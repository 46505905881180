export const getFormattedDate = (dateString: string = '') => {
    const date = dateString ? new Date(dateString) : new Date();
    return date.toLocaleDateString().replaceAll('/', '-');
};

export const gerCurrentDate = () => {
    return new Date().toISOString();
};

export const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${amPm}`;
};
