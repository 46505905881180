import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { MessageType } from '../__generated__/graphql';
import { IMessage } from '../contexts';
import { MARK_MESSAGES_AS_READ } from '../graphql';
import { logger } from '../utils';

const useMessagesRead = (messages: IMessage[]) => {
    const [markMessagesRead] = useMutation(MARK_MESSAGES_AS_READ);

    useEffect(() => {
        const messageIds =
            messages
                ?.filter(msg => msg.type === MessageType.SmeResponse || msg.type === MessageType.LearningMaterial)
                ?.map(msg => msg.id) || [];

        if (messages && messageIds && messageIds.length > 0) {
            try {
                markMessagesRead({
                    variables: {
                        input: {
                            messageIds,
                        },
                    },
                });
            } catch (error) {
                logger.log('Error marking as read', error);
            }
        }
    }, [markMessagesRead, messages]);
};

export { useMessagesRead };
