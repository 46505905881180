import { Button, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { uniqueId } from '../../../../utils';

const TranscriptSummary = ({ demoData, openModal, downloadMeetingSummary, refreshComponent }: any) => (
    <>
        <div className="p-4 bg-white shadow-md rounded-lg">
            <section className="my-3">
                <p className="my-1">
                    <strong>Date:</strong> {demoData.date}
                </p>
                <p className="my-1">
                    <strong>Time:</strong> {demoData.time} (UTC+05:30)
                </p>
                <p className="my-1">
                    <strong>Call Length:</strong> {demoData.callLength}
                </p>
                <p className="my-1">
                    <strong>Account Name:</strong> {demoData.accountName}
                </p>
            </section>

            <section className="my-4">
                <div className="my-2">
                    <strong>EcoCart Attendees:</strong>
                    <ul className="pl-4 list-disc">
                        {demoData.ecoCartAttendance.map((name: any, index: any) => (
                            <li className="my-1 ms-10" key={index}>
                                {name}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="my-2">
                    <strong>Prospect Attendees:</strong>
                    <ul className="pl-4 list-disc">
                        {demoData.prospectAttendance.map((name: any, index: any) => (
                            <li className="my-1 ms-10" key={index}>
                                {name}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="my-2">
                    <strong>Key Insights:</strong>
                    <ul className="pl-4 list-disc">
                        {demoData.keyInsights.map((name: any, index: any) => (
                            <li className="my-1 ms-10" key={index}>
                                {name}
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            <section className="my-3">
                <p className="my-1">
                    <strong>Next Steps:</strong> {demoData.nextStep}
                </p>
                <p className="my-1">
                    <strong>Miscellaneous:</strong> {demoData.miscellaneous}
                </p>
            </section>
        </div>

        <div className="flex space-x-2 my-5 w-[9vw]">
            <Tooltip id={uniqueId()} place="top" variant="dark" tooltipType="detailed" title="Edit Content">
                <Button
                    className="bg-R-500 hover:bg-R-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                    iconBefore="ri-edit-box-fill"
                    view="outline"
                    onClick={openModal}
                />
            </Tooltip>
            <Tooltip id={uniqueId()} place="top" variant="dark" tooltipType="detailed" title="Download as text File">
                <Button
                    className="bg-B-500 hover:bg-B-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                    iconBefore="ri-mail-download-fill"
                    view="outline"
                    onClick={() => downloadMeetingSummary(demoData)}
                />
            </Tooltip>
            <Tooltip id={uniqueId()} place="top" variant="dark" tooltipType="detailed" title="Refresh">
                <Button
                    className="bg-G-500 hover:bg-G-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                    iconBefore="ri-refresh-line"
                    view="outline"
                    onClick={refreshComponent}
                />
            </Tooltip>
        </div>
    </>
);

export default TranscriptSummary;
