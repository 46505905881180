import { useHover } from '@mantine/hooks';
import { CN } from '../../../utils';
import { ICommonMessage } from '../../pages/messenger/types';
import { MessageActions } from './MessageActions';
import { MessageAvatar } from './MessageAvatar';

const CommonMessage = ({
    id = '',
    text = '',
    index,
    content = <></>,
    isUser = false,
    disableCopy = false,
    feedback,
}: ICommonMessage) => {
    const { hovered, ref } = useHover();

    return (
        <div className="">
            <div className={CN('flex ', { 'justify-end': isUser })}>
                <div
                    className={CN('flex flex-col space-y-2 text-xs order-1 items-end', {
                        'w-full': !isUser,
                        'w-fit max-w-[70%] sm:max-w-[90%]': isUser,
                    })}
                >
                    <div
                        ref={ref}
                        className={CN(
                            'single_chat_bubble flex flex-col relative w-full text-sm rounded-[24px_24px_0px_24px] text-N-900 slide-in-top animate-once',
                            {
                                'px-4 pt-3 pb-6': !isUser,
                                'px-4 py-2 bg-[#EEEEEE] border-[2px] border-N-200': isUser,
                            }
                        )}
                        style={{ animationDelay: index ? `${100 * index}ms` : '200ms' }}
                    >
                        <div className="flex flex-col">
                            <div
                                className={CN('flex gap-x-3 w-full sm:flex-col sm:gap-y-3', { 'items-start': isUser })}
                            >
                                {!isUser && <MessageAvatar isUser={isUser} />}
                                <div className="flex-grow flex flex-col items-start gap-y-3 w-full">
                                    {content}
                                    <MessageActions
                                        id={id}
                                        text={text}
                                        content={content}
                                        isUser={isUser}
                                        isHovered={hovered}
                                        disableCopy={disableCopy}
                                        feedback={feedback}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { CommonMessage };
