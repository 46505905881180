import classname from 'classnames';

type IKnowledgeDocument = {
    name: string;
    onClose?: Function;
    className?: string;
    fileSize?: number;
    isHideClose?: boolean;
};

export const KnowledgeDocument = ({ name, onClose, className, fileSize, isHideClose }: IKnowledgeDocument) => {
    function bytesToMB(bytes: number) {
        return (bytes / (1024 * 1024)).toFixed(2);
    }
    return (
        <div
            className={classname(
                'w-fit bg-N-200 max-w-[250px] sm:max-w-[120px] px-3 py-2 rounded-[3px] relative flex items-center gap-x-2 justify-between',
                className
            )}
        >
            <div className="flex items-center overflow-hidden gap-x-2">
                <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-[2px] bg-B-200 flex items-center justify-center text-B-500">
                    <i className="ri-file-line w-fit text-lg"></i>
                </div>
                <div className="flex flex-col overflow-hidden">
                    <span className="whitespace-nowrap block w-full overflow-hidden text-ellipsis">{name}</span>
                    <span className="whitespace-nowrap block w-full overflow-hidden text-ellipsis text-xs text-N-500">
                        {fileSize && bytesToMB(fileSize)} MB
                    </span>
                </div>
            </div>
            {!isHideClose && (
                <button
                    type="button"
                    className="h-4 w-4 min-h-4 min-W-4 rounded-full bg-R-400 flex items-center justify-center  absolute right-[-4px] top-[-4px]"
                    onClick={() => onClose?.()}
                >
                    <i className="ri-close-line text-xs text-N-50"></i>
                </button>
            )}
        </div>
    );
};
