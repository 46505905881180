import { Button } from '@kaya/kaya-ui-design-system-pb';
import { useSideBarContext } from '../../../contexts';
import { useMobile } from '../../../hooks';

type MobileMenuButtonProps = {
    className?: string;
};

const MobileMenuButton = ({ className = '' }: MobileMenuButtonProps) => {
    const { showMobileSideBar, handleMobileMenuIconClick, isCollapsed, setIsCollapsed } = useSideBarContext();
    const isMobile = useMobile();

    return isMobile ? (
        <i
            className={`px-2 cursor-pointer text-h5 text-N-600 ${showMobileSideBar ? `ri-close-fill` : `ri-menu-fill`} ${className} `}
            onClick={() => (isCollapsed ? setIsCollapsed(false) : handleMobileMenuIconClick())}
        />
    ) : (
        <i
            className={`px-2 cursor-pointer text-h5 text-N-400 ri-arrow-left-s-line bg-N-200 flex items-center justify-center h-[30px] w-[30px] rounded-[5px] mr-6  ${className} `}
            onClick={() => {
                setIsCollapsed(true);
            }}
        />
    );
};

export { MobileMenuButton };
