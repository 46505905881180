import { Button } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { CommonMessage, FormattedChatMessage, TranscriptionUpload } from '..';
import { useMutation } from '@apollo/client';
import { ANALYZE_CALL_TRANSCRIPT } from '../../../graphql';
import { downloadCallTranscriptAnalysisAsPDF } from '../../../services';

const calculateProgress = (currentIndex: number, totalMessages: number) => {
    return Math.round(((currentIndex + 1) / totalMessages) * 100);
};

const useLoadingMessages = (loading: boolean): any => {
    const [loadingState, setLoadingState] = useState<any>({
        currentMessage: '',
        showLoadingText: false,
        progress: 0,
    });

    useEffect(() => {
        const messages = [
            'Analyzing Call Transcript 📞',
            'Extracting metadata from Call Transcript 📝',
            'Analyzing call transcript data 📈',
            'Generating call transcript analysis report 📄',
        ];

        let currentIndex = 0;
        let interval: any = null;

        const startLoading = () => {
            interval = setInterval(() => {
                currentIndex = (currentIndex + 1) % messages.length;
                setLoadingState((prevState: any) => ({
                    ...prevState,
                    currentMessage: messages[currentIndex],
                    showLoadingText: true,
                    progress: calculateProgress(currentIndex, messages.length),
                }));
            }, 3000);

            // Set initial state immediately
            setLoadingState((prevState: any) => ({
                ...prevState,
                currentMessage: messages[currentIndex],
                showLoadingText: true,
                progress: calculateProgress(currentIndex, messages.length),
            }));
        };

        const stopLoading = () => {
            if (interval) {
                clearInterval(interval);
            }

            setLoadingState({ currentMessage: '', showLoadingText: false, progress: 0 });
        };

        if (loading) {
            startLoading();
        } else {
            stopLoading();
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [loading]);

    return loadingState;
};

const AddCallTranscripts = () => {
    const [analyzeCallTranscript] = useMutation(ANALYZE_CALL_TRANSCRIPT);
    const [fileUploaded, setFileUploaded] = useState<File | null>(null);
    const [analyzing, setAnalyzing] = useState(false);
    const [analysis, setAnalysis] = useState<string>('');

    const handleClick = async () => {
        try {
            setAnalyzing(true);
            const { data } = await analyzeCallTranscript({
                variables: {
                    input: {
                        transcript: fileUploaded,
                    },
                },
            });

            setAnalysis(data?.analyzeCallTranscript || '');
            setAnalyzing(false);
        } catch (error) {
            console.error('Error in handleClick', error);
            setAnalyzing(false);
        }
    };

    const onDownloadPDF = () => {
        downloadCallTranscriptAnalysisAsPDF(analysis);
    };

    const { currentMessage, showLoadingText, progress } = useLoadingMessages(analyzing);

    return (
        <>
            <CommonMessage
                disableCopy
                text=""
                content={
                    <>
                        <TranscriptionUpload onFileUpload={setFileUploaded} />
                        {fileUploaded && (
                            <Button
                                isDisabled={analyzing || Boolean(analysis)}
                                onClick={handleClick}
                                className="flex justify-center cursor-pointer bg-transparent bg-B-500 hover:bg-B-400 text-white hover:border-N-100 my-2 py-2 px-4 border-none rounded-md w-full text-left hoverEffect disabled:bg-N-600 disabled:cursor-not-allowed"
                                iconBefore="ri-chat-check-line"
                            >
                                Analyze Call Transcript
                            </Button>
                        )}
                    </>
                }
            />
            {showLoadingText && (
                <CommonMessage
                    disableCopy
                    text={currentMessage}
                    content={
                        <>
                            <FormattedChatMessage text={currentMessage} />
                            <div className="bg-N-400 rounded-full h-2.5 animate-fade my-1">
                                <div
                                    style={{ width: `${progress}%` }}
                                    className={'bg-B-600 h-2.5 rounded-full animate-pulse'}
                                />
                            </div>
                        </>
                    }
                />
            )}
            {analysis && (
                <CommonMessage
                    disableCopy
                    text="Call Transcript Analysis"
                    content={
                        <>
                            <FormattedChatMessage text={analysis} />
                            <div>
                                <div className="my-2">👇 Please click on the below button to Download as PDF</div>
                                <button
                                    onClick={onDownloadPDF}
                                    className="bg-B-500 hover:bg-B-400 py-2 px-4 cursor-pointer text-white border-none float-right rounded-md disabled:bg-N-600 disabled:pointer-events-none w-full"
                                >
                                    Download Analysis Report
                                    <i className="ri-download-2-line mx-2"></i>
                                </button>
                            </div>
                        </>
                    }
                />
            )}
        </>
    );
};

export { AddCallTranscripts };
