import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface IParagraphSkeletonProps {
    lineCount: number;
}

export const ParagraphSkeleton = ({ lineCount }: IParagraphSkeletonProps) => {
    return (
        <div>
            <SkeletonTheme baseColor="#7b808813" highlightColor="#99a0a822">
                <div className="w-[90%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[35%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[20%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[60%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[90%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[90%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[30%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[40%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
                <div className="w-[80%]">
                    <Skeleton height={15} containerClassName="flex-1" className="skeleton-bg" count={1} />
                </div>
            </SkeletonTheme>
        </div>
    );
};
