import { Button } from '@kaya/kaya-ui-design-system-pb';
import { useChatContext } from '../../../contexts';
import { downloadCallReportAsPDF } from '../../../services';

const DownloadCallReport = ({
    messageId,
    text,
    enableCallReports,
}: {
    messageId: string;
    text: string;
    enableCallReports: boolean;
}) => {
    const { messages } = useChatContext();
    const streaming = Boolean(messages?.slice(-1)[0]?.processing && messages?.slice(-1)[0]?.id === messageId);

    if (!enableCallReports || streaming) return null;

    return (
        <Button
            iconAfter="ri-download-2-line"
            className="flex justify-center cursor-pointer bg-transparent text-white hover:border-N-100 my-2 py-2 px-4 border border-N-400 rounded-md w-full text-left hoverEffect disabled:bg-N-500 disabled:cursor-not-allowed"
            onClick={() => downloadCallReportAsPDF(text)}
        >
            Download Report
        </Button>
    );
};

export { DownloadCallReport };
