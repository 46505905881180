export const FOLLOW_UP_EMAIL = `
## Next Steps and How We Can Help You Achieve Your Financial Goals

Dear Aurora,

It was wonderful speaking with you earlier today. I really enjoyed our conversation and getting to know you, and I look forward to potentially working with you to help you achieve your goals for you and your daughter. Below I've provided a quick summary of our discussion, a few reminders, and what the next steps are. I've also provided some of our research pieces to read at your leisure.

### Summary:
1. Introduction - Discussed the beautiful city of San Francisco and your referral from John.
2. You have increasing responsibilities at work (tech company for 20 years) and want to make sure you and your daughter are financially secure.
3. You've done well managing your portfolio with the help of an advisor at Schwab but are looking to make changes.
4. Goals - Plan for retirement ideally in 15 years, set up a 529 plan for your daughter who's going to college in 9 years, and invest in real estate.
5. **Approximate Current Portfolio:**
   | **Asset Class**             | **Est. Amount**  | **Notes**                 |
   | --------------------------- | ----------------| ------------------------- |
   | Cash and Cash Equivalents    | $100,000         |                           |
   | Stocks & Bonds               | $5,500,000       | Concentrated stock positions |
   | Alternatives                 | $225,000         | Private Equity            |

6. Discussion of Pinnacle Wealth Advisors and our unique approach to investment strategy, our fee structure, and how we would transition your portfolio if you were to become a client.

### Reminders:
1. Please send us the following information so we can provide you with a more detailed Asset Allocation recommendation, including fees, a Forecasting Plan, and Portfolio Transition Plan:
   a. Schwab Holdings Report
   b. Capital Account Statement and Prospectus for your Private Equity Investment
2. Attached to this email are Pinnacle Wealth Advisors research pieces on 529 plans and investing in Private Real Estate. Please read these at your leisure, and I would be happy to set up another meeting to discuss both items in more detail.

### Next Steps:
1. Attached to this email are baseline Forecasting reports and an Asset Allocation recommendation based on our conversation. These are to reflect the tools and reporting we will provide to you as a client and will review at a frequency of your choosing.
2. After you provide the holdings report and private equity investment information, I will follow up with a more detailed Asset Allocation recommendation, a review of the terms of your PE investment, and a tax estimate for the portfolio transition from Schwab.

Please feel free to reach out if you have any questions or concerns. I look forward to helping you achieve your goals!

Best Regards,  
Orion Starling  
Wealth Manager  
Pinnacle Wealth Advisors
`;
