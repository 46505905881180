import { useEffect, useState } from 'react';
import { ButtonWithMessage, ChatMessage, CommonMessage, ScrollableWrapper } from '../../common';
import { useMessageContent } from '../../../hooks';
import { useChatContext } from '../../../contexts';
import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { downloadAllEmail, downloadEmlFile } from './generateEmlContent';
import { DEMO_MSG_MAESTRO_COMPANY_DETAILS, DEMO_MSG_MAESTRO_EMAIL } from '../../../constants';
import EmailEditModal from './common/emailEditModal';
import LeadCompanySelector from './common/LeadCompanySelector';
import EmailActions from './common/emailActions';

export type EmailContent = {
    id: string;
    subject: string;
    body: string;
    attachment: any;
    from: string;
    to: string;
    ProfessionalEmailSubject?: string;
    ProfessionalEmailBody?: string;
    FriendlyEmailSubject?: string;
    FriendlyEmailBody?: string;
    InformativeEmailSubject?: string;
    InformativeEmailBody?: string;
    tone?: 'normal' | 'professional' | 'friendly' | 'informative';
};

const MessageMaestroKPL = () => {
    const UsersLoading = false;
    const { containerRef } = useMessageContent();
    const { userFirstName } = useChatContext();

    const [isProceedBtnLoading, setIsProceedBtnLoading] = useState(false);
    const [emailContent, setEmailContent] = useState<EmailContent | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [generatedEmails, setGeneratedEmails] = useState<EmailContent[]>([]);
    const [savedTemplates, setSavedTemplates] = useState<EmailContent[]>(DEMO_MSG_MAESTRO_EMAIL);
    const [selectedEmailId, setSelectedEmailId] = useState<any>();
    const [selectStep, setSelectStep] = useState(1);
    const [isSelectorVisible, setIsSelectorVisible] = useState<boolean>(true);
    const [isProceedBtnVisible, setIsProceedBtnVisible] = useState<boolean>(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleUserSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedUserId = e.target.value;
        const selectedUser = DEMO_MSG_MAESTRO_COMPANY_DETAILS.find(user => user.id === selectedUserId);
        const emailTemplate = savedTemplates.find(email => email.id === selectedUserId);

        if (selectedUser && emailTemplate) {
            setEmailContent(emailTemplate);
            setSelectedEmailId(selectedUserId);
            setIsProceedBtnVisible(true);
        }
    };

    const handleEmailTemplateSave = (id: string) => {
        if (!emailContent) return;

        const updatedTemplates = savedTemplates.map(template =>
            template.id === id ? { ...template, subject: emailContent.subject, body: emailContent.body } : template
        );
        const newEmail = {
            id: id,
            subject: emailContent.subject,
            body: emailContent.body,
            from: emailContent.from,
            to: emailContent.to,
            attachment: emailContent.attachment,
        };
        const updatedEmails = generatedEmails.filter(email => email.id !== id);
        setGeneratedEmails([...updatedEmails, newEmail]);
        setSavedTemplates(updatedTemplates);
        setIsSelectorVisible(false);
        setIsProceedBtnVisible(false);
        closeModal();
    };

    const refreshComponent = () => {
        setRefreshKey(prevKey => prevKey + 1);
        setGeneratedEmails([]);
        setIsSelectorVisible(true);
    };

    useEffect(() => {
        if (isProceedBtnLoading) {
            const timer = setTimeout(() => {
                setIsProceedBtnLoading(false);
                handleEmailTemplateSave(selectedEmailId);
            }, 2500);

            return () => clearTimeout(timer);
        }
    }, [isProceedBtnLoading]);

    return (
        <ScrollableWrapper ref={containerRef} key={refreshKey}>
            <ChatMessage
                disableCopy
                text={`Hi, ${userFirstName} 👋
                \nWelcome to MessageMaestro! 🎉 This tool is designed to make your outreach to leads more efficient and personalized.
                \n- No more starting from scratch! MessageMaestro pulls in relevant lead details from HubSpot and gives you a head start on crafting messages that resonate.
                \nReady to get started? Let’s make your messaging more impactful, one lead at a time!`}
            />

            {generatedEmails.map((email: EmailContent) => (
                <>
                    <CommonMessage
                        isUser
                        disableCopy
                        text="Select"
                        content={
                            <>
                                <div className="mt-2 text-md">👇 Please select a Lead Name to proceed </div>
                                <div className="w-full flex justify-center">
                                    {UsersLoading ? (
                                        <SpinLoader color="#384151" />
                                    ) : (
                                        <LeadCompanySelector
                                            UsersLoading={UsersLoading}
                                            disabled={true}
                                            selectedValue={email?.id}
                                        />
                                    )}
                                </div>
                            </>
                        }
                    />

                    <ButtonWithMessage
                        displayCondition={true}
                        disabledCondition={true}
                        buttonText="Generate Email"
                        disabledText="Email Generated successfully"
                        isLoading={isProceedBtnLoading}
                        onClick={() => {}}
                        icon="ri-sparkling-line"
                        text="👇 Please click on the button below to Generate Email"
                    />
                    <CommonMessage
                        key={email.id}
                        disableCopy
                        text={email.body}
                        isUser={false}
                        content={
                            <EmailActions
                                email={email}
                                generatedEmails={generatedEmails}
                                openModal={openModal}
                                downloadEmlFile={downloadEmlFile}
                                downloadAllEmail={downloadAllEmail}
                                refreshComponent={refreshComponent}
                                setIsSelectorVisible={setIsSelectorVisible}
                                setSelectedEmailId={setSelectedEmailId}
                            />
                        }
                    />
                </>
            ))}

            <>
                {isSelectorVisible && (
                    <CommonMessage
                        isUser
                        disableCopy
                        text="Select"
                        content={
                            <>
                                <div className="mt-2 text-md">👇 Please select a Lead Name to proceed</div>
                                <div className="w-full flex justify-center">
                                    {UsersLoading ? (
                                        <SpinLoader color="#384151" />
                                    ) : (
                                        <LeadCompanySelector
                                            UsersLoading={UsersLoading}
                                            onChange={handleUserSelect}
                                            placeholder="Select Lead Name"
                                        />
                                    )}
                                </div>
                            </>
                        }
                    />
                )}

                {isProceedBtnVisible && (
                    <ButtonWithMessage
                        displayCondition={true}
                        disabledCondition={false}
                        buttonText="Generate Email"
                        disabledText="Email Generated successfully"
                        isLoading={isProceedBtnLoading}
                        onClick={() => {
                            setIsProceedBtnLoading(true);
                            setSelectStep(selectStep + 1);
                        }}
                        icon="ri-sparkling-line"
                        text="👇 Please click on the button below to Generate Email"
                    />
                )}
            </>

            <EmailEditModal
                emailContent={emailContent}
                isOpen={isModalOpen}
                onClose={closeModal}
                onSave={() => {
                    handleEmailTemplateSave(selectedEmailId);
                }}
                onSubjectChange={subject => setEmailContent({ ...emailContent, subject: subject  } as EmailContent)}
                onBodyChange={body => setEmailContent({ ...emailContent, body: body } as EmailContent)}
                selectedEmailId={undefined}
            />
        </ScrollableWrapper>
    );
};

export { MessageMaestroKPL };
