export enum StepperTypes {
    USER_INPUT,
    BOT_RESPONSE,
    BOT_CUSTOM_RESPONSE,
}

export enum GenTechSpecQuestionType {
    OPTIONS,
    TEXT,
    FILE,
}

export interface Step {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: string;
    response: any;
    condition: boolean;
    disabledCondition: boolean;
    handleClick: () => void;
    isLoading: boolean;
    text: string;
    disabledText: string;
    buttonText: string;
    showButton: boolean;
    showEdit: boolean;
    showExtraButton: boolean;
    icon: string;
    type: StepperTypes;
}

export interface StepContainerProps {
    step: Step;
    index: number;
}

export interface IGenerationQuestion {
    text: string;
    options: string[];
    type: GenTechSpecQuestionType;
    answer: string | File;
}

export const getParsed = (value: string) => {
    try {
        const parsed = JSON.parse(value);
        return parsed['overview'];
    } catch (error) {
        return value;
    }
};

export const getParsedDetailed = (value: string) => {
    try {
        const parsed = JSON.parse(value);
        return parsed['detailed'];
    } catch (error) {
        return value;
    }
};

export const getParsedMeta = (value: string) => {
    try {
        const parsed = JSON.parse(value);
        return parsed['meta'];
    } catch (error) {
        return value;
    }
};
