import { usePromptFilter } from '../../../hooks';
import { PromptFilterContainer } from './common/prompt-filter-container/PromptFilterContainer';
import { PromptTableContainer } from './common/prompt-table-container/PromptTableContainer';
import { useState } from 'react';
import { useMobile } from '../../../hooks';
import { Button, Drawer } from '@kaya/kaya-ui-design-system-pb';
import { useSideBarContext } from '../../../contexts';
import { CN } from '../../../utils';

export const PromptManagementContainer = () => {
    const isMobile = useMobile();
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
    const { isCollapsed, isMenuCollapsed } = useSideBarContext();

    const { model, setModel, kpl, setKpl, category, setCategory, search, setSearch, count, setCount } =
        usePromptFilter();
    return (
        <div
            className={CN('admin-panel flex xxl:flex-col gap-x-16 w-full', {
                'gap-x-6': isMenuCollapsed || !isCollapsed,
            })}
        >
            <div className="sm:justify-end hidden xxl:flex">
                <Button
                    onClick={() => {
                        setIsFilterDrawerOpen(true);
                    }}
                    className="mb-3 text-B-500"
                    iconBefore="ri-equalizer-line"
                >
                    <p className="text-sm font-400 ">Filters</p>
                </Button>
            </div>
            <div className="xxl:hidden">
                <PromptFilterContainer
                    model={model}
                    setModel={setModel}
                    kpl={kpl}
                    setKpl={setKpl}
                    category={category}
                    setCategory={setCategory}
                />
            </div>

            <PromptTableContainer
                model={model}
                kpl={kpl}
                category={category}
                search={search}
                setSearch={setSearch}
                count={count}
                setCount={setCount}
            />

            <Drawer
                isOpen={isFilterDrawerOpen}
                onClose={() => {
                    setIsFilterDrawerOpen(false);
                }}
                title="Prompt Filters"
                isHideClose={true}
                fullWidth={isMobile}
                headerIcon="ri-contacts-line"
                width={800}
                closableOnClickOutside={true}
                className="z-[999]"
                footer={
                    <div className="flex items-center gap-x-[8px] justify-end">
                        <Button
                            view="outline"
                            appearance="primary"
                            size="medium"
                            onClick={() => {
                                setIsFilterDrawerOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                }
                children={
                    <>
                        <div className="p-5 mb-[20vh]">
                            <PromptFilterContainer
                                model={model}
                                setModel={setModel}
                                kpl={kpl}
                                setKpl={setKpl}
                                category={category}
                                setCategory={setCategory}
                            />
                        </div>
                    </>
                }
            />
        </div>
    );
};
