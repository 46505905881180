import { useChatContext } from '../../../contexts';
import { useScroll } from '../../../hooks';
import { ActivityLogMessage, MessageTyping, ScrollableWrapper } from '../../common';

function TimesheetContent() {
    const { userFirstName, timesheets, loading } = useChatContext();
    const { scrollRef } = useScroll([loading, timesheets]);

    return (
        <ScrollableWrapper>
            <ActivityLogMessage
                text={`Hi, ${userFirstName} 👋 
                    \n\n Ready to spill the beans on your day's groove?
                    \n\n We're eager to hear all about it! Just a friendly reminder, though, let's keep it cool and not spill any sensitive data. 
                    \n\n Whether it's the technical twists and turns or the awesome lessons you've picked up, we're all in.
                    \n\n Your input isn't just a game-changer for you – it's a global force for good. 
                    \n\n Together, we're speeding up solutions for folks across the planet, without compromising any secret sauce.
                    \n\n Thanks in advance for sharing. You rock! 🚀👊`}
            />
            {timesheets?.map((timesheet, index) => (
                <div className="my-2" key={index}>
                    <ActivityLogMessage
                        key={index + '1'}
                        text={`${timesheet.description}`}
                        isUser={Boolean(timesheet.isUser)}
                        showApprove={Boolean(timesheet.showApprove)}
                    />
                </div>
            ))}
            {loading && <MessageTyping />}
            <span ref={scrollRef}></span>
        </ScrollableWrapper>
    );
}

export { TimesheetContent };
