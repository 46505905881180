import React, { useEffect, useState } from 'react';
import { Source } from '../../../../__generated__/graphql';
import { FormattedChatMessage, MessageActions, MessageAvatar } from '../../message';
import { GoogleFactCheckLoadingState } from '../FactCheckResponseContainer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ParagraphSkeleton } from '../../Skelton';
import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import classNames from 'classnames';

type IFactCheckResponse = {
    label: string;
    content: string;
    sources: Source[];
    isFactCheckedResponse?: boolean;
    loadingStateMessage: GoogleFactCheckLoadingState | undefined;
    maximized: boolean;
    onMaximize?: () => void;
    maximizable: boolean;
};

export const FactCheckResponse = ({
    label,
    content,
    sources,
    isFactCheckedResponse = false,
    loadingStateMessage,
    onMaximize,
    maximized,
    maximizable,
}: IFactCheckResponse) => {
    const [loadingMessage, setLoadingMessage] = useState<string[]>([]);

    useEffect(() => {
        setLoadingMessage([...loadingMessage, String(loadingStateMessage)]);
    }, [loadingStateMessage]);

    return (
        <div className="flex flex-col gap-y-6 flex-1">
            <div
                className={classNames(
                    'fact-check-response-card flex flex-col gap-y-3 border border-[#4b556317] bg-[#EEEEEE] p-3 h-full',
                    {
                        'max-w-[500px]': !maximized,
                        'max-w-[unset]': maximized,
                    }
                )}
            >
                <div className="flex justify-between items-center">
                    <div className="flex gap-x-3 items-center">
                        <div className="relative">
                            <MessageAvatar />
                        </div>
                        <p className="text-N-600 text-md">{label}</p>
                    </div>
                    {maximizable && loadingStateMessage === GoogleFactCheckLoadingState.COMPLETED && (
                        <i className="ri-fullscreen-line cursor-pointer" onClick={() => onMaximize?.()} />
                    )}
                </div>
                <div className="">
                    {loadingStateMessage !== GoogleFactCheckLoadingState.COMPLETED ? (
                        <div className="flex flex-col gap-y-1 relative h-[350px]">
                            <div className="w-full">
                                <div className="absolute w-full left-[124px] top-[100px]">
                                    {loadingMessage.map((message: string) => {
                                        return <p className="text-sm font-400 text-N-700">{message}</p>;
                                    })}
                                </div>
                                <ParagraphSkeleton lineCount={14} />
                            </div>
                        </div>
                    ) : (
                        <>
                            <p
                                className={classNames(
                                    'text-N-700 text-md flex-1 overflow-y-auto scrollable_effect_multi_browser_without_thumb pr-2',
                                    { 'h-[unset]': maximized, 'min-h-[300px] max-h-[300px]': !maximized }
                                )}
                            >
                                <FormattedChatMessage text={content} sources={sources} />
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
