import { useEffect } from 'react';
import {
    CustomKplContextProvider,
    CustomKplSectionSelectionTypes,
    useChatContext,
    useCustomKplContext,
    useSideBarContext,
} from '../../../contexts';
import { CustomKplConfigure } from './common/custom-kpl-configure/CustomKplConfigure';
import { CustomKplCreate } from './common/custom-kpl-create/CustomKplCreate';
import { CN } from '../../../utils';
import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';

export const CustomKplContainer = () => {
    const { customKplSelection, setCustomKplSelection } = useSideBarContext();
    const { isGuestKplCreated, isKplLoading } = useCustomKplContext();

    useEffect(() => {
        if (!isGuestKplCreated) {
            setCustomKplSelection(CustomKplSectionSelectionTypes?.CREATE);
        }
    }, [isGuestKplCreated]);
    return (
        <div
            className={CN('w-full h-full overflow-auto', {
                'p-4 sm:px-2 sm:py-3 ': customKplSelection === CustomKplSectionSelectionTypes?.CONFIGURE,
            })}
        >
            {customKplSelection === CustomKplSectionSelectionTypes?.CONFIGURE ? (
                <CustomKplConfigure />
            ) : (
                <CustomKplCreate />
            )}
        </div>
    );
};
