import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { APPROVE_TIMESHEET } from '../graphql';
import { useChatContext } from '../contexts';
import { ActivityLogMessage } from '../__generated__/graphql';

const useApproveLog = () => {
    const { userDetails, timesheets, timesheetFormData } = useChatContext();
    const [approveActivityLog, { loading }] = useMutation(APPROVE_TIMESHEET);
    const [approved, setApproved] = useState<boolean>(false);
    const updates: ActivityLogMessage[] = timesheets?.filter(timesheet => timesheet.isUser === true);
    const worklog = updates.slice(-1)?.[0]?.description || '';

    const submitApproval = useCallback(
        async (text: string) => {
            const { data: result } = await approveActivityLog({
                variables: {
                    input: {
                        date: timesheetFormData.date,
                        userId: userDetails.id,
                        description: text,
                        worklog,
                    },
                },
            });

            if (result?.approveActivityLog?.id) {
                setApproved(true);
            }
        },
        [approveActivityLog, timesheetFormData, userDetails, worklog]
    );

    return { submitApproval, loading, approved };
};

export { useApproveLog };
