import { useMemo } from 'react';
import { UITableV2 } from '../../../common/table';
import { Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { uniqueId } from '../../../../utils';

const SalesRepTable = ({ tableData }: any) => {
    const tableColumnDefinition = useMemo(
        () => [
            {
                id: '1',
                Header: 'First Name',
                showHeader: true,
                accessor: 'firstName',
                isSortable: true,
                width: 100,
                minWidth: 100,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.firstName}</p>,
            },
            {
                id: '2',
                Header: 'Last Name',
                showHeader: true,
                accessor: 'lastName',
                isSortable: true,
                width: 100,
                minWidth: 100,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.lastName}</p>,
            },
            {
                id: '3',
                Header: 'Role',
                showHeader: true,
                accessor: 'role',
                isSortable: true,
                width: 100,
                minWidth: 100,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.role}</p>,
            },
            {
                id: '4',
                Header: 'Email',
                showHeader: true,
                accessor: 'email',
                isSortable: true,
                width: 100,
                minWidth: 100,
                isCustomizable: false,
                Cell: ({ row }: any) => (
                    <Tooltip
                        id={uniqueId()}
                        title={row?.original?.email}
                        tooltipType="minimal"
                        variant="dark"
                        place="top"
                    >
                        <p className="text-N-600 max-w-[200px] truncate">{row?.original?.email}</p>
                    </Tooltip>
                ),
            },
            {
                id: '5',
                Header: 'Geo-Location',
                showHeader: true,
                accessor: 'geoLocation',
                isSortable: true,
                width: 100,
                minWidth: 100,
                isCustomizable: false,
                Cell: ({ row }) => <p className="text-N-900 font-500">{row?.original?.geoLocation}</p>,
            },
            {
                id: '6',
                Header: 'Assigned Lead',
                showHeader: true,
                accessor: 'assignedLead',
                isSortable: true,
                width: 50,
                minWidth: 50,
                isCustomizable: false,
                Cell: ({ row }) => <p className="text-N-900 font-500">{row?.original?.assignedLead}</p>,
            },
            {
                id: '7',
                Header: 'Live Deals',
                showHeader: true,
                accessor: 'liveDeals',
                isSortable: true,
                width: 50,
                minWidth: 50,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.liveDeals}</p>,
            },
        ],
        []
    );

    return (
            <UITableV2
                className="z-[0]"
                width={120}
                widthInPercentage={true}
                tableData={tableData}
                columnsArray={tableColumnDefinition}
                bodyClassnames={tableData?.length >= 4 ? 'overflow-auto' : ''}
                isLoading={false}
                isLoadMoreTriggered={false}
                loadingText={'Loading sales representatives...'}
                headerClassnames={tableData?.length <= 1 ? 'z-0' : ''}
            />
    );
};

export default SalesRepTable;
