import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import classNames from 'classnames';

interface IOverlayLoading {
    overlayLoadingText?: string;
    isLoadMoreTriggered?: boolean;
    loadingText: string;
}

export const OverlayLoading = ({
    overlayLoadingText = 'Loading more data. Please wait ...',
    isLoadMoreTriggered = false,
    loadingText,
}: IOverlayLoading) => {
    return (
        <div className="absolute inset-0 z-[99]">
            <div className="w-full h-full bg-N-50 opacity-50"></div>
            <div
                className={classNames('absolute flex justify-center w-full', {
                    /**
                     * if is @param isLoadMoreTriggered true open the loading indicator at the bottom of the table
                     * if is @param isLoadMoreTriggered false open the loading indicator at the middle of the table
                     */
                    'top-[40vh]': !isLoadMoreTriggered,
                    'bottom-[40vh]': isLoadMoreTriggered,
                })}
            >
                <div className="flex flex-col items-center">
                    <SpinLoader />
                    <p className="text-sm text-N-600 mt-[8px]">{loadingText}</p>
                </div>
            </div>
        </div>
    );
};
