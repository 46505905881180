import { MessageAvatar } from '../message';

const MessageTyping = () => {
    return (
        <div className="px-4">
            <div className="flex items-center gap-2">
                <div className="flex text-xs mx-2 order-1 items-end h-full justify-start">
                    <div className="mx-2" data-title="dot-flashing">
                        <div className="dot-flashing"></div>
                    </div>
                </div>
                <MessageAvatar animated />
            </div>
        </div>
    );
};

export { MessageTyping };
