const generateEmlContent = (emailContent: any) => {
    const { from, to, subject, body, attachment } = emailContent;

    return (
        `From: ${from}\n` +
        `To: ${to}\n` +
        `Subject: ${subject}\n` +
        `MIME-Version: 1.0\n` +
        `Content-Type: text/plain; charset="UTF-8"\n` +
        `\n` +
        `${body} \n\n` +
        `Attached : ` +
        `${attachment}`
    );
};

export const downloadEmlFile = (emailContent: any) => {
    const emlContent = generateEmlContent(emailContent);
    const blob = new Blob([emlContent], { type: 'message/rfc822' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${emailContent?.to}.eml`;
    link.click();
};

export const downloadAllEmail = (emailContent: any) => {
    emailContent.map((email: any) => downloadEmlFile(email));
};
