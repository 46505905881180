import React from 'react';
import { logger } from '../../../utils';
import { useChatContext } from '../../../contexts';
import { useMessageContent, useMessenger } from '../../../hooks';
import { ChatMessage } from '../message';

export const CustomWelcomeMessage = () => {
    const { userFirstName } = useChatContext();
    const { currentAppConfig, loading } = useMessageContent();
    const { submitMessage } = useMessenger();

    const showCustomWelcomeMessage = currentAppConfig?.metadata.customWelcomeMessage?.enabled ? true : false;
    const customWelcomeMessage =
        `Hi, ${userFirstName} 👋
        \n${showCustomWelcomeMessage && currentAppConfig?.metadata.customWelcomeMessage?.message}` || '';

    const showOverriddenPredefinedQuestions = currentAppConfig?.metadata.overridePredefinedQuestions?.enabled;
    const overriddenQuestions = currentAppConfig?.metadata.overridePredefinedQuestions?.questions;

    const handleGuidelineClick = (question: string) => {
        try {
            !loading && submitMessage(question);
        } catch (error) {
            logger.error('Error in handleGuidelineClick', error);
        }
    };

    return (
        <div className="mb-8">
            <ChatMessage disableCopy text={customWelcomeMessage} />
            <div className="pl-16">
                {showOverriddenPredefinedQuestions && (
                    <div className="flex flex-col gap-y-2">
                        {overriddenQuestions?.map(question => {
                            return (
                                <div
                                    onClick={() => handleGuidelineClick(question.value)}
                                    className="ml-2 flex items-center gap-x-1  hover:border-B-800 cursor-pointer w-fit active:bg-B-100"
                                >
                                    <i className="ri-arrow-right-line text-B-800" />
                                    <p className="text-xs font-500 text-B-800">{question.key}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
