import { useEffect, useState } from 'react';
import { logger } from '../utils';

export const useTextToSpeech = (text: string) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [voiceControllerOpen, setVoiceControllerOpen] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [defaultVoice, setDefaultVoice] = useState<SpeechSynthesisVoice | null>(null);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [timerRunning, setTimerRunning] = useState<boolean>(false);

    const initializeVoice = () => {
        const voices = window.speechSynthesis.getVoices();
        const voice = voices[1];
        if (voice) {
            setDefaultVoice(voice);
        } else {
            logger.log('Desired voice not found');
        }
    };

    useEffect(() => {
        const handleSpeech = () => {
            const synth = window.speechSynthesis;
            const utterance = new SpeechSynthesisUtterance(text);

            if (synth.onvoiceschanged !== undefined) {
                synth.onvoiceschanged = initializeVoice;
            } else {
                initializeVoice();
            }

            if (defaultVoice) {
                utterance.voice = defaultVoice;
            } else {
                logger.log('Desired voice not found');
            }

            utterance.onend = () => {
                setIsSpeaking(false);
                setVoiceControllerOpen(false);
                setElapsedTime(0);
                setTimerRunning(false);
            };
            synth.speak(utterance);
        };
        if (isSpeaking) {
            handleSpeech();
        }
        return () => {
            if (isSpeaking && window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
            }
        };
    }, [isSpeaking, text, defaultVoice]);

    useEffect(() => {
        let interval: number | undefined;

        if (timerRunning) {
            interval = window.setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        } else if (!timerRunning && interval) {
            clearInterval(interval);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timerRunning]);

    const toggleSpeaking = () => {
        initializeVoice();
        if (isSpeaking || window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel();
            setTimerRunning(false);
            setElapsedTime(0);
        } else {
            setTimerRunning(true);
        }
        setIsSpeaking(!isSpeaking);
        setVoiceControllerOpen(!isSpeaking);
    };

    const onPauseResume = () => {
        const synth = window.speechSynthesis;
        if (synth.paused) {
            synth.resume();
            setIsPaused(false);
            setTimerRunning(true);
        } else if (synth.speaking) {
            synth.pause();
            setIsPaused(true);
            setTimerRunning(false);
        }
    };

    const onRestart = () => {
        setIsSpeaking(false);
        setIsPaused(false);
        setTimerRunning(false);
        setElapsedTime(0);
        setTimeout(() => {
            setIsSpeaking(true);
            setTimerRunning(true);
        }, 100);
    };

    const stopSpeaking = () => {
        setIsSpeaking(false);
        setVoiceControllerOpen(false);
        setElapsedTime(0);
        setTimerRunning(false);
        setIsPaused(false);
    };

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return {
        voiceControllerOpen,
        isPaused,
        elapsedTime,
        isSpeaking,
        timerRunning,
        stopSpeaking,
        toggleSpeaking,
        onPauseResume,
        onRestart,
        formatTime,
    };
};
