import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { IMG_KAYA_BOT_GIF, IMG_KAYA_POPUP_CLOSE, MOBILE_SCREEN_SIZE } from '../../constants';
import { useChatContext } from '../../contexts';
import { useMobile } from '../../hooks';
import { CN, getAutoExpand } from '../../utils';
import { Popover } from '../common';
import { ChatButton } from './ChatButton';
import { ChatContent } from './ChatContent';

const ChatbotPopup = () => {
    const [key, setKey] = useState(Date.now());
    const { userDetails, fullscreen, setFullscreen, cleanupState } = useChatContext();
    const expand = getAutoExpand(userDetails.email);

    const [open, setOpen] = useState(expand);
    const [leftOffset, setLeftOffset] = useState<number>(expand ? window.innerWidth - 100 : 0);

    const ref = useClickOutside(() => setOpen(false));
    const isMobile = useMobile();

    const onClickHandler = (leftOffset: number) => {
        setLeftOffset(leftOffset);
        setOpen(o => !o);
    };

    return (
        <div className="h-[100vh]">
            <ChatContent
                showHeader
                onExternalLinkClick={() => {
                    if (isMobile || fullscreen) setOpen(false);
                    setFullscreen(fullscreen => !fullscreen);
                    cleanupState();
                    setKey(Date.now());
                }}
            />
        </div>
    );
};

export { ChatbotPopup };
