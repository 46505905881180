import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import { FeedbackData, useChatContext, useSideBarContext } from '../contexts';
import { ADD_FEEDBACK } from '../graphql';
import { generateStars, logger } from '../utils';

const useFeedback = () => {
    const [feedbackText, setFeedbackText] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [feedback, setFeedback] = React.useState<FeedbackData>({
        technicalAssistance: 0,
        employeeEngagement: 0,
        dailyUsage: 0,
        file: null,
    });

    const { userDetails } = useChatContext();
    const { showFeedbackModal, setShowFeedbackModal } = useSideBarContext();

    const maximumLength = 500;
    const lengthExceeded = feedbackText.length > maximumLength;
    const lengthCount = `${feedbackText.length}/${maximumLength}`;

    const submitDisabled =
        !feedbackText ||
        !feedback.technicalAssistance ||
        !feedback.employeeEngagement ||
        !feedback.dailyUsage ||
        lengthExceeded;

    const [addFeedback] = useMutation(ADD_FEEDBACK);

    const generateFeedbackHTML = (text: string) => /* HTML */ `
        <div>
            <p>We have received feedback about our Bernie chatbot from <b>${userDetails.name}</b></p>
            <h4>📣 Feedback on Bernie</h4>
            <p>${text}</p>
            <h4>👌 Satisfaction with Bernie's Technical Assistance</h4>
            <p>${generateStars(feedback.technicalAssistance, 5)}</p>
            <h4>🤝 Usefulness of Bernie for Employee Engagement Tasks</h4>
            <p>${generateStars(feedback.employeeEngagement, 5)}</p>
            <h4>🔄 Likelihood of Continuing to Use Bernie in Daily Tasks</h4>
            <p>${generateStars(feedback.dailyUsage, 5)}</p>
        </div>
    `;

    const submitFeedback = async () => {
        const finalFeedback = generateFeedbackHTML(feedbackText);
        const variables = {
            file: feedback.file,
            input: { userId: userDetails.id, feedback: finalFeedback, userName: userDetails.name },
        };

        try {
            setLoading(true);
            const { data } = await addFeedback({ variables });
            toast.info(data?.addFeedback || 'Feedback submitted successfully!');
        } catch (error) {
            logger.error(error);
            toast.error('Failed to submit feedback. Please try again later.');
        } finally {
            setLoading(false);
            handleFeedbackModalClose();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setFeedback(prev => ({ ...prev, file: selectedFile }));
        }
    };

    const handleRatingChange = (field: keyof FeedbackData, value: number) => {
        setFeedback(prev => ({ ...prev, [field]: value }));
    };

    const handleFeedbackModalClose = () => {
        setShowFeedbackModal(false);
        resetForm();
    };

    const resetForm = () => {
        setFeedbackText('');
        setFeedback({ technicalAssistance: 0, employeeEngagement: 0, dailyUsage: 0, file: null });
    };

    const onSubmit = async () => {
        if (!submitDisabled) {
            await submitFeedback();
        }
    };

    return {
        feedback,
        loading,
        feedbackText,
        showFeedbackModal,
        submitDisabled,
        lengthExceeded,
        lengthCount,
        handleFileChange,
        handleFeedbackModalClose,
        setShowFeedbackModal,
        setFeedbackText,
        onSubmit,
        handleRatingChange,
    };
};

export { useFeedback };
