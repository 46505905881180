import { useEffect, useState } from 'react';

type LoadingStates = {
    sprint: boolean;
    [key: string]: boolean;
};

type LoadingMessages = {
    [key in keyof LoadingStates]: {
        loading: string[];
        success: string;
    };
};

interface LoadingState {
    currentMessage: string;
    showLoadingText: boolean;
    progress: number;
}

const calculateProgress = (currentIndex: number, totalMessages: number) => {
    return Math.round(((currentIndex + 1) / totalMessages) * 100);
};

const useLoadingMessages = (requirementLoading: LoadingStates, MESSAGES: LoadingMessages): LoadingState => {
    const [loadingState, setLoadingState] = useState<LoadingState>({
        currentMessage: '',
        showLoadingText: false,
        progress: 0, // Initialize progress to 0%
    });

    useEffect(() => {
        const activeLoadingKeys = Object.keys(requirementLoading).filter(key => requirementLoading[key]);

        if (activeLoadingKeys.length > 0) {
            const activeKey = activeLoadingKeys[0];
            const messages = MESSAGES[activeKey].loading;
            const lastMessageIndex = messages.length - 1;
            let currentIndex = 0;

            const updateLoadingState = () => {
                setLoadingState(prevState => ({
                    ...prevState,
                    currentMessage: messages[currentIndex],
                    showLoadingText: true,
                    progress: calculateProgress(currentIndex, messages.length),
                }));
            };

            updateLoadingState();

            const interval = setInterval(() => {
                if (currentIndex < lastMessageIndex) {
                    currentIndex++;
                    updateLoadingState();
                }
            }, 10000);

            return () => clearInterval(interval);
        } else {
            setLoadingState({ currentMessage: '', showLoadingText: false, progress: 0 });
        }
    }, [requirementLoading, MESSAGES]);

    return loadingState;
};

export { useLoadingMessages };
