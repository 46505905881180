import { SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { ALL_VALUE, NOT_AVAILABLE_VALUE } from '../../../constants';
import { useAllKPLs } from '../../../hooks';

interface IKPLSelectProps {
    onKPLChange: Function;
    value?: string | null;
    hasEmptyOption?: boolean;
    hasAllOption?: boolean;
    disabled?: boolean;
}

export const KPLSelect = ({ onKPLChange, hasEmptyOption, hasAllOption, value, disabled = false }: IKPLSelectProps) => {
    const { ALL_KPL_SELECT_LIST, findConfigKeyByTitle } = useAllKPLs();

    let KPL_LIST = ALL_KPL_SELECT_LIST?.map(config => config?.title || '') || [];

    if (hasEmptyOption) {
        KPL_LIST = [NOT_AVAILABLE_VALUE, ...KPL_LIST];
    }

    if (hasAllOption) {
        KPL_LIST = [ALL_VALUE, ...KPL_LIST];
    }

    const [currentKPL, setCurrentKPL] = useState<string>(
        value === null
            ? hasAllOption
                ? ALL_VALUE
                : NOT_AVAILABLE_VALUE
            : ALL_KPL_SELECT_LIST?.find(config => config?.configKey === value)?.title || NOT_AVAILABLE_VALUE
    );

    useEffect(() => {
        if (value === ALL_VALUE) {
            setCurrentKPL(value);
        }

        if (value === null || value === undefined) {
            setCurrentKPL(hasAllOption ? ALL_VALUE : hasEmptyOption ? NOT_AVAILABLE_VALUE : KPL_LIST[0]);
        }
    }, [value]);

    return (
        <SearchInput
            className="w-full"
            placeholder="Select KPL"
            value={currentKPL}
            view="default"
            items={KPL_LIST}
            disabled={disabled}
            onChange={(value: string) => {
                const configKey = findConfigKeyByTitle(value);
                setCurrentKPL(value);
                onKPLChange({
                    title: value,
                    configKey: value === NOT_AVAILABLE_VALUE || value === ALL_VALUE ? null : configKey,
                });
            }}
        />
    );
};
