import React from 'react';

interface IAdminPanelLayoutProps {
    children: React.ReactNode;
}

export const AdminPanelLayout = ({ children }: IAdminPanelLayoutProps) => {
    return (
        <div className="sm:px-2 px-6 pt-8 pb-6 bg-white h-full flex justify-start w-full overflow-hidden">
            {children}
        </div>
    );
};
