import { Accordion, Button } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { CSS_INPUT } from '../../../constants';
import { downloadCodeAnalysisReport } from '../../../services';
import { CN } from '../../../utils';
import { ChatMessage, CommonMessage, FormattedChatMessage, ScrollableWrapper } from '../../common';

const calculateProgress = (currentIndex: number, totalMessages: number) => {
    return Math.round(((currentIndex + 1) / totalMessages) * 100);
};

const useLoadingMessages = (loading: boolean, messages: string[]): any => {
    const [loadingState, setLoadingState] = useState<any>({
        currentMessage: '',
        showLoadingText: false,
        progress: 0,
    });

    useEffect(() => {
        let currentIndex = 0;
        let interval: any = null;

        const startLoading = () => {
            interval = setInterval(() => {
                currentIndex = (currentIndex + 1) % messages.length;
                setLoadingState((prevState: any) => ({
                    ...prevState,
                    currentMessage: messages[currentIndex],
                    showLoadingText: true,
                    progress: calculateProgress(currentIndex, messages.length),
                }));
            }, 3000);
            // Set initial state immediately
            setLoadingState((prevState: any) => ({
                ...prevState,
                currentMessage: messages[currentIndex],
                showLoadingText: true,
                progress: calculateProgress(currentIndex, messages.length),
            }));
        };

        const stopLoading = () => {
            clearInterval(interval);
            setLoadingState({ currentMessage: '', showLoadingText: false, progress: 0 });
        };

        if (loading) {
            startLoading();
        } else {
            stopLoading();
        }

        return () => {
            clearInterval(interval);
        };
    }, [loading, messages]);

    return loadingState;
};

const DynamicCodeAnalysis = ({ workflowJson }: { workflowJson: any }) => {
    const { workflow } = workflowJson;
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedRepository, setSelectedRepository] = useState('');
    const [selectedPullRequest, setSelectedPullRequest] = useState('');
    const [loading, setLoading] = useState(false);
    const [startAnalysisLoading, setStartAnalysisLoading] = useState(false);
    const [allAnalysesCompleted, setAllAnalysesCompleted] = useState(false);
    const [requirementInput, setRequirementInput] = useState('');

    const { currentMessage, progress } = useLoadingMessages(loading, workflow.loadingMessages);

    const executeAnalysis = (analysisFunctionName: string) => {
        setLoading(true);

        const analysisFunctions: { [key: string]: () => void } = {
            onAnalyzeCodeQuality: onAnalyzeCodeQuality,
            onAnalyzeMergeConflicts: onAnalyzeMergeConflicts,
            onAnalyzeChangeImpact: onAnalyzeChangeImpact,
            onAnalyzeRequirements: onAnalyzeRequirements,
        };

        analysisFunctions[analysisFunctionName]?.();
    };

    const onStartAnalysis = () => {
        setStartAnalysisLoading(true);
        setTimeout(() => {
            setStartAnalysisLoading(false);
            setCurrentStep(prev => prev + 1);
        }, workflow.startAnalysisDuration);
    };

    const onAnalyzeCodeQuality = () => {
        setTimeout(() => {
            setLoading(false);
            setCurrentStep(prev => prev + 1);
        }, workflow.analysisDurations.codeQuality);
    };

    const onAnalyzeMergeConflicts = () => {
        setTimeout(() => {
            setLoading(false);
            setCurrentStep(prev => prev + 1);
        }, workflow.analysisDurations.mergeConflicts);
    };

    const onAnalyzeChangeImpact = () => {
        setTimeout(() => {
            setLoading(false);
            setCurrentStep(prev => prev + 1);
        }, workflow.analysisDurations.changeImpact);
    };

    const onAnalyzeRequirements = () => {
        setTimeout(() => {
            setLoading(false);
            setAllAnalysesCompleted(true);
            setCurrentStep(prev => prev + 1);
        }, workflow.analysisDurations.requirements);
    };

    const renderStep = (step: any) => {
        if (!step.condition) return null;

        const sharedButtonStyles =
            'bg-B-500 hover:bg-B-400 py-2 px-4 cursor-pointer text-white border-none rounded-md w-full';

        switch (step.type) {
            case 'chat_message':
                return <ChatMessage disableCopy={true} isUser={false} text={step.message} />;
            case 'common_message':
                return (
                    <CommonMessage
                        isUser
                        disableCopy
                        text={step.message}
                        content={
                            <>
                                <div className="my-2 text-md">{step.promptText}</div>
                                {step.ui_fields.map((field: any) => renderUIField(field))}
                                <Button
                                    isLoading={startAnalysisLoading}
                                    onClick={onStartAnalysis}
                                    isDisabled={!selectedRepository || !selectedPullRequest}
                                    className={sharedButtonStyles}
                                >
                                    {step.buttonText}
                                </Button>
                            </>
                        }
                    />
                );
            case 'input_step':
                return (
                    <CommonMessage
                        isUser={false}
                        disableCopy
                        text={step.title}
                        content={
                            <>
                                <div className="w-[80%]">
                                    <FormattedChatMessage text={step.description} />
                                    <div className="w-full my-2">
                                        <textarea
                                            id="requirement-input"
                                            rows={5}
                                            className={CN(`${CSS_INPUT}  w-full bg-card-default text-N-900`)}
                                            value={requirementInput}
                                            onChange={e => setRequirementInput(e.target.value)}
                                            placeholder="Describe the new requirement..."
                                        />
                                    </div>
                                    <Button
                                        onClick={() => executeAnalysis(step.onExecute)}
                                        className={sharedButtonStyles}
                                    >
                                        {step.buttonText}
                                        <i className="ri-forward-end-mini-line mx-2"></i>
                                    </Button>
                                </div>
                            </>
                        }
                    />
                );
            case 'analysis_step':
                return (
                    <CommonMessage
                        isUser={false}
                        disableCopy
                        text={step.title}
                        content={
                            <>
                                <div className="w-[80%]">
                                    <FormattedChatMessage text={step.overview} />
                                    <div className="border border-N-200 my-2">
                                        <Accordion
                                            items={[
                                                {
                                                    title: <span className="text-sm">View Detailed Analysis</span>,
                                                    content: (
                                                        <div className="p-4">
                                                            <FormattedChatMessage text={step.description} />
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="flex gap-2 justify-between my-2">
                                        {step.buttonText && (
                                            <Button
                                                onClick={() => executeAnalysis(step.onExecute)}
                                                className={sharedButtonStyles}
                                            >
                                                {step.buttonText}
                                                <i className="ri-forward-end-mini-line mx-2"></i>
                                            </Button>
                                        )}
                                        <Button
                                            onClick={() => downloadCodeAnalysisReport(step.title, step.description)}
                                            className={sharedButtonStyles}
                                        >
                                            Download Analysis Report <i className="ri-download-2-line mx-2"></i>
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                    />
                );
            default:
                return null;
        }
    };

    const renderUIField = (field: any) => {
        const inputStyles = 'my-2 p-2 text-N-600 w-full';

        switch (field.type) {
            case 'bitbucket_repository':
                return (
                    <div className="w-full">
                        <label htmlFor="repository">{field.label}:</label>
                        <select
                            id="repository"
                            className={inputStyles}
                            onChange={e => {
                                setSelectedRepository(e.target.value);
                                setSelectedPullRequest('');
                            }}
                        >
                            <option>{field.placeholder}</option>
                            {field.repositories.map((repo: any) => (
                                <option key={repo.id} value={repo.name}>
                                    {repo.name}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            case 'bitbucket_pull_request':
                return (
                    <div className="w-full">
                        <label htmlFor="pull-request">{field.label}:</label>
                        <select
                            id="pull-request"
                            className={inputStyles}
                            onChange={e => setSelectedPullRequest(e.target.value)}
                        >
                            <option>{field.placeholder}</option>
                            {field.pullRequests.map((pr: any) => (
                                <option key={pr.id} value={pr.id}>
                                    {pr.title}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <ScrollableWrapper className="py-2">
            {workflow.steps.slice(0, currentStep + 1).map((step: any, index: number) => (
                <div key={index}>{renderStep(step)}</div>
            ))}
            {loading && (
                <CommonMessage
                    isUser={false}
                    disableCopy
                    text="Running Analysis..."
                    content={
                        <div>
                            {currentMessage}
                            <div className="bg-N-400 rounded-full h-2.5 animate-fade my-1">
                                <div
                                    className="bg-B-600 h-2.5 rounded-full animate-pulse"
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                        </div>
                    }
                />
            )}
            {allAnalysesCompleted && (
                <CommonMessage
                    isUser={false}
                    disableCopy
                    text="All analyses are complete! 🎉"
                    content={
                        <div>
                            <div className="text-md mb-2">
                                <p>All analysis steps have been completed successfully! 🎉</p>
                            </div>
                            <Button
                                onClick={() =>
                                    downloadCodeAnalysisReport(
                                        workflow.finalReportTitle,
                                        workflow.steps
                                            .filter((step: any) => step.description)
                                            .map((step: any) => step.description)
                                            .join('\n')
                                    )
                                }
                                className="bg-B-500 hover:bg-B-400 py-2 px-4 cursor-pointer text-white border-none rounded-md w-full"
                            >
                                {workflow.finalReportButtonText}
                                <i className="ri-download-2-line mx-2"></i>
                            </Button>
                        </div>
                    }
                />
            )}
        </ScrollableWrapper>
    );
};

interface ICodeAnalysis {
    metadata: any;
}

const CodeAnalysis = ({ metadata }: ICodeAnalysis) => {
    return <DynamicCodeAnalysis workflowJson={metadata} />;
};

export { CodeAnalysis };
