import { useSideBarContext } from '../../../contexts';
import { useMobile } from '../../../hooks';
import { CN } from '../../../utils';

const ScrollDownButton = ({ scrollToBottom }: { scrollToBottom: () => void }) => {
    const isMobile = useMobile();
    const { isCollapsed } = useSideBarContext();

    return (
        <button
            onClick={scrollToBottom}
            className={CN('absolute transform -translate-x-1/2 cursor-pointer bottom-[12em] left-1/2 hover:font-bold', {
                '!left-[65%]': !isMobile && !isCollapsed,
            })}
        >
            <i className="ri-arrow-down-line bg-white border border-N-200 rounded-full shadow-lg text-[18px] p-[4px]"></i>
        </button>
    );
};

export { ScrollDownButton };
