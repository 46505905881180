import { useMutation } from '@apollo/client';
import { Button } from '@kaya/kaya-ui-design-system-pb';
import { MessageAuthor } from '../../../__generated__/graphql';
import { SYSTEM_TEMP_ID } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { ADD_ACTION_ON_DISLIKE } from '../../../graphql/mutation';
import { getCallReportsUser, uniqueId } from '../../../utils';

function isEnabledResponse(messages: any[], callReportQuestions: any[], responseId: string): boolean {
    try {
        const lastElement = messages[messages.length - 1];
        const oneBeforeLastElement = messages[messages.length - 2];

        if (oneBeforeLastElement?.author === MessageAuthor.User) {
            const correspondingQuestion = callReportQuestions.find(
                item => item?.question === oneBeforeLastElement?.content
            );
            if (correspondingQuestion?.enabled) {
                if (lastElement?.id === responseId) {
                    return true;
                }
            }
        }

        return false;
    } catch (error) {
        return false;
    }
}

const RouteCallReports = ({ messageId, enableCallReports }: { messageId: string; enableCallReports: boolean }) => {
    const { userDetails, streaming, callReportQuestions, messages, setMessages } = useChatContext();
    const [addActionOnDislike, { loading }] = useMutation(ADD_ACTION_ON_DISLIKE);

    const userFullName = getCallReportsUser(userDetails.email);
    const responseEnabled = isEnabledResponse(messages, callReportQuestions, messageId);

    if (!enableCallReports || !responseEnabled || streaming) return null;

    return (
        <Button
            iconAfter={loading ? 'ri-loader-3-fill' : 'ri-send-plane-fill'}
            className="flex justify-center cursor-pointer bg-transparent text-white hover:border-N-100 my-2 py-2 px-4 border border-N-400 rounded-md w-full text-left hoverEffect disabled:bg-N-500 disabled:cursor-not-allowed"
            onClick={async () => {
                await addActionOnDislike({
                    variables: {
                        input: {
                            messageId: messageId,
                            type: 'call-reports',
                            user: userFullName,
                        },
                    },
                });
                setMessages(prevMessages => [
                    ...prevMessages,
                    {
                        id: `${SYSTEM_TEMP_ID}_${uniqueId()}`,
                        content: `✅ Your request to route the identified anomalies to our SME <b>${userFullName}</b> for further analysis has been sent successfully.`,
                        author: MessageAuthor.System,
                    },
                ]);
            }}
        >
            Route the identified anomalies to our SME <b>{userFullName}</b> for further analysis
        </Button>
    );
};

export { RouteCallReports };
