import { CheckBox } from '@kaya/kaya-ui-design-system-pb';
import { SETTING_TYPES } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { MobileMenuButton } from '../buttons';

export const Settings = () => {
    const { currentAppConfig, settings, setSettings } = useChatContext();
    type KeyType = keyof typeof settings.options;

    const toggleSettingOption = (optionKey: string, isEnabled: boolean) => {
        if (!isEnabled) return;

        setSettings({
            ...settings,
            options: {
                ...settings.options,
                [optionKey]: !settings.options[optionKey as KeyType],
            },
        });
    };

    const isOptionEnabled = (optionKey: string) => {
        return currentAppConfig?.metadata[optionKey as KeyType]?.enabled;
    };

    const isOptionChecked = (optionKey: string) => {
        return settings.options[optionKey as KeyType];
    };

    return (
        <div>
            <div className="flex justify-between items-center border-b border-b-N-200">
                <div className="chat_history_title py-[20px] sm:py-4 px-6 sm:px-3 flex gap-x-[10px] items-center border-b-N-200">
                    <p className="text-h6 sm:text-lg font-600 text-N-900">Settings</p>
                </div>
                <MobileMenuButton />
            </div>
            <div className="flex flex-col gap-y-2 px-6 py-4">
                <div className="flex items-center gap-x-1">
                    <i className="ri-menu-search-line text-h5 text-N-600" />
                    <p className="text-md text-N-600 mb-1">KPL Options</p>
                </div>
                <div className="pl-4 flex flex-col gap-y-3">
                    {SETTING_TYPES.map(({ key, name, description }) => {
                        const isEnabled = Boolean(isOptionEnabled(key));
                        const isChecked = Boolean(isOptionChecked(key));

                        return (
                            <CheckBox
                                disabled={!isEnabled}
                                className={!isEnabled ? 'disabled-checkbox' : ''}
                                key={key}
                                value={key}
                                checked={isChecked}
                                onChange={() => toggleSettingOption(key, isEnabled)}
                                label={
                                    (
                                        <>
                                            <span>{name}</span>
                                            <div className="relative ml-1 group">
                                                <i className="ri-information-line cursor-pointer"></i>
                                                <div className="w-[200px] absolute left-1/2 transform -translate-x-1/2 hidden bottom-full mb-2 group-hover:block bg-N-100 text-black text-xs rounded text-wrap px-2 py-1 text-center z-50 sm:-translate-y-[-100px] sm:mb-8 sm:w-[200px]">
                                                    {description}
                                                </div>
                                            </div>
                                        </>
                                    ) as any
                                }
                                id={key}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
