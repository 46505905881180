import React from 'react';
import { ReferencePDFViewerModal } from '../reference-pdf-viewer-modal';
import { FactCheckViewerModal } from '../fact-check-viewer-modal';

export const ChatContentModalContainer = () => {
    return (
        <div>
            {/* This modal is to show highlighted PDF references  */}
            <ReferencePDFViewerModal />
            {/* This modal is to show Google Fact Check Response along with the LLM comparison */}
            <FactCheckViewerModal />
        </div>
    );
};
