import { MultiSelectableInput } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { MODEL_TYPES } from '../../../constants';

interface IModelMultiSelectProps {
    onChange: Function;
    width?: string;
    taglength?: number;
    selectedModels: any;
}

export const ModelMultiSelect = ({ onChange, width = '', taglength = 3, selectedModels }: IModelMultiSelectProps) => {
    const [currentModels, setCurrentModels] = useState<any[]>([]);

    const SELECTABLE_MODELS = MODEL_TYPES.map(modelType => ({
        id: modelType.model,
        name: modelType.name,
    }));

    useEffect(() => {
        setCurrentModels(SELECTABLE_MODELS.filter(model => selectedModels.includes(model.id)) || []);
    }, [selectedModels]);

    return (
        <MultiSelectableInput
            taglength={taglength}
            width={width}
            showTags
            selectedValues={currentModels}
            placeholder="Select Models"
            selectableItems={SELECTABLE_MODELS}
            onSelect={(model: any) => {
                onChange(model);
            }}
        />
    );
};
