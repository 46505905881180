import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { CSS_INPUT, CSS_SCROLL } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { CN } from '../../../utils';
import { Step, getParsedDetailed, getParsedMeta } from './types';

const NextStepModel = ({ open, step, onClose }: { open: boolean; step: Step; onClose: () => void }) => {
    const { setShowJiraButtons, setTechnicalRequirementValue } = useChatContext();
    const ref = useClickOutside(() => onClose());
    const [showEdit, setShowEdit] = useState(false);
    const [value, setValue] = useState(typeof step.response === 'string' ? getParsedDetailed(step.response) : '');
    const [editClicked, setEditClicked] = useState({
        business: false,
        technical: false,
    });

    if (!open) return null;

    const active = 'bg-B-500 hover:bg-B-400 text-white py-2 px-4 rounded-md w-full';
    const normal = 'bg-N-500 hover:bg-N-400 text-white py-2 px-4 rounded-md w-full';

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div
                className="fixed bottom-[5em] right-[20em] p-4 pt-2 bg-white border border-N-200 drop-shadow-sm rounded-md w-full max-w-md m-auto"
                ref={ref}
            >
                <div className="flex justify-between items-center">
                    <p className="text-2xl font-bold">Assistance Guide</p>
                </div>
                <p className="my-2 py-2">Need assistance? Select an option below to continue.</p>
                <div className="flex gap-2">
                    {showEdit ? (
                        <>
                            <div className="flex flex-col gap-1 w-full">
                                {step.id === 'technical-jira-requirements' && (
                                    <div className="radio-group flex flex-col mb-2">
                                        <label className="flex align-middle gap-1 font-bold cursor-pointer">
                                            <input
                                                type="radio"
                                                name="requirementSource"
                                                value="transcript"
                                                defaultChecked
                                                onClick={() => {
                                                    const currentValue = getParsedDetailed(step.response);
                                                    setValue(currentValue);
                                                    setTechnicalRequirementValue(currentValue);
                                                }}
                                            />
                                            Use technical requirements extracted from transcript
                                        </label>
                                        <label className="flex align-middle gap-1 font-bold cursor-pointer">
                                            <input
                                                type="radio"
                                                name="requirementSource"
                                                value="bernie"
                                                onClick={() => {
                                                    const currentValue = getParsedMeta(step.response);
                                                    setValue(currentValue);
                                                    setTechnicalRequirementValue(currentValue);
                                                }}
                                            />
                                            Use AI suggestions from Bernie
                                        </label>
                                    </div>
                                )}
                                <textarea
                                    className={CN(
                                        `${CSS_INPUT} ${CSS_SCROLL} w-full !h-[15em] bg-card-default text-N-900`
                                    )}
                                    value={value || step.response}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setValue(e.target.value);
                                    }}
                                />
                                <div className="flex gap-2">
                                    <button
                                        className="bg-B-500 hover:bg-B-400 text-white py-2 px-4 rounded-md w-full"
                                        onClick={() => {
                                            setShowEdit(false);
                                        }}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="bg-R-600 hover:bg-N-500 text-white py-2 px-4 rounded-md w-full"
                                        onClick={() => {
                                            setShowEdit(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col w-full gap-1">
                            <button
                                className={
                                    step.id === 'business-requirements' && !editClicked.business ? active : normal
                                }
                                onClick={() => {
                                    setShowEdit(true);
                                    setEditClicked(s => ({ ...s, business: true }));
                                }}
                            >
                                Review requirements{' '}
                                <span role="img" aria-label="pencil" className="mx-2">
                                    📝
                                </span>
                            </button>
                            <button
                                className={
                                    step.id === 'business-requirements' && editClicked.business ? active : normal
                                }
                                onClick={() => {
                                    setShowJiraButtons(s => ({ ...s, business: true }));
                                    onClose();
                                }}
                            >
                                Save requirements in JIRA{' '}
                                <span role="img" aria-label="pencil" className="mx-2">
                                    📥
                                </span>
                            </button>
                            <button
                                className={
                                    step.id === 'technical-jira-requirements' && !editClicked.technical
                                        ? active
                                        : normal
                                }
                                onClick={() => {
                                    setShowEdit(true);
                                    setEditClicked(s => ({ ...s, technical: true }));
                                }}
                            >
                                Review technical requirements{' '}
                                <span role="img" aria-label="pencil" className="mx-2">
                                    📝
                                </span>
                            </button>
                            <button
                                className={
                                    step.id === 'technical-jira-requirements' && editClicked.technical ? active : normal
                                }
                                onClick={() => {
                                    setShowJiraButtons(s => ({ ...s, technical: true }));
                                    onClose();
                                }}
                            >
                                Save technical requirements in JIRA{' '}
                                <span role="img" aria-label="pencil" className="mx-2">
                                    📥
                                </span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export { NextStepModel };
