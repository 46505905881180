import { useViewportSize } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { CN } from '../../../utils';
import { ModalContainer } from '../modal-container';
import {
    SidebarAvatarSection,
    SidebarLogoSection,
    SidebarPrimaryBtnSection,
    SidebarSecondaryBtnSection,
} from './common';
import { CustomKplSectionCreationSteps, useCustomKplContext, useSideBarContext } from '../../../contexts';
import { useGuestCustomKpl, useMobile } from '../../../hooks';

export const Sidebar = () => {
    const { height } = useViewportSize();
    const { isMenuCollapsed } = useSideBarContext();
    const isLandscape = height < 515;
    const isMobile = useMobile();
    const { handleDefaultSidebarSelection } = useGuestCustomKpl();
    const { isGuestKplCreated, currentStep } = useCustomKplContext();

    useEffect(() => {
        if (!isMobile && currentStep !== CustomKplSectionCreationSteps?.KPL_CREATED) handleDefaultSidebarSelection();
    }, [isMobile, isGuestKplCreated]);

    return (
        <React.Fragment>
            <div
                className={CN(
                    'sidebar flex flex-col justify-between bg-white border-r border-r-N-200  transition-all duration-300 ease-in-out z-10 h-full',
                    {
                        'overflow-y-scroll overflow-x-hidden': isLandscape,
                        'min-w-[70px] w-[70px]': !isMenuCollapsed,
                        'min-w-[350px] w-[350px] xxl:min-w-[280px]  xxl:w-[280px]': isMenuCollapsed,
                        '!min-w-screen !w-screen !h-[calc(100dvh-143px)] overflow-y-auto': isMobile,
                    }
                )}
            >
                <div className="flex flex-col gap-y-6 h-full w-full">
                    {!isMobile && <SidebarLogoSection />}
                    <div
                        className={CN('menu_container flex flex-col gap-y-3 h-full', {
                            ' pb-3': !isMobile,
                        })}
                    >
                        <div className="flex flex-col gap-y-3">
                            <SidebarPrimaryBtnSection />
                        </div>
                        <div className="flex flex-col gap-y-3 mt-auto">
                            <SidebarSecondaryBtnSection />
                            <div className="bg-[#E5E7EB] h-[1px] w-full" />
                            {!isMobile && <SidebarAvatarSection />}
                        </div>
                    </div>
                </div>
            </div>

            {/* All the UI modal related to the ExtendedSideBar & SideBar */}
            <ModalContainer />
        </React.Fragment>
    );
};
