import { IMG_KAYA_BOT_GIF } from '../../../../constants';
import { useSideBarContext } from '../../../../contexts';
import { useMobile } from '../../../../hooks';
import { CN } from '../../../../utils';

export const SidebarLogoSection = () => {
    const { isMenuCollapsed } = useSideBarContext();
    const isMobile = useMobile();
    return (
        <div className="sidebar_logo_section border-b border-b-N-200">
            <div
                className={CN('sidebar_logo pt-[12px] pb-[16px] px-3', {
                    '!p-0': isMobile,
                })}
            >
                <div className="logo flex gap-x-3 items-center">
                    <img
                        alt="Kaya Chatbot GIF"
                        height={40}
                        width={40}
                        src={IMG_KAYA_BOT_GIF}
                        className="rounded-[14px] sm:w-10"
                    />
                    {(isMenuCollapsed || isMobile) && (
                        <h4 className="text-[36px] leading-3 mt-2 w-fit font-900 font-overpass text-[#328ee0] drop-shadow">
                            Bernie
                        </h4>
                    )}
                </div>
            </div>
        </div>
    );
};
