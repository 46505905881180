import { Button, CheckBox, IconSelector, TextField } from '@kaya/kaya-ui-design-system-pb';
import { MAX_CHAR_LIMIT, SETTING_TYPES } from '../../../../../constants';
import { KPLFormData, useAdminPanelContext } from '../../../../../contexts';
import { enforceCharLimit } from '../../../../../utils';
import { ExplanationTooltip } from '../explanation-tooltip';
import { MetaDataSection } from '../meta-data-section';

export const KPLCreationInnerForm = () => {
    const { kplFormData, setKPLFormData } = useAdminPanelContext();

    const handleInputChange = (field: keyof KPLFormData, value: string) => {
        setKPLFormData(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleQuestionChange = (index: number, value: string) => {
        const updatedQuestions = [...kplFormData.predefinedQuestions];
        updatedQuestions[index] = value;
        setKPLFormData(prevState => ({
            ...prevState,
            predefinedQuestions: updatedQuestions,
        }));
    };

    const toggleSettingOption = (optionKey: string) => {
        setKPLFormData(prevState => ({
            ...prevState,
            additionalSettings: {
                ...prevState.additionalSettings,
                [optionKey]: !prevState.additionalSettings[optionKey],
            },
        }));
    };

    const isOptionChecked = (optionKey: string) => {
        return kplFormData.additionalSettings[optionKey];
    };

    return (
        <div className="flex flex-col gap-y-6 h-fit">
            <div className="flex gap-x-6 items-center">
                <div className="flex flex-col gap-y-2 w-full">
                    <p className="text-xs font-500 text-N-900 flex gap-x-1">
                        Title <span className="text-R-700">*</span>
                        <ExplanationTooltip
                            id="title-tooltip"
                            description="Add Title for your KPL. This title will be shown as the KPL title from the Explore KPLs option."
                        />
                    </p>
                    <TextField
                        view="default"
                        placeholder="Enter your KPL title"
                        value={kplFormData.title}
                        onChange={(e: any) => {
                            const limitedValue = enforceCharLimit(e?.target?.value, MAX_CHAR_LIMIT.title);
                            handleInputChange('title', limitedValue);
                        }}
                    />
                </div>
                <div className="flex flex-col gap-y-2 w-full">
                    <p className="text-xs font-500 text-N-900 flex gap-x-1">
                        Icon <span className="text-R-700">*</span>
                        <ExplanationTooltip
                            id="icon-tooltip"
                            description="Select an icon for your KPL. This icon will be displayed as the KPL icon from the Explore KPLs option. Find icons on remixicon.com"
                        />
                    </p>
                    <div id="icon-selector" className="w-full">
                        <IconSelector
                            onChange={(icon: string) => handleInputChange('icon', icon)}
                            defaultIcon={kplFormData.icon}
                        />
                    </div>
                </div>
            </div>
            <div className="border-t border-t-N-200" />
            <div className="flex flex-col gap-y-2 w-full">
                <p className="text-xs font-500 text-N-900 flex gap-x-1">
                    Description <span className="text-R-700">*</span>
                    <ExplanationTooltip
                        id="short-description-tooltip"
                        description="This will be the description that will be shown along with the KPL title in the Explore KPLs tile. Try to make this short."
                    />
                </p>
                <TextField
                    rows={2}
                    view="default"
                    placeholder="Enter your KPL Description"
                    value={kplFormData.shortDescription}
                    onChange={(e: any) => {
                        const limitedValue = enforceCharLimit(e?.target?.value, MAX_CHAR_LIMIT.shortDescription);
                        handleInputChange('shortDescription', limitedValue);
                    }}
                />
            </div>
            <div className="flex flex-col gap-y-2 w-full">
                <p className="text-xs font-500 text-N-900 flex gap-x-1">
                    Information Icon Tooltip content <span className="text-R-700">*</span>
                    <ExplanationTooltip
                        id="detailed-description-tooltip"
                        description="This will be the description that will be shown in the tooltip when you click on the information icon near the KPL title when you selected a KPL. Make sure to add details such as what sort of information are there in the KPL and what is the time period of these information etc."
                    />
                </p>
                <TextField
                    rows={5}
                    view="default"
                    placeholder="Enter your content"
                    value={kplFormData.detailedDescription}
                    onChange={(e: any) => {
                        const limitedValue = enforceCharLimit(e?.target?.value, MAX_CHAR_LIMIT.detailedDescription);
                        handleInputChange('detailedDescription', limitedValue);
                    }}
                />
            </div>
            <div className="addition-question-section flex flex-col gap-y-2">
                <p className="text-xs font-500 text-N-900 flex gap-x-1">
                    Enable Additional Settings
                    <ExplanationTooltip
                        id="additional-settings-tooltip"
                        description="Advanced KPL settings, you can enable or disable these settings based on your requirement."
                    />
                </p>
                <div className="flex flex-row gap-2">
                    {SETTING_TYPES.map(({ key, name }) => (
                        <CheckBox
                            key={key}
                            value={key}
                            checked={isOptionChecked(key)}
                            onChange={() => toggleSettingOption(key)}
                            label={name}
                            id={key}
                        />
                    ))}
                </div>
            </div>
            <div className="border-t border-t-N-200" />
            <MetaDataSection />
            <div className="border-t border-t-N-200" />
            <div className="predefined-question-section flex flex-col gap-y-2 mb-[10vh] sm:mb-0">
                <p className="text-xs font-500 text-N-900 flex gap-x-1">
                    Predefined Questions
                    <ExplanationTooltip
                        id="predefined-questions-tooltip"
                        description="If you need to show any pre defined questions as conversation starters for the user, you can either put a tick on the below mentioned box where it will automatically generates the pre defined questions for you based on the data ingestion for the KPL or otherwise if you already have some predefined questions with you, you can enter them to the test boxes below."
                    />
                </p>
                <div className="flex flex-col my-2">
                    <CheckBox
                        value="autoGenerate"
                        checked={kplFormData.autoGenerate}
                        onChange={() =>
                            setKPLFormData(prevState => ({
                                ...prevState,
                                autoGenerate: !prevState.autoGenerate,
                            }))
                        }
                        label="Auto Generate Predefined Questions"
                        id="autoGenerate"
                    />
                    {kplFormData.autoGenerate && (
                        <div className="flex flex-col text-A-500 my-2">
                            <div className="flex items-center">
                                <i className="ri-information-fill mr-2 text-sm"></i>
                                <p className="text-sm font-semibold">Important Information</p>
                            </div>
                            <ul className="list-disc ml-4 text-xs mt-1 space-y-1">
                                <li>
                                    Questions are generated based on the ingested data for the selected filter criteria.
                                </li>
                                <li>If no data is available, questions will not be generated.</li>
                                <li>Existing predefined questions will remain unchanged.</li>
                                <li>
                                    This process runs in the background, so please check back later for the generated
                                    questions.
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                <div className="flex flex-col gap-y-4">
                    {kplFormData.predefinedQuestions.map((question, index) => {
                        const isLastQuestion = kplFormData.predefinedQuestions.length - 1 === index;

                        return (
                            <div className="flex items-start gap-2">
                                <TextField
                                    key={index}
                                    view="default"
                                    placeholder="Provide Question Here..."
                                    value={question}
                                    onChange={(e: any) => {
                                        const limitedValue = enforceCharLimit(
                                            e?.target?.value,
                                            MAX_CHAR_LIMIT.predefinedQuestionTitle
                                        );
                                        handleQuestionChange(index, limitedValue);
                                    }}
                                    wrapperClassName="w-full"
                                    disabled={kplFormData.autoGenerate}
                                />
                                <Button
                                    size="medium"
                                    appearance={kplFormData.autoGenerate ? undefined : 'primary'}
                                    view="outline"
                                    iconBefore={isLastQuestion ? 'ri-add-line' : 'ri-subtract-line'}
                                    isDisabled={kplFormData.autoGenerate}
                                    onClick={() => {
                                        if (isLastQuestion) {
                                            setKPLFormData(prevState => ({
                                                ...prevState,
                                                predefinedQuestions: [...prevState.predefinedQuestions, ''],
                                            }));
                                        } else {
                                            setKPLFormData(prevState => ({
                                                ...prevState,
                                                predefinedQuestions: prevState.predefinedQuestions.filter(
                                                    (_, i) => i !== index
                                                ),
                                            }));
                                        }
                                    }}
                                ></Button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
