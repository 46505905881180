import { TextField, Button, UIModalV2, Tooltip, SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { EmailContent } from '../messageMaestroKPL';
import { uniqueId } from '../../../../utils';
import { useState } from 'react';
import { DEMO_MSG_MAESTRO_EMAIL } from '../../../../constants';

interface EmailEditModalProps {
    emailContent: EmailContent | null;
    isOpen: boolean;
    selectedEmailId: any;
    onClose: () => void;
    onSave: (updatedEmail: EmailContent) => void;
    onSubjectChange: (e: any) => void;
    onBodyChange: (e: any) => void;
}

const EmailEditModal: React.FC<EmailEditModalProps> = ({
    emailContent,
    isOpen,
    selectedEmailId,
    onClose,
    onSave,
    onSubjectChange,
    onBodyChange,
}) => {
    if (!emailContent) return null;

    const [emailSubject, setEmailSubject] = useState<any>(emailContent?.subject);
    const [emailBody, setEmailBody] = useState<any>(emailContent?.body);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const toneMapping: { [key: string]: { subject: string; body: string } } = {
        professional: {
            subject: emailContent?.ProfessionalEmailSubject || '',
            body: emailContent?.ProfessionalEmailBody || '',
        },
        friendly: {
            subject: emailContent?.FriendlyEmailSubject || '',
            body: emailContent?.FriendlyEmailBody || '',
        },
        informative: {
            subject: emailContent?.InformativeEmailSubject || '',
            body: emailContent?.InformativeEmailBody || '',
        },
        normal: (() => {
            const email = DEMO_MSG_MAESTRO_EMAIL.find(email => email.id === emailContent?.id);
            return {
                subject: email?.subject || '',
                body: email?.body || '',
            };
        })(),
    };

    const handleToneChange = (tone: string) => {
        setIsLoading(true);
        setTimeout(() => {
            const { subject, body } = toneMapping[tone] || { subject: '', body: '' };
            setEmailSubject(subject);
            setEmailBody(body);
            onSubjectChange(subject);
            onBodyChange(body);
            setIsLoading(false);
        }, 3500);
    };

    const handleSave = () => {
        setIsLoading(true);
        setTimeout(() => {
            onSave(selectedEmailId);
            setIsLoading(false);
            onClose();
        }, 1500);
    };

    return (
        <UIModalV2
            header={
                <div className="flex items-center justify-between">
                    <div className="flex gap-x-3">
                        <i className="ri-body-scan-fill text-h6" />
                        <p className="text-md text-N-900 font-500">Edit Email</p>
                    </div>
                </div>
            }
            body={
                <>
                    {isLoading ? (
                        <div className="flex items-center justify-center text-center w-full h-[350px]">
                            <SpinLoader />
                            <span className="ms-5">Wait a moment...</span>
                        </div>
                    ) : (
                        <div>
                            <div className="flex flex-col mt-3">
                                <p>Subject: </p>
                                <TextField
                                    isLoading={isLoading}
                                    defaultValue={emailSubject}
                                    onChange={(e: any) => {
                                        onSubjectChange(e.target.value);
                                    }}
                                    view="default"
                                    wrapperClassName="w-full"
                                />
                            </div>
                            <textarea
                                disabled={isLoading}
                                className="w-full border-none h-[45vh] p-4 my-1 border border-gray-300 rounded-md resize-none"
                                defaultValue={emailBody}
                                onChange={(e: any) => {
                                    onBodyChange(e.target.value);
                                }}
                                placeholder="Edit email content here..."
                            />
                        </div>
                    )}
                    <div className="flex space-x-2 float-end">
                        {/* Professional Tone Button */}
                        <Tooltip
                            id={uniqueId()}
                            place="top"
                            variant="dark"
                            tooltipType="detailed"
                            title="Set to Professional Tone"
                        >
                            <Button
                                className="bg-R-500 hover:bg-R-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                iconBefore="ri-briefcase-3-fill"
                                view="outline"
                                isDisabled={isLoading}
                                onClick={() => handleToneChange('professional')}
                            ></Button>
                        </Tooltip>

                        {/* Friendly Tone Button */}
                        <Tooltip
                            id={uniqueId()}
                            place="top"
                            variant="dark"
                            tooltipType="detailed"
                            title="Set to Friendly Tone"
                        >
                            <Button
                                isDisabled={isLoading}
                                className="bg-G-500 hover:bg-G-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                iconBefore="ri-emotion-happy-fill"
                                view="outline"
                                onClick={() => handleToneChange('friendly')}
                            ></Button>
                        </Tooltip>

                        {/* Informative Tone Button */}
                        <Tooltip
                            id={uniqueId()}
                            place="top"
                            variant="dark"
                            tooltipType="detailed"
                            title="Set to Informative Tone"
                        >
                            <Button
                                isDisabled={isLoading}
                                className="bg-B-500 hover:bg-B-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                iconBefore="ri-information-fill"
                                view="outline"
                                onClick={() => handleToneChange('informative')}
                            ></Button>
                        </Tooltip>

                        {/* Reset Tone Button */}
                        <Tooltip
                            id={uniqueId()}
                            place="top"
                            variant="dark"
                            tooltipType="detailed"
                            title="Reset Email Tone"
                        >
                            <Button
                                isDisabled={isLoading}
                                className="bg-N-500 hover:bg-N-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                iconBefore="ri-refresh-line"
                                view="outline"
                                onClick={() => handleToneChange('normal')}
                            ></Button>
                        </Tooltip>
                    </div>
                </>
            }
            footer={
                <Button
                    onClick={handleSave}
                    isDisabled={isLoading}
                    size="medium"
                    view="ghost"
                    className="bg-B-500 p-2 hover:bg-B-400 text-white self-end"
                >
                    Save & Close
                </Button>
            }
            open={isOpen}
            onClose={onClose}
            height={550}
        />
    );
};

export default EmailEditModal;
