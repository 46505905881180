export const deConstantCase = (key: string): string => {
    const lowerCased = key.toLowerCase().replace(/_/g, ' ');
    return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
};

export const deUnderscore = (key: string): string => {
    return key.replace(/_/g, ' ');
};

export function toConstantCase(str: string) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1_$2') // Insert underscore before any uppercase letter that follows a lowercase letter
        .replace(/[\s\W]+/g, '_') // Replace spaces and non-word characters with underscores
        .toUpperCase(); // Convert the entire string to uppercase
}

export const extractVariables = (input: string): string[] => {
    const regex = /{(?!{)([^{}]+)}(?!})/g;
    const matches: string[] = [];
    let match;

    while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
    }

    return matches;
};

export const removeVariables = (input: any): { withoutVariables: any; variables: any[] } => {
    const regex = /{(?!{)([^{}]+)}(?!})/g;
    const variables: string[] = [];
    let cleanedInput = input;

    let match;
    while ((match = regex.exec(input)) !== null) {
        variables.push(match[1]);
    }
    cleanedInput = cleanedInput
        .replace(regex, '')
        .replace(/\*\*.*?\*\*/g, '')
        .trim();
    return { withoutVariables: cleanedInput, variables };
};
