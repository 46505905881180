import { useCallback } from 'react';
import { logger } from '../utils';
import { toast } from 'react-toastify';

const useHTMLTableToCsv = () => {
    const convertTableToCsv = useCallback((tableElement: HTMLTableElement | null, filename: string) => {
        try {
            if (!tableElement) {
                toast.error('Failed to download CSV. No table detected..');
                return;
            }

            let csvContent = '';
            for (const row of Array.from(tableElement.rows)) {
                const cells = Array.from(row.cells).map(cell => {
                    const link = cell.querySelector('a');
                    if (link) {
                        return link.getAttribute('href') || '';
                    }
                    return cell.textContent?.trim() || '';
                });
                csvContent += cells.join(',') + '\n';
            }

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename ?? 'file.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            logger.error(error);
            toast.error('Failed to download CSV. Please try again later.');
        }
    }, []);

    return { convertTableToCsv };
};

export { useHTMLTableToCsv };
