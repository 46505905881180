import { useRef, useEffect, DependencyList } from 'react';

export const useScroll = (deps?: DependencyList) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const element = scrollRef.current;
        if (!element) return;
        element.scrollTop = element.scrollHeight;
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, deps);

    return { scrollRef };
};
