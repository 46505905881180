import { Button } from '@kaya/kaya-ui-design-system-pb';
import { useChatContext } from '../../../contexts';
import { GENERATED_TEST_CASE_FILE } from '../../../constants';

const DownloadTestCases = ({
    messageId,
    text,
    enableTestCaseAnalysis,
}: {
    messageId: string;
    text: string;
    enableTestCaseAnalysis: boolean;
}) => {
    const { messages } = useChatContext();
    const streaming = Boolean(messages?.slice(-1)[0]?.processing && messages?.slice(-1)[0]?.id === messageId);

    if (!enableTestCaseAnalysis || streaming) return null;

    const downloadTestCases = () => {
        try {
            window.open(GENERATED_TEST_CASE_FILE, '_blank');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Button
            iconAfter="ri-download-2-line"
            className="flex justify-center cursor-pointer bg-transparent text-white hover:border-N-100 my-2 py-2 px-4 border border-N-400 rounded-md w-full text-left hoverEffect disabled:bg-N-500 disabled:cursor-not-allowed"
            onClick={downloadTestCases}
        >
            Download Test Cases
        </Button>
    );
};

export { DownloadTestCases };
