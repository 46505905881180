import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { CSS_INPUT, CSS_SCROLL } from '../../../constants';
import { CN } from '../../../utils';

const FeedbackModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const [value, setValue] = useState('');
    const ref = useClickOutside(() => onClose());
    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div
                className="fixed bottom-[3.5em] left-[1.5em] p-4 pt-2 bg-white border border-N-200 drop-shadow-sm rounded-md w-full max-w-md m-auto"
                ref={ref}
            >
                <p className="my-2 py-2">Provide your valuable feedback!</p>
                <div className="flex flex-col gap-1 w-full">
                    <textarea
                        className={CN(`${CSS_INPUT} ${CSS_SCROLL} w-full !h-[15em] bg-card-default `)}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            setValue(e.target.value);
                        }}
                    />
                    <div className="flex gap-2">
                        <button
                            className="bg-B-500 hover:bg-B-400 text-white py-2 px-4 rounded-md w-full"
                            onClick={() => onClose()}
                        >
                            Provide Feedback
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { FeedbackModal };
