import { useMutation } from '@apollo/client';
import { Toggle } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { DocumentPersistenceType, MessageAuthor } from '../../../__generated__/graphql';
import { DOCUMENT_PERSISTENCE_TYPES, SYSTEM_TEMP_ID } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { SAVE_DOCUMENTS } from '../../../graphql';
import { logger, uniqueId } from '../../../utils';
import { UploadButton, UploadCard, UploadInput } from '../upload-card';

type IDocumentsUpload = {
    onClickUpload?: Function;
    isFileLimitationValid?: boolean;
    warningMessage?: string;
    showDocumentToggles?: boolean;
    initialLabel?: string;
    postLabel?: string;
    acceptedFiles?: string;
};

const DocumentsUpload = ({
    onClickUpload,
    isFileLimitationValid = true,
    warningMessage,
    showDocumentToggles = true,
    initialLabel = 'Add to chat',
    postLabel = 'Documents added',
    acceptedFiles = '.txt,.docx,.pdf,.xlsx,.csv',
}: IDocumentsUpload) => {
    const {
        conversationId,
        userDetails,
        chatDocuments,
        documentPersistanceType,
        setChatDocuments,
        setDocumentPersistanceType,
        setLoading,
        setSettings,
        setMessages,
        featureType,
    } = useChatContext();
    const [addDocuments, { loading, data }] = useMutation(SAVE_DOCUMENTS);
    const documentsAdded = Boolean(data?.saveNewDocuments);
    const filesExist = chatDocuments.length > 0;

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
        const newFiles = selectedFiles.filter(
            file => !chatDocuments.some(existingFile => existingFile.name === file.name)
        );
        setChatDocuments([...chatDocuments, ...newFiles]);
        e.target.value = ''; // Clear the file input for subsequent selections
    };

    const handleChange = (documentPersistanceType: DocumentPersistenceType, checked: boolean) => {
        const switchTypes =
            documentPersistanceType === DocumentPersistenceType.Permanent
                ? DocumentPersistenceType.Temporary
                : DocumentPersistenceType.Permanent;

        const currentType = checked ? documentPersistanceType : switchTypes;

        setDocumentPersistanceType(currentType);
    };

    const handleAddToChat = async () => {
        try {
            setLoading(true);
            const { data } = await addDocuments({
                variables: {
                    input: {
                        files: chatDocuments,
                        conversationId: conversationId,
                        type: documentPersistanceType,
                        userId: userDetails.id,
                        feature: featureType,
                    },
                },
            });

            if (data?.saveNewDocuments) {
                setMessages(messages => [
                    ...messages,
                    {
                        id: `${SYSTEM_TEMP_ID}_${uniqueId()}`,
                        author: MessageAuthor.System,
                        content: String(data?.saveNewDocuments),
                    },
                ]);
            }

            if (documentPersistanceType === DocumentPersistenceType.Temporary) {
                setSettings(s => ({ ...s, hasTemporaryFiles: true }));
            }
        } catch (error) {
            logger.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = (index: number) => {
        const newFiles = chatDocuments.filter((_, i) => i !== index);
        setChatDocuments(newFiles);
    };

    return (
        <div className="max-w-[500px]">
            <div className="my-2 w-full">
                <UploadInput
                    label={filesExist ? 'Upload More Documents' : 'Upload Documents'}
                    handleFileChange={handleFileChange}
                    acceptedFiles={acceptedFiles}
                />
            </div>
            {filesExist && (
                <>
                    <details className="my-4" open>
                        <summary className="cursor-pointer pb-2 text-md text-N-600">Uploaded Documents</summary>
                        <div className="py-1 text-sm">
                            {chatDocuments.map((file, index) => (
                                <UploadCard
                                    key={index}
                                    file={file}
                                    handleRemove={() => handleRemoveFile(index)}
                                    hasRemove={documentPersistanceType === DocumentPersistenceType.Temporary}
                                />
                            ))}
                        </div>
                    </details>
                    {showDocumentToggles && (
                        <>
                            <hr />
                            <details className="my-4" open>
                                <summary className="cursor-pointer pb-2 text-md text-N-600 relative group">
                                    How to preserve the documents?{' '}
                                    <i className="ri-information-line cursor-pointer mx-1"></i>
                                    <div className="w-[260px] absolute left-1/2 transform -translate-x-1/2 hidden bottom-full mb-2 group-hover:block bg-N-200 text-black text-xs rounded text-wrap px-2 py-1 text-center z-50 sm:-translate-y-[-100px] sm:mb-8 sm:w-[200px]">
                                        <span className="text-A-600 ">⚠️ </span> Questions based solely on documents can
                                        only be asked using temporary option.
                                    </div>
                                </summary>

                                <div className="my-2 toggles-doc">
                                    {DOCUMENT_PERSISTENCE_TYPES.map(docType => {
                                        const { type, name } = docType;
                                        const isChecked = documentPersistanceType === type;

                                        return (
                                            <Toggle
                                                key={type}
                                                name="doc-type"
                                                value={type}
                                                isActive={isChecked}
                                                onChange={(checked: boolean) => handleChange(type, checked)}
                                                label={(<p className="text-N-600 text-sm">{name}</p>) as any}
                                            />
                                        );
                                    })}
                                </div>
                            </details>
                        </>
                    )}
                    <UploadButton
                        label={documentsAdded ? postLabel : initialLabel}
                        loading={loading}
                        disabled={documentsAdded || !documentPersistanceType || !isFileLimitationValid}
                        onClick={() => (onClickUpload ? onClickUpload() : handleAddToChat())}
                    />
                    <p className="mt-2 text-xs text-R-600">
                        <i>{warningMessage && warningMessage}</i>
                    </p>
                </>
            )}
        </div>
    );
};

export { DocumentsUpload };
