import { CustomKplSectionSelectionTypes, useSideBarContext } from '../../../../../contexts';
import classNames from 'classnames';
import { useMobile } from '../../../../../hooks';

type ISidebarOption = {
    selectionType: CustomKplSectionSelectionTypes;
    isDisabled?: boolean;
};

export const SidebarOption = ({ selectionType, isDisabled }: ISidebarOption) => {
    const { setCustomKplSelection, customKplSelection, handleMobileMenuIconClick } = useSideBarContext();
    const isMobile = useMobile();

    const handleMenuSelection = () => {
        setCustomKplSelection(selectionType);
        if (isMobile) handleMobileMenuIconClick();
    };

    return (
        <button
            type="button"
            className={classNames('pl-4 flex gap-x-1 items-center', {
                'hover:cursor-pointer group': !isDisabled,
                'opacity-[.6] cursor-not-allowed': isDisabled,
            })}
            onClick={() => handleMenuSelection()}
            disabled={isDisabled}
        >
            <i
                className={classNames('ri-corner-down-right-fill text-sm', {
                    'text-B-600': customKplSelection === selectionType,
                    'text-N-600': customKplSelection !== selectionType,
                    'group-hover:text-B-600': !isDisabled,
                })}
            />
            <p
                className={classNames('text-sm', {
                    'text-B-600 font-500 underline underline-offset-2': customKplSelection === selectionType,
                    'text-N-600': customKplSelection !== selectionType,
                    'group-hover:text-B-600': !isDisabled,
                })}
            >
                {selectionType}
            </p>
        </button>
    );
};
