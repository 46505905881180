import { useMutation } from '@apollo/client';
import { Button } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { DocumentPersistenceType } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { SAVE_CALL_REPORTS } from '../../../graphql';

interface IDocumentsUpload {
    onSuccess: Function;
}

const DocumentsUpload = ({ onSuccess }: IDocumentsUpload) => {
    const {
        conversationId,
        userDetails,
        chatDocuments,
        documentPersistanceType,
        setDocumentPersistanceType,
        setChatDocuments,
        setLoading,
        setSettings,
        featureType,
    } = useChatContext();
    const [addDocuments, { loading, data }] = useMutation(SAVE_CALL_REPORTS);
    const documentsAdded = Boolean(data?.saveNewCallReports);
    const filesExist = chatDocuments.length > 0;

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
        const newFiles = selectedFiles.filter(
            file => !chatDocuments.some(existingFile => existingFile.name === file.name)
        );
        setChatDocuments([...chatDocuments, ...newFiles]);
        setDocumentPersistanceType(DocumentPersistenceType.Temporary);
        e.target.value = ''; // Clear the file input for subsequent selections
    };

    const handleAddToChat = async () => {
        try {
            setLoading(true);
            const { data } = await addDocuments({
                variables: {
                    input: {
                        files: chatDocuments,
                        conversationId: conversationId,
                        type: documentPersistanceType,
                        userId: userDetails.id,
                        feature: featureType,
                    },
                },
            });

            if (data?.saveNewCallReports) {
                onSuccess(data?.saveNewCallReports);
            }

            if (documentPersistanceType === DocumentPersistenceType.Temporary) {
                setSettings(s => ({ ...s, hasTemporaryFiles: true }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = (index: number) => {
        const newFiles = chatDocuments.filter((_, i) => i !== index);
        setChatDocuments(newFiles);
    };

    return (
        <div className="max-w-[500px]">
            <div className="my-2 w-full">
                <label className="flex flex-col items-center text-white cursor-pointer p-2 border w-full hover:border-dashed rounded-md">
                    <span>
                        <svg
                            className="w-8 h-8"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                    </span>
                    <span className="text-md">{filesExist ? 'Upload More Call Reports' : 'Upload Call Reports'}</span>
                    <input
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                        multiple
                        accept=".txt,.docx,.pdf,.doc,.xlsx,.csv"
                    />
                </label>
            </div>
            {filesExist && (
                <>
                    <details className="my-4" open>
                        <summary className="cursor-pointer pb-2 text-md">Uploaded Call Reports</summary>
                        <div className="py-1 text-sm">
                            <ul>
                                {chatDocuments.map((file, index) => (
                                    <li key={index}>
                                        <i
                                            className="ri-delete-bin-6-line mx-1 cursor-pointer hover:font-bold"
                                            onClick={() => handleRemoveFile(index)}
                                        />
                                        {file.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </details>
                    <Button
                        isDisabled={documentsAdded || !documentPersistanceType}
                        isLoading={loading}
                        className="bg-N-700 hover:bg-N-800 text-white text-md outline-none border-none font-bold py-2 px-4 my-1 rounded-md w-full flex items-center disabled:bg-N-500 disabled:cursor-not-allowed"
                        onClick={handleAddToChat}
                        iconBefore={loading ? '' : 'ri-chat-check-line'}
                    >
                        {documentsAdded ? 'Call Reports Added' : 'Add Call Reports'}
                    </Button>
                </>
            )}
        </div>
    );
};

export { DocumentsUpload };
