import { Button } from '@kaya/kaya-ui-design-system-pb';
import { CommonMessage } from '../message';

interface ButtonWithMessageProps {
    displayCondition: boolean;
    disabledCondition?: boolean;
    isLoading?: boolean;
    onClick: () => void;
    text: string;
    disabledText: string;
    buttonText: string;
    icon: string;
    extraButton?: React.ReactNode;
}

const ButtonWithMessage = ({
    displayCondition,
    disabledCondition = false,
    isLoading = false,
    onClick,
    text,
    disabledText,
    buttonText,
    icon = 'ri-checkbox-circle-line',
    extraButton,
}: ButtonWithMessageProps) => {
    if (!displayCondition) return null;

    return (
        <CommonMessage
            isUser={false}
            disableCopy
            text=""
            content={
                <div>
                    <div className="m-1 pb-1">{text}</div>
                    <div className="flex gap-1 items-center">
                        <Button
                            isDisabled={disabledCondition}
                            isLoading={isLoading}
                            className="bg-B-500 hover:bg-B-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                            onClick={onClick}
                            iconBefore={isLoading ? '' : icon}
                        >
                            {disabledCondition ? disabledText : buttonText}
                        </Button>
                        {extraButton || <></>}
                    </div>
                </div>
            }
        />
    );
};

export { ButtonWithMessage };
