interface CustomWindow extends Window {
    config: {
        chatbotApiUrl: string;
        chatbotWebSocketUrl: string;
        keycloakRedirectUrl: string;
        keycloakUrl: string;
        keycloakRealm: string;
        keycloakClientId: string;
        kayaCdnUrl: string;
        faviconUrl: string;
        headerLogoUrl: string;
        logLevel: string;
        googleAnalyticsId: string;
        activationDays: number;
        tokenLimit: number;
        maxDocumentCount: number;
        maxDocumentSizeMB: number;
    };
}

const customWindow: CustomWindow = window as unknown as CustomWindow;
const { config } = customWindow;

export const KAYA_KEYCLOAK_REDIRECT_URL = config.keycloakRedirectUrl;
export const KAYA_CHATBOT_API_URL = config.chatbotApiUrl;
export const KAYA_CHATBOT_WEB_SOCKET_URL = config.chatbotWebSocketUrl;
export const KAYA_KEYCLOAK_CLIENT_ID = config.keycloakClientId;
export const KAYA_KEYCLOAK_REALM = config.keycloakRealm;
export const KAYA_KEYCLOAK_URL = config.keycloakUrl;
export const KAYA_CDN_URL = config.kayaCdnUrl;
export const HEADER_LOGO_URL = config.headerLogoUrl;
export const ENV_LOG_LEVEL = config.logLevel;
export const KAYA_CUSTOM_KPL_ACTIVATIONDATE = config.activationDays;
export const KAYA_CUSTOM_KPL_TOCKENLIMIT = config.tokenLimit;
export const KAYA_CUSTOM_KPL_MAX_DOCUMENT_COUNT = config.maxDocumentCount;
export const KAYA_CUSTOM_KPL_MAX_DOCUMENT_SIZE = config.maxDocumentSizeMB;
