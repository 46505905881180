import { useMutation } from '@apollo/client';
import { useClickOutside, useClipboard } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { IconButtonRound } from '..';
import { MessageAuthor } from '../../../__generated__/graphql';
import { SYSTEM_TEMP_ID } from '../../../constants';
import { useChatContentContext, useChatContext } from '../../../contexts';
import { ADD_ACTION_ON_DISLIKE, ADD_FACT_CHECK_MESSAGE } from '../../../graphql';
import { useMessageContent, useMessageFeedback, useTextToSpeech } from '../../../hooks';
import { getImprovedResponse, uniqueId } from '../../../utils';
import { IMessageActions } from '../../pages/messenger/types';
import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import GoogleLogo from '../../../assets/google_icon.png';

const DislikedMessageModal = ({ id, open, onClose }: { id: string; open: boolean; onClose: () => void }) => {
    const { userDetails, setMessages } = useChatContext();
    const [addActionOnDislike] = useMutation(ADD_ACTION_ON_DISLIKE);
    const improvedResponse = getImprovedResponse(userDetails.email) === 'improved';
    if (!open || !improvedResponse) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="fixed bottom-[3.5em] right-[1.5em] p-4 pt-2 bg-N-800 rounded-md w-full max-w-md m-auto">
                <div className="flex justify-between items-center">
                    <p className="text-2xl font-bold">We appreciate your valuable feedback.</p>
                    <button onClick={onClose}>
                        <i className="cursor-pointer text-lg text-white ri-close-line"></i>
                    </button>
                </div>
                <p className="my-2 py-2">
                    Do you want a better answer to your question? Choose one of the options below to proceed.
                </p>
                <div className="flex justify-center gap-3">
                    <button
                        className="bg-transparent hover:bg-B-700 text-white font-semibold py-2 px-4 border border-B-700 rounded"
                        onClick={() => {
                            setMessages(prevMessages => [
                                ...prevMessages,
                                {
                                    id: `${SYSTEM_TEMP_ID}_${uniqueId()}`,
                                    content: 'SME is informed about your query, they will get back to you soon.',
                                    author: MessageAuthor.System,
                                },
                            ]);
                            onClose();
                        }}
                    >
                        <span className="flex gap-1">
                            <i className="ri-user-settings-line"></i> Request Help from an SME
                        </span>
                    </button>
                    <button
                        className="bg-transparent hover:bg-B-700 text-white font-semibold py-2 px-4 border border-B-700 rounded"
                        onClick={async () => {
                            await addActionOnDislike({
                                variables: {
                                    input: {
                                        messageId: id,
                                        type: 'teams',
                                        user: userDetails.name,
                                    },
                                },
                            });
                            setMessages(prevMessages => [
                                ...prevMessages,
                                {
                                    id: `${SYSTEM_TEMP_ID}_${uniqueId()}`,
                                    content: 'Your query is posted in the Teams channel.',
                                    author: MessageAuthor.System,
                                },
                            ]);
                            onClose();
                        }}
                    >
                        <span className="flex gap-1">
                            <i className="ri-team-line"></i> Post the Query in Teams Channel
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

interface TextSpeechControlPanelProps {
    onClose: () => void;
    onPauseResume: () => void;
    onRestart: () => void;
    voiceControllerOpen: boolean;
    isPaused: boolean;
    timer: string;
}

const TextSpeechControlPanel: React.FC<TextSpeechControlPanelProps> = ({
    voiceControllerOpen,
    onClose,
    onPauseResume,
    onRestart,
    isPaused,
    timer,
}) => {
    const ref = useClickOutside(() => onClose());

    if (!voiceControllerOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div
                className="fixed bottom-[3em] left-[4em] sm:left-[1em] bg-N-200 p-2 my-2 rounded-md opacity-90 w-[200px] max-w-md flex flex-row justify-between"
                ref={ref}
            >
                <div className="flex justify-center items-center gap-1">
                    <button
                        className="hover:bg-N-300 rounded-full w-7"
                        title={isPaused ? 'Resume' : 'Paused'}
                        onClick={onPauseResume}
                    >
                        {isPaused ? (
                            <i className="cursor-pointer text-lg ri-play-circle-line"></i>
                        ) : (
                            <i className="cursor-pointer text-lg ri-pause-circle-line"></i>
                        )}
                    </button>
                    <span>{timer}</span>
                </div>
                <div className="flex justify-center">
                    <button className="hover:bg-N-300 rounded-full w-7" title="Start Over" onClick={onRestart}>
                        <i className="cursor-pointer text-lg ri-memories-line"></i>
                    </button>
                    <button className="hover:bg-N-300 rounded-full w-7" title="Close" onClick={onClose}>
                        <i className="cursor-pointer text-lg ri-close-circle-line"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

const MessageActions = ({ id = '', text = '', isUser = false, disableCopy, feedback }: IMessageActions) => {
    const { copy, copied } = useClipboard({ timeout: 2000 });
    const { submitUserFeedback, loading } = useMessageFeedback();
    const [liked, setLiked] = useState(feedback != null && feedback === true);
    const [disliked, setDisLiked] = useState(feedback != null && feedback === false);
    const [open, setOpen] = useState(false);
    const {
        voiceControllerOpen,
        isPaused,
        elapsedTime,
        isSpeaking,
        stopSpeaking,
        toggleSpeaking,
        onPauseResume,
        onRestart,
        formatTime,
    } = useTextToSpeech(text);
    const { messages } = useMessageContent();
    const { setIsGoogleFactCheckModalOpen, setGoogleFactCheckContent } = useChatContentContext();

    const handleLikeClick = () => {
        submitUserFeedback(id, true);
        setLiked(true);
        setDisLiked(false);
    };

    const handleDislikeClick = () => {
        submitUserFeedback(id, false);
        setLiked(false);
        setDisLiked(true);
        setOpen(true);
    };

    const handleCopyClick = () => {
        copy(text);
    };

    const handleGoogleFactCheck = async (previousMessageId: string, previousMessageContent: string) => {
        setGoogleFactCheckContent({ previousMessageId: previousMessageId, previousMessage: previousMessageContent });
        setIsGoogleFactCheckModalOpen(true);
    };

    useEffect(() => {
        if (feedback != null) {
            setLiked(feedback === true);
            setDisLiked(feedback === false);
        }
    }, [feedback]);

    if (isUser || disableCopy) {
        return <></>;
    }

    return (
        <div className="flex float-right border-t-N-500 pt-[6px]">
            <IconButtonRound
                title={isSpeaking ? 'Stop' : 'Read Aloud'}
                onClick={toggleSpeaking}
                icon={isSpeaking ? 'ri-stop-circle-line' : 'ri-volume-up-line'}
            />
            <IconButtonRound
                title="Like"
                onClick={handleLikeClick}
                icon={loading && liked ? 'ri-refresh-line' : liked ? 'ri-thumb-up-fill' : 'ri-thumb-up-line'}
            />
            <IconButtonRound
                title="Response needs improvement"
                onClick={handleDislikeClick}
                icon={loading && disliked ? 'ri-refresh-line' : disliked ? 'ri-lightbulb-fill' : 'ri-lightbulb-line'}
            />
            <IconButtonRound
                title="Copy"
                onClick={handleCopyClick}
                icon={copied ? 'ri-checkbox-circle-line' : 'ri-clipboard-line'}
            />
            <div
                className="flex items-center gap-x-2 justify-center border border-N-400 pr-[6px] rounded-md cursor-pointer hover:bg-N-300 ml-2"
                onClick={() => handleGoogleFactCheck(id, text)}
            >
                <img src={GoogleLogo} width={12} className="ml-[6px]" />
                <p className="text-N-500 font-400 text-[11px] ml-[-2px] leading-3">Fact Check</p>
            </div>
            <DislikedMessageModal id={id} open={open} onClose={() => setOpen(false)} />
            <TextSpeechControlPanel
                voiceControllerOpen={voiceControllerOpen}
                onClose={stopSpeaking}
                onPauseResume={onPauseResume}
                onRestart={onRestart}
                isPaused={isPaused}
                timer={formatTime(elapsedTime)}
            />
        </div>
    );
};

export { MessageActions };
