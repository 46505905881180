import React, { forwardRef } from 'react';
import { CN } from '../../../utils';
import { CSS_SCROLL } from '../../../constants';
import { useSideBarContext } from '../../../contexts';

interface ScrollableWrapperProps {
    children: React.ReactNode;
    className?: string;
}

const ScrollableWrapper = forwardRef<HTMLDivElement, ScrollableWrapperProps>(({ children }, ref) => {
    const { isCollapsed } = useSideBarContext();
    return (
        <div
            ref={ref}
            className={CN(
                'w-full mx-auto py-1 flex flex-col scroll-smooth h-full px-[15%] sm:px-[2%] xxl:!p-5',
                CSS_SCROLL
            )}
        >
            {children}
        </div>
    );
});

export { ScrollableWrapper };
