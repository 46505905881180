interface IPromptCardProps {
    onClick?: () => void;
    title: string;
    description: string;
    icon: string;
}

export const PromptCard = ({ onClick, title, description, icon }: IPromptCardProps) => {
    return (
        <div
            className="group bg-white rounded-md py-2 px-5 flex flex-col sm:flex-row gap-y-3 shadow-sm cursor-pointer w-[340px] hover:shadow-lg duration-300 animate-fade-up animate-once"
            onClick={() => onClick?.()}
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <i className={`${icon} text-h3 mx-2 text-B-600`} />
                    <p className="text-N-600 text-sm">{description}</p>
                </div>
                <i className="ri-arrow-right-line text-N-600 text-h6 group-hover:scale-125 duration-150" />
            </div>
        </div>
    );
};
