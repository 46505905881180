import { useClickOutside } from '@mantine/hooks';

const HelpModel = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const ref = useClickOutside(() => onClose());
    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div
                className="fixed bottom-[3.5em] left-[1.5em]  p-4 pt-2 bg-white border border-N-200 drop-shadow-sm rounded-md w-full max-w-xs m-auto"
                ref={ref}
            >
                <p className="my-2 py-2">We're here to help! Please let us know how we can assist you.</p>
                <button
                    className="bg-transparent hover:bg-B-400 font-semibold py-2 px-4 border border-B-700 rounded w-full"
                    onClick={() => {
                        window.open('mailto:support@kayatech.com', '_blank');
                        onClose();
                    }}
                >
                    <span className="flex gap-1 justify-center">
                        <i className="ri-user-settings-line"></i>
                        {''}
                        Request help from an expert
                    </span>
                </button>
            </div>
        </div>
    );
};

export { HelpModel };
