import { useQuery } from '@apollo/client';
import { Avatar, SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { useHover } from '@mantine/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LiveCustomerAgentTranscript } from '../../../__generated__/graphql';
import { IMG_KAYA_BOT_PNG } from '../../../constants';
import { useSideBarContext } from '../../../contexts';
import { GET_LIVE_CUSTOMER_AGENT } from '../../../graphql';
import { useMessageContent, useMobile } from '../../../hooks';
import { CN } from '../../../utils';
import { FormattedChatMessage, MessageActions, ScrollableWrapper } from '../../common';
import { IChatMessage } from '../messenger/types';

enum MessageDisplayType {
    CUSTOMER_AGENT = 'CUSTOMER_AGENT',
    AI_AGENT = 'AI_AGENT',
    CUSTOMER = 'CUSTOMER',
    SYSTEM = 'SYSTEM',
    NOTIFICATION = 'NOTIFICATION',
    LOADING_AGENT = 'LOADING_AGENT',
    LOADING_CUSTOMER = 'LOADING_CUSTOMER',
}

const SANTANDER_INPUT_MESSAGE = `🎙️ Simulation of a conversation between a customer and an agent. 
    \n\n 📊 The system will analyze the conversation and provide insights.
    \n\n 🧑‍💼  Agent will respond to the customer query with the insights.`;

const SANTANDER_PREFIX_MESSAGES = {
    [MessageDisplayType.CUSTOMER]: '**[Bernie Live Transcription 🎙️]**',
    [MessageDisplayType.SYSTEM]: '### [Live Bernie Suggestion 📊]',
    [MessageDisplayType.CUSTOMER_AGENT]: '**[Agent Live Transcription🧑‍💼]**',
    [MessageDisplayType.AI_AGENT]: '',
    [MessageDisplayType.NOTIFICATION]: '',
    [MessageDisplayType.LOADING_AGENT]: '',
    [MessageDisplayType.LOADING_CUSTOMER]: '',
};

type ICustomCommonMessage = IChatMessage & {
    content: JSX.Element;
    subContent?: JSX.Element;
    messageDisplayType: MessageDisplayType;
    loading?: boolean;
};

type IMessageSub = {
    messageDisplayType: MessageDisplayType;
};

const MessageAvatar = ({ messageDisplayType }: IMessageSub) => {
    return (
        <>
            {(messageDisplayType === MessageDisplayType.CUSTOMER_AGENT ||
                messageDisplayType === MessageDisplayType.LOADING_AGENT) && (
                <Avatar
                    className="rounded-full order-1"
                    shape="circle"
                    size="md"
                    type="image"
                    imageURL={'https://img.icons8.com/ios-filled/50/user-female-circle.png'}
                />
            )}
            {(messageDisplayType === MessageDisplayType.AI_AGENT ||
                messageDisplayType === MessageDisplayType.SYSTEM) && (
                <Avatar
                    className="rounded-full order-1"
                    shape="circle"
                    size="md"
                    type="image"
                    imageURL={IMG_KAYA_BOT_PNG}
                />
            )}
            {(messageDisplayType === MessageDisplayType.CUSTOMER ||
                messageDisplayType === MessageDisplayType.LOADING_CUSTOMER) && (
                <Avatar
                    className="rounded-full"
                    shape="circle"
                    size="md"
                    type="image"
                    imageURL={'https://img.icons8.com/color/48/user.png'}
                />
            )}
        </>
    );
};

const CustomCommonMessage = ({
    id = '',
    text = '',
    content = <></>,
    isUser = false,
    disableCopy = false,
    feedback,
    messageDisplayType = MessageDisplayType.CUSTOMER,
}: ICustomCommonMessage) => {
    const { hovered, ref } = useHover();

    if (messageDisplayType === MessageDisplayType.NOTIFICATION) {
        return (
            <div className="flex items-center justify-center my-2" key={id}>
                <div className="flex gap-2 text-center text-N-500">
                    <span className="font-semibold ml-1">{text}</span>
                </div>
            </div>
        );
    }

    if (
        messageDisplayType === MessageDisplayType.LOADING_AGENT ||
        messageDisplayType === MessageDisplayType.LOADING_CUSTOMER
    ) {
        return (
            <div className="p-1">
                <div className={CN('flex items-center gap-2', { 'justify-end': isUser })}>
                    <div className="flex text-xs mx-2 order-1 items-end h-full justify-start">
                        <div className="mx-2" data-title="dot-flashing">
                            <div className="dot-flashing"></div>
                        </div>
                    </div>
                    <MessageAvatar messageDisplayType={messageDisplayType} />
                </div>
            </div>
        );
    }

    return (
        <div className="p-1">
            <div className={CN('flex items-end', { 'justify-end': isUser })}>
                <div className="flex flex-col space-y-2 text-xs mx-2 order-1 items-end max-w-[80%] animate-fade-down animate-once">
                    <div
                        ref={ref}
                        className={CN('relative w-full text-sm px-4 py-2 rounded-md inline-block', {
                            'rounded-br-sm bg-N-100 border border-N-200 text-N-900':
                                messageDisplayType === MessageDisplayType.CUSTOMER_AGENT ||
                                messageDisplayType === MessageDisplayType.AI_AGENT,
                            'bg-N-50 border border-N-200 rounded-bl-sm':
                                messageDisplayType === MessageDisplayType.CUSTOMER,
                            '!text-white bg-B-100 rounded-br-md border-[1px] border-B-700 animate-fade-left animate-once animate-ease-in':
                                messageDisplayType === MessageDisplayType.SYSTEM,
                        })}
                    >
                        {content}
                        <MessageActions
                            id={id}
                            text={text}
                            content={content}
                            isUser={isUser}
                            isHovered={hovered}
                            disableCopy={disableCopy}
                            feedback={feedback}
                        />
                    </div>
                </div>
                <MessageAvatar messageDisplayType={messageDisplayType} />
            </div>
        </div>
    );
};

const StopAudio = ({ playing, pausePlay }: { playing: boolean; pausePlay: () => void }) => {
    const isMobile = useMobile();
    const { isCollapsed } = useSideBarContext();

    return (
        <button
            onClick={pausePlay}
            className={CN('absolute transform -translate-x-1/2 cursor-pointer bottom-[4em] left-1/2 hover:font-bold', {
                '!left-[65%]': !isMobile && !isCollapsed,
            })}
        >
            <i
                className={CN('bg-white border border-N-200 rounded-full shadow-lg text-[18px] p-[4px]', {
                    'ri-pause-fill': playing,
                    'ri-play-fill': !playing,
                })}
            ></i>
        </button>
    );
};

interface LiveCustomerAgentComponentProps {
    liveAgentMessages: LiveCustomerAgentTranscript[];
    audioFile: string;
}

const LiveCustomerAgentComponent = ({ liveAgentMessages, audioFile }: LiveCustomerAgentComponentProps) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const { containerRef, scrollToBottom } = useMessageContent();

    const messageTimings = liveAgentMessages.map(({ time }) => time);
    const slicedMessages = liveAgentMessages.slice(0, currentMessageIndex);

    const loadingType = useMemo(() => {
        try {
            if (!audioPlaying) return null;
            if (currentMessageIndex === 0) return MessageDisplayType.LOADING_AGENT;

            const currentMessageType = slicedMessages[currentMessageIndex - 1].type;

            return currentMessageType === MessageDisplayType.CUSTOMER
                ? MessageDisplayType.LOADING_AGENT
                : currentMessageType === MessageDisplayType.CUSTOMER_AGENT
                  ? MessageDisplayType.LOADING_CUSTOMER
                  : currentMessageType === MessageDisplayType.SYSTEM
                    ? MessageDisplayType.LOADING_AGENT
                    : null;
        } catch (error) {
            return null;
        }
    }, [currentMessageIndex, slicedMessages, audioPlaying]);

    const handleTimeUpdate = () => {
        const audio = audioRef.current;
        const currentTime = audio?.currentTime || 0;
        const nextMessageIndex = messageTimings.findIndex(time => time > currentTime);
        const isPlaying = audio?.paused === false && audio?.readyState > 2;

        setAudioPlaying(isPlaying);

        if (nextMessageIndex !== -1 && nextMessageIndex !== currentMessageIndex) {
            setCurrentMessageIndex(nextMessageIndex - 1);
        } else if (nextMessageIndex === -1 && currentMessageIndex !== messageTimings.length - 1) {
            setCurrentMessageIndex(messageTimings.length - 1);
        }
    };

    useEffect(() => {
        const audio = audioRef.current;
        audio?.addEventListener('timeupdate', handleTimeUpdate);
        return () => audio?.removeEventListener('timeupdate', handleTimeUpdate);
    }, []);

    useEffect(() => {
        if (audioPlaying) {
            scrollToBottom();
        }
    }, [currentMessageIndex, audioPlaying, scrollToBottom]);

    return (
        <ScrollableWrapper ref={containerRef}>
            <div className="flex flex-col gap-y-6 mt-4">
                {audioFile && (
                    <CustomCommonMessage
                        isUser={true}
                        messageDisplayType={MessageDisplayType.CUSTOMER_AGENT}
                        disableCopy
                        text={'Add Transcription here'}
                        content={
                            <div className="my-2 max-w-full">
                                <label className="flex flex-col items-center text-N-600 cursor-pointer py-2 mt-2 border-b w-full border-dashed">
                                    <audio ref={audioRef} controls className="border-none outline-none w-full my-2">
                                        <source src={audioFile} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                    </audio>
                                    <span className="font-bold">
                                        Simulate Conversation by Playing the above audio 🎧
                                    </span>
                                </label>
                                <div className="mt-2">
                                    <FormattedChatMessage text={SANTANDER_INPUT_MESSAGE} />
                                </div>
                            </div>
                        }
                    />
                )}
                {slicedMessages.map(({ message, type }, index) => (
                    <CustomCommonMessage
                        isUser={
                            type === MessageDisplayType.CUSTOMER_AGENT ||
                            type === MessageDisplayType.SYSTEM ||
                            type === MessageDisplayType.AI_AGENT ||
                            type === MessageDisplayType.LOADING_AGENT
                        }
                        key={index}
                        messageDisplayType={type as MessageDisplayType}
                        disableCopy
                        text={message}
                        content={
                            <FormattedChatMessage
                                text={`${SANTANDER_PREFIX_MESSAGES[type as MessageDisplayType]} \n\n ${message}`}
                            />
                        }
                    />
                ))}
                {loadingType && (
                    <CustomCommonMessage
                        isUser={loadingType === MessageDisplayType.LOADING_AGENT}
                        messageDisplayType={loadingType}
                        disableCopy
                        text={'Loading'}
                        content={<>Loading</>}
                    />
                )}
                <StopAudio
                    playing={audioPlaying}
                    pausePlay={() => {
                        const audio = audioRef.current;
                        if (audio) {
                            if (audio.paused) {
                                audio.play();
                            } else {
                                audio.pause();
                            }
                        }
                    }}
                />
            </div>
        </ScrollableWrapper>
    );
};

const LiveCustomerAgent = () => {
    const { data, loading } = useQuery(GET_LIVE_CUSTOMER_AGENT);

    const liveAgentMessages = data?.getLiveCustomerAgentDetails?.transcript || [];
    const audioFile = data?.getLiveCustomerAgentDetails?.audioFileUrl || '';

    if (loading || !audioFile || liveAgentMessages.length <= 0) {
        return (
            <div className="flex justify-center">
                <SpinLoader />
            </div>
        );
    }

    return <LiveCustomerAgentComponent liveAgentMessages={liveAgentMessages} audioFile={audioFile} />;
};

export { LiveCustomerAgent };
