import { useEffect, useState } from 'react';
import { FeatureType } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { PageLoading } from '../../common/full-page-loader';
import {
    GenerateTechnicalSpecContent,
    LiveCustomerAgent,
    MessageMaestroKPL,
    SprintAnalysisReport,
    TimesheetContent,
    TranscriptIQKPL,
    WealthManagementTranscriptAnalysis,
} from '../../pages';
import { CodeAnalysis } from '../code-analysis';
import { LeadSyncKPL } from '../lead-sync-kpl/LeadSyncKPL';

const CustomKPLComponent = () => {
    const { featureType, conversationId, currentAppConfig } = useChatContext();
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if (conversationId) {
            setShowLoading(true);

            setTimeout(() => {
                setShowLoading(false);
            }, 100);
        }
    }, [conversationId]);

    if (showLoading) {
        return <PageLoading />;
    }

    switch (featureType) {
        case FeatureType.GenerateTechnicalSpec:
            return <GenerateTechnicalSpecContent />;
        case FeatureType.SprintAnalysisReport:
            return <SprintAnalysisReport />;
        case FeatureType.ClientKbSantander:
            return <LiveCustomerAgent />;
        case FeatureType.Timesheet:
            return <TimesheetContent />;
        case FeatureType.WealthManagementTranscriptAnalysis:
            return <WealthManagementTranscriptAnalysis />;
        case FeatureType.TranscriptIqKpl:
            return <TranscriptIQKPL />;
        case FeatureType.MessageMaestroKpl:
            return <MessageMaestroKPL />;
        case FeatureType.LeadSyncKpl:
            return <LeadSyncKPL />;
        case FeatureType.CodeAnalysis:
            return <CodeAnalysis metadata={currentAppConfig?.metadata} />;
        default:
            <></>;
    }
};

export { CustomKPLComponent };
