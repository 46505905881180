import { useGenerateTechnicalSpec } from '../../../hooks';
import {
    ChatMessage,
    CommonMessage,
    FormattedChatMessage,
    ScrollableWrapper,
    Step,
    StepContainer,
    StepperTypes,
    TranscriptionUpload,
    useLoadingMessages,
} from '../../common';

const MESSAGES = {
    business: {
        initial: 'Generate Requirements',
        loading: [
            'Analyzing transcript 📋',
            'Extracting business requirements 📝',
            'Analyzing business requirements 📋',
            'Generating business requirements 💡',
        ],
        success: 'Requirements generated successfully!',
    },
    businessJira: {
        initial: 'Save requirements in JIRA',
        loading: [
            'Establishing connection with JIRA 🔗',
            'Initiating process to save business requirements in JIRA 📝',
            'Saving business requirements in JIRA 📂',
            'Finalizing the saving process 💾',
        ],
        success: 'Requirements saved successfully!',
    },
    technical: {
        initial: 'Analyze Technical Requirements',
        loading: [
            'Analyzing technical requirements 📋',
            'Reviewing current code bases 🔍',
            'Reviewing Swagger documentation 📑',
            'Conducting security analysis 🔐',
        ],
        success: 'Technical requirements generated successfully!',
    },
    technicalJira: {
        initial: 'Save technical requirements in JIRA',
        loading: [
            'Establishing connection with JIRA 🔗',
            'Initiating process to save technical requirements in JIRA 📝',
            'Saving technical requirements in JIRA 📂',
            'Finalizing the saving process 💾',
        ],
        success: 'Technical requirements saved successfully!',
    },
    impactAnalysis: {
        initial: 'Starting Impact Analysis',
        loading: [
            'Analyzing existing codebases 🧩',
            'Reviewing API documentation 📄',
            'Conducting security checks 🔒',
            'Wrapping up change analysis ✨',
        ],
        success: 'Change analysis completed successfully!',
    },
    generateCode: {
        initial: 'Generate Code',
        loading: [
            'Analyzing technical requirements 📋',
            'Analyzing technical architecture 🔗',
            'Creating code structure 📝',
            'Generating unit tests 🔡',
            'Generating code 👩‍💻',
            'Creating zip file 🗂️',
        ],
        success: 'Code generated successfully!',
    },
};

const GenerateTechnicalSpecContent = () => {
    const {
        userFirstName,
        fileUploaded,
        scrollRef,
        requirementLoading,
        businessRequirement,
        jiraBusinessRequirement,
        technicalRequirement,
        jiraTechnicalRequirement,
        // codeGenerationExecution,
        codeGenerated,
        // questions,
        showJiraButtons,
        setFileUploaded,
        handleBusinessRequirements,
        handleTechnicalRequirements,
        handleGenerateBusinessJiraTickets,
        handleGenerateTechnicalJiraTickets,
        // handleGenerateCode,
        handleDownloadCode,
        handleProceedToCodeGeneration,
        // handleQuestionAnswerChange,
        feedbackSaved,
        setFeedbackSaved,
        impactAnalysisCompleted,
        handleImpactAnalysis,
    } = useGenerateTechnicalSpec();

    const steps: Step[] = [
        {
            id: 'transcription-upload',
            response: <TranscriptionUpload onFileUpload={setFileUploaded} />,
            condition: Boolean(fileUploaded),
            disabledCondition: Boolean(businessRequirement),
            handleClick: handleBusinessRequirements,
            isLoading: requirementLoading.business,
            text: '👇 Please click on the button below to generate requirements',
            disabledText: MESSAGES.business.success,
            buttonText: MESSAGES.business.initial,
            showButton: true,
            showEdit: true,
            showExtraButton: false,
            icon: 'ri-sparkling-line',
            type: StepperTypes.USER_INPUT,
        },
        {
            id: 'business-requirements',
            response: businessRequirement,
            condition: showJiraButtons.business,
            disabledCondition: Boolean(jiraBusinessRequirement),
            handleClick: handleGenerateBusinessJiraTickets,
            isLoading: requirementLoading.businessJira,
            text: '👇 Please click on the below button to save requirements in JIRA',
            disabledText: MESSAGES.businessJira.success,
            buttonText: MESSAGES.businessJira.initial,
            showButton: true,
            showEdit: true,
            showExtraButton: true,
            icon: 'ri-save-line',
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: 'impact-analysis',
            response: jiraBusinessRequirement,
            condition: Boolean(jiraBusinessRequirement),
            disabledCondition: Boolean(impactAnalysisCompleted),
            handleClick: handleImpactAnalysis,
            isLoading: requirementLoading.impactAnalysis,
            text: '👇 Please click the button below to conduct an change analysis',
            disabledText: 'Change Analysis Executed',
            buttonText: 'Execute Change Analysis',
            showButton: true,
            showEdit: false,
            showExtraButton: true,
            icon: 'ri-arrow-right-double-line',
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: 'technical-requirements',
            response: impactAnalysisCompleted,
            condition: Boolean(impactAnalysisCompleted),
            disabledCondition: Boolean(technicalRequirement),
            handleClick: handleTechnicalRequirements,
            isLoading: requirementLoading.technical,
            text: '👇 Please click on the button below to analyze technical requirements',
            disabledText: MESSAGES.technical.success,
            buttonText: MESSAGES.technical.initial,
            showButton: true,
            showEdit: false,
            showExtraButton: false,
            icon: 'ri-sparkling-line',
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: 'technical-jira-requirements',
            response: technicalRequirement,
            condition: showJiraButtons.technical,
            disabledCondition: Boolean(jiraTechnicalRequirement),
            handleClick: handleGenerateTechnicalJiraTickets,
            isLoading: requirementLoading.technicalJira,
            text: '👇 Please click on the below button to save subtasks in JIRA',
            disabledText: MESSAGES.technicalJira.success,
            buttonText: MESSAGES.technicalJira.initial,
            showButton: true,
            showEdit: true,
            showExtraButton: true,
            icon: 'ri-save-line',
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: 'code-generation',
            response: jiraTechnicalRequirement,
            condition: Boolean(jiraTechnicalRequirement),
            disabledCondition: Boolean(codeGenerated),
            handleClick: handleProceedToCodeGeneration,
            isLoading: requirementLoading.generateCode,
            text: '👇 Please click the button below to proceed to the code generation',
            disabledText: 'Generate Code And Test Packages',
            buttonText: 'Generate Code And Test Packages',
            showButton: true,
            showEdit: false,
            showExtraButton: false,
            icon: 'ri-arrow-right-double-line',
            type: StepperTypes.BOT_RESPONSE,
        },
        // {
        //     id: 'code-generation-questions',
        //     response: (
        //         <CodeGenerationQuestions
        //             questions={questions}
        //             handleQuestionAnswerChange={(index: number, answer: string | File) => {
        //                 handleQuestionAnswerChange(index, answer);
        //             }}
        //         />
        //     ),
        //     condition: codeGenerationExecution,
        //     disabledCondition: Boolean(codeGenerated),
        //     handleClick: handleGenerateCode,
        //     isLoading: requirementLoading.generateCode,
        //     text: '👇 Please click on the below button to Generate the code',
        //     disabledText: MESSAGES.generateCode.success,
        //     buttonText: MESSAGES.generateCode.initial,
        //     showButton: questions.every(question => question.answer),
        //     showEdit: false,
        //     showExtraButton: false,
        //     icon: 'ri-code-s-slash-line',
        //     type: StepperTypes.BOT_CUSTOM_RESPONSE,
        // },
        {
            id: 'code-download',
            response: codeGenerated,
            condition: Boolean(codeGenerated),
            disabledCondition: false,
            handleClick: handleDownloadCode,
            isLoading: requirementLoading.downloadCode,
            text: '👇 Please click on the below button to Download the code',
            disabledText: 'Download Code',
            buttonText: 'Download Code',
            showButton: true,
            showEdit: false,
            showExtraButton: false,
            icon: 'ri-download-2-line',
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: 'provide-feedback',
            response: '',
            condition: Boolean(codeGenerated),
            disabledCondition: false,
            handleClick: () => setFeedbackSaved('✅ Your feedback has been saved successfully!'),
            isLoading: false,
            text: '👇 Please click on the below button to Provide Feedback',
            disabledText: 'Provide Feedback',
            buttonText: 'Provide Feedback',
            showButton: true,
            showEdit: false,
            showExtraButton: false,
            icon: 'ri-feedback-line',
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: 'feedback-saved',
            response: feedbackSaved,
            condition: Boolean(feedbackSaved),
            disabledCondition: false,
            handleClick: () => {},
            isLoading: false,
            text: '',
            disabledText: '',
            buttonText: '',
            showButton: false,
            showEdit: false,
            showExtraButton: false,
            icon: 'ri-check-line',
            type: StepperTypes.BOT_RESPONSE,
        },
    ];

    const { currentMessage, showLoadingText, progress } = useLoadingMessages(requirementLoading, MESSAGES);

    return (
        <ScrollableWrapper>
            <ChatMessage
                disableCopy
                text={`Hi, ${userFirstName} 👋
                \n Bernie enables you to automatically generate a list of requirements and technical specifications from your meeting transcripts.
                \n- **Upload Your Transcript:** Start by uploading the transcript of your requirement discussion.
                \n- **Analyze:** Bernie will then analyze the transcript, identifying and drafting a list of requirements and technical specifications.
                \n- **Save in Jira:** Once you're satisfied with the generated requirements, you can directly save them to your Jira project for easy tracking and further action.
                \n- **Generate Code:** After answering a few questions, Bernie will generate the code for you.
                \n- **Download Code:** Once the code is generated, you can download it as a zip file.
                \n _Bernie is here to make project planning and documentation seamless and efficient. Let's get started!_`}
            />
            {steps.map((step, index) => (
                <StepContainer step={step} index={index} />
            ))}
            {showLoadingText && (
                <CommonMessage
                    disableCopy
                    text={currentMessage}
                    content={
                        <>
                            <FormattedChatMessage text={currentMessage} />
                            <div className="bg-N-400 rounded-full h-2.5 animate-fade my-1">
                                <div
                                    style={{ width: `${progress}%` }}
                                    className={'bg-B-600 h-2.5 rounded-full animate-pulse'}
                                />
                            </div>
                        </>
                    }
                />
            )}
            <span ref={scrollRef}></span>
        </ScrollableWrapper>
    );
};

export { GenerateTechnicalSpecContent };
