import { Code, MemoizedMarkdown } from '.';
import { Source } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { extractContentAndCodeBlocks, ExtractedContent } from '../../../utils';
import { References } from './References';

type IFormattedChatMessage = {
    text: string;
    sources?: Source[];
};

const FormattedChatMessage = ({ text, sources }: IFormattedChatMessage) => {
    const { featureType } = useChatContext();
    const result = extractContentAndCodeBlocks(text, featureType);

    return (
        <>
            {result.map(({ text, language, isCode }: ExtractedContent, index: number) => (
                <div key={index}>
                    {isCode ? <Code language={language}>{text}</Code> : <MemoizedMarkdown markdownText={text} />}
                </div>
            ))}
            {sources && sources.length > 0 && <References sources={sources} />}
        </>
    );
};

export { FormattedChatMessage };
