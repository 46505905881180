import { useState } from 'react';
import { ButtonWithMessage, CommonMessage, FormattedChatMessage, JiraSettingsModal } from '..';
import {
    FLEXIBILITY_ISSUES,
    FLEXIBILITY_ISSUES_CONCLUTION,
    FLEXIBILITY_ISSUES_WITH_MONOLITH,
    IMPACT_ANALYSIS,
    IMPACT_CONCLUSION,
    IMPACT_STRATEGIES,
    MAINTAINABILITY_ISSUES,
    MAINTAINABILITY_ISSUES_WITH_MONOLITH,
    SCALABILITY_ISSUES_WITH_MONOLITH,
    SUGGESTED_IMPROVEMENTS_FOR_FLEXIBILITY_ISSUES,
    SUGGESTED_IMPROVEMENTS_FOR_MAINTAINABILITY_ISSUES,
    SUGGESTED_IMPROVEMENTS_FOR_SCALABILITY_ISSUES,
} from '../../../constants';
import { useChatContext } from '../../../contexts';
import { BitBucketSettingsModal } from './BitBucketSettingsModal';
import { FeedbackModal } from './FeedbackModal';
import { HelpModel } from './HelpModel';
import { NextStepModel } from './NextStepModel';
import { Step, StepContainerProps, StepperTypes, getParsed, getParsedDetailed, getParsedMeta } from './types';
import { Accordion } from '@kaya/kaya-ui-design-system-pb';

const StepContainer = ({ step, index }: StepContainerProps) => {
    const [openJira, setOpenJira] = useState(false);
    const [openNextStep, setOpenNextStep] = useState(false);
    const [openHelp, setOpenHelp] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const { showJiraButtons } = useChatContext();

    const disabled =
        step.id === 'business-requirements'
            ? showJiraButtons.business
            : step.id === 'technical-jira-requirements'
              ? showJiraButtons.technical
              : false;

    const renderResponse = (step: Step) => {
        if (!step.response) return <></>;

        return (
            <>
                {step.type === StepperTypes.BOT_RESPONSE ? (
                    <CommonMessage
                        text={step.response}
                        disableCopy
                        content={
                            <>
                                <FormattedChatMessage text={getParsed(step.response)} />
                                {['business-requirements', 'technical-jira-requirements'].includes(step.id) && (
                                    <div className="reference w-full flex flex-col border border-N-200">
                                        <Accordion
                                            items={[
                                                {
                                                    title:
                                                        step.id === 'technical-jira-requirements'
                                                            ? 'View detailed technical requirements'
                                                            : 'View detailed requirement',
                                                    content: (
                                                        <div className="px-6 py-5">
                                                            <FormattedChatMessage
                                                                text={getParsedDetailed(step.response)}
                                                            />
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                        {(step.id === 'business-requirements' ||
                                            step.id === 'technical-jira-requirements') && (
                                            <Accordion
                                                items={[
                                                    {
                                                        title:
                                                            step.id === 'technical-jira-requirements'
                                                                ? 'View AI suggestions'
                                                                : 'View AI extracted metadata',
                                                        content: (
                                                            <div className="px-6 py-5">
                                                                <FormattedChatMessage
                                                                    text={getParsedMeta(step.response)}
                                                                />
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </div>
                                )}

                                {step.id == 'technical-requirements' && (
                                    <div className="">
                                        <div className="reference w-full flex flex-col border border-N-200">
                                            <Accordion
                                                items={[
                                                    {
                                                        title: 'View Detailed Impact on The Current System',
                                                        content: (
                                                            <div className="px-6 py-5">
                                                                <div className="my-2">
                                                                    <FormattedChatMessage text={IMPACT_ANALYSIS} />
                                                                    <Accordion
                                                                        items={[
                                                                            {
                                                                                title: 'Scalability Issues',
                                                                                content: (
                                                                                    <div className="my-2">
                                                                                        <FormattedChatMessage
                                                                                            text={
                                                                                                SCALABILITY_ISSUES_WITH_MONOLITH
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ),
                                                                            },
                                                                            {
                                                                                title: 'Suggested Improvements',
                                                                                content: (
                                                                                    <div className="my-2">
                                                                                        <FormattedChatMessage
                                                                                            text={
                                                                                                SUGGESTED_IMPROVEMENTS_FOR_SCALABILITY_ISSUES
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                    />
                                                                    <FormattedChatMessage
                                                                        text={MAINTAINABILITY_ISSUES}
                                                                    />
                                                                    <Accordion
                                                                        items={[
                                                                            {
                                                                                title: 'Maintainability Issues',
                                                                                content: (
                                                                                    <div className="my-2">
                                                                                        <FormattedChatMessage
                                                                                            text={
                                                                                                MAINTAINABILITY_ISSUES_WITH_MONOLITH
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ),
                                                                            },
                                                                            {
                                                                                title: 'Suggested Improvements',
                                                                                content: (
                                                                                    <div className="my-2">
                                                                                        <FormattedChatMessage
                                                                                            text={
                                                                                                SUGGESTED_IMPROVEMENTS_FOR_MAINTAINABILITY_ISSUES
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                    />
                                                                    <FormattedChatMessage text={FLEXIBILITY_ISSUES} />
                                                                    <Accordion
                                                                        items={[
                                                                            {
                                                                                title: 'Flexibility Issues',
                                                                                content: (
                                                                                    <div className="my-2">
                                                                                        <FormattedChatMessage
                                                                                            text={
                                                                                                FLEXIBILITY_ISSUES_WITH_MONOLITH
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ),
                                                                            },
                                                                            {
                                                                                title: 'Suggested Improvements',
                                                                                content: (
                                                                                    <div className="my-2">
                                                                                        <FormattedChatMessage
                                                                                            text={
                                                                                                SUGGESTED_IMPROVEMENTS_FOR_FLEXIBILITY_ISSUES
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                    />
                                                                    <FormattedChatMessage
                                                                        text={FLEXIBILITY_ISSUES_CONCLUTION}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ),
                                                    },
                                                    {
                                                        title: 'View Implementation Strategies',
                                                        content: (
                                                            <div className="px-6 py-5">
                                                                <FormattedChatMessage text={IMPACT_STRATEGIES} />
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <FormattedChatMessage text={IMPACT_CONCLUSION} />
                                        </div>
                                    </div>
                                )}

                                {step.showEdit && (
                                    <div className="flex items-center">
                                        <button
                                            disabled={disabled}
                                            onClick={() => setOpenNextStep(true)}
                                            className="bg-B-500 hover:bg-B-400 py-2 px-4 text-white border-none float-right rounded-md disabled:bg-N-600 disabled:pointer-events-none"
                                        >
                                            Proceed <i className="ri-arrow-right-double-fill"></i>
                                        </button>
                                        <button
                                            onClick={() => setOpenHelp(true)}
                                            className="px-2 text-N-900 border-none float-left rounded-md disabled:bg-N-600 disabled:pointer-events-none"
                                        >
                                            <i className="text-lg ri-question-line"></i>
                                        </button>
                                    </div>
                                )}

                                <HelpModel open={openHelp} onClose={() => setOpenHelp(false)} />
                                <NextStepModel open={openNextStep} onClose={() => setOpenNextStep(false)} step={step} />
                            </>
                        }
                    />
                ) : step.type === StepperTypes.USER_INPUT ? (
                    <CommonMessage isUser disableCopy text="" content={step.response} />
                ) : step.condition && step.type === StepperTypes.BOT_CUSTOM_RESPONSE ? (
                    <>{step.response}</>
                ) : (
                    <></>
                )}
            </>
        );
    };

    return (
        <div key={index}>
            {renderResponse(step)}
            {step.showButton && (
                <ButtonWithMessage
                    displayCondition={step.condition}
                    disabledCondition={step.disabledCondition}
                    onClick={() => {
                        if (step.id === 'provide-feedback') {
                            setFeedbackOpen(true);
                        } else {
                            step.handleClick();
                        }
                    }}
                    isLoading={step.isLoading}
                    text={step.text}
                    disabledText={step.disabledText}
                    buttonText={step.buttonText}
                    icon={step.icon}
                    extraButton={
                        step.showExtraButton ? (
                            <>
                                <button
                                    disabled={step.disabledCondition}
                                    title="Edit"
                                    className="bg-B-500 hover:bg-B-400 text-white py-2 px-4 rounded-md disabled:bg-N-600 disabled:pointer-events-none"
                                    onClick={() => setOpenJira(o => !o)}
                                >
                                    <i className="ri-settings-3-line"></i>
                                </button>

                                {step.id === 'business-requirements' && (
                                    <JiraSettingsModal open={openJira} onClose={() => setOpenJira(false)} />
                                )}
                                {step.id === 'impact-analysis' && (
                                    <BitBucketSettingsModal open={openJira} onClose={() => setOpenJira(false)} />
                                )}
                            </>
                        ) : (
                            <>
                                <FeedbackModal
                                    open={feedbackOpen}
                                    onClose={() => {
                                        setFeedbackOpen(false);
                                        step.handleClick();
                                    }}
                                />
                            </>
                        )
                    }
                />
            )}
        </div>
    );
};

export { StepContainer };
