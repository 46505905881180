import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import classNames from 'classnames';

interface IPageLoadingProps {
    title?: string;
    onlyIcon?: boolean;
    textColor?: string;
    height?: string;
}

export const PageLoading = ({
    title = 'Loading...',
    onlyIcon = false,
    textColor = 'text-N-600',
    height = 'h-[500px]',
}: IPageLoadingProps) => {
    return (
        <div className={`m-4 ${height} flex items-center justify-center flex-col`}>
            <SpinLoader />
            {!onlyIcon && <p className={classNames('text-sm mt-[8px]', textColor)}>{title}</p>}
        </div>
    );
};
