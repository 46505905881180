import { Avatar } from '@kaya/kaya-ui-design-system-pb';
import { IMG_KAYA_BOT_GIF, IMG_KAYA_BOT_PNG } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { getInitials } from '../../../utils';
import { TMessageAvatar } from '../../pages/messenger/types';
import { useMobile } from '../../../hooks';

const MessageAvatar = ({ isUser, animated }: TMessageAvatar) => {
    const { userDetails } = useChatContext();
    const isMobile = useMobile();

    const avatarConfig = {
        type: userDetails.imageUrl ? 'image' : 'text',
        imageURL: userDetails.imageUrl,
        textLabel: getInitials(userDetails.name),
    };

    if (isUser) {
        return (
            <Avatar
                className="rounded-full order-1 min-w-9"
                size="md"
                icon="ri-user-3-line"
                shape="circle"
                type={'text'}
                imageURL={avatarConfig.imageURL}
                textLabel={avatarConfig.textLabel}
            />
        );
    }

    return (
        <div className="logo bg-B-600 w-9 h-9 flex justify-center items-center min-w-9 rounded-full">
            <img src={IMG_KAYA_BOT_PNG} className="rounded-full" alt="" />
        </div>
    );
};

export { MessageAvatar };
