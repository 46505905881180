import React from 'react';
import { AdminPanelSectionSelectionTypes, useSideBarContext } from '../../../contexts';
import { KPLManagementContainer } from '../KPL-management-container';
import { PromptManagementContainer } from '../prompt-management-container/PromptManagementContainer';
import { UserManagementContainer } from '../user-management-container';
import { ExternalUserManagementContainer } from '../external-user-management-container';

export const AdminPanel = () => {
    const { adminPanelSelection } = useSideBarContext();
    return (
        <React.Fragment>
            {adminPanelSelection === AdminPanelSectionSelectionTypes.KPL_AUTHORING && <KPLManagementContainer />}
            {adminPanelSelection === AdminPanelSectionSelectionTypes.USER_AUTHORING && <UserManagementContainer />}
            {adminPanelSelection === AdminPanelSectionSelectionTypes.PROMPT_AUTHORING && <PromptManagementContainer />}
            {adminPanelSelection === AdminPanelSectionSelectionTypes.EXTERNAL_USER && (
                <ExternalUserManagementContainer />
            )}
        </React.Fragment>
    );
};
