import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { GET_APPLICATION_CONFIGURATIONS } from '../graphql';

export const useAllKPLs = () => {
    const { data } = useQuery(GET_APPLICATION_CONFIGURATIONS, {
        fetchPolicy: 'cache-first',
        variables: {
            enabled: null,
        },
    });

    const ALL_KPL_SELECT_LIST = useMemo(() => {
        return (
            data?.getApplicationConfig?.map((kpl: any) => ({
                title: kpl.title,
                configKey: kpl.configKey,
            })) || []
        );
    }, [data]);

    const KPL_SELECT_LIST = useMemo(() => {
        return (
            data?.getApplicationConfig?.map(config => ({
                id: config?.configKey || '',
                name: config?.title || '',
            })) || []
        );
    }, [data]);

    const findTitleByConfigKey = useCallback(
        (configKey: string) => ALL_KPL_SELECT_LIST?.find(config => config?.configKey === configKey)?.title || '',
        [ALL_KPL_SELECT_LIST]
    );

    const findConfigKeyByTitle = useCallback(
        (title: string) => ALL_KPL_SELECT_LIST?.find(config => config?.title === title)?.configKey || '',
        [ALL_KPL_SELECT_LIST]
    );

    const doesKPLConfigExist = useCallback(
        (configKey: string) => {
            return ALL_KPL_SELECT_LIST.some(config => config.configKey === configKey);
        },
        [ALL_KPL_SELECT_LIST]
    );

    const doesKPLTitleExist = (kplFormDataTitle: string, selectedKPLKey?: string | null): boolean => {
        return ALL_KPL_SELECT_LIST.some(
            kpl => kpl.title === kplFormDataTitle && (!selectedKPLKey || kpl.title !== selectedKPLKey)
        );
    };

    return {
        ALL_KPL_SELECT_LIST,
        KPL_SELECT_LIST,
        findTitleByConfigKey,
        findConfigKeyByTitle,
        doesKPLConfigExist,
        doesKPLTitleExist,
    };
};
