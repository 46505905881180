import React, { useEffect, useState } from 'react';
import { GoogleFactCheckLoadingState, LLMCompareLoadingState } from '../FactCheckResponseContainer';
import { FormattedChatMessage } from '../../message';
import { ParagraphSkeleton } from '../../Skelton';
import { PageLoading } from '../../full-page-loader';

interface ILLLMResponseCardProps {
    factCheckLoadingMessage: GoogleFactCheckLoadingState;
    compareResponse?: string | null;
    loadingStateMessage: LLMCompareLoadingState;
}

export const LLMResponseCard = ({
    factCheckLoadingMessage,
    compareResponse,
    loadingStateMessage,
}: ILLLMResponseCardProps) => {
    const [loadingMessage, setLoadingMessage] = useState<string[]>([]);

    useEffect(() => {
        setLoadingMessage([...loadingMessage, String(loadingStateMessage)]);
    }, [loadingStateMessage]);

    return (
        <div className="flex flex-col border border-N-200">
            <div className="flex cursor-pointer py-[12px] px-[27px] bg-N-100">
                <p className="text-N-900 font-500 text-md">Fact Check Analysis</p>
            </div>
            <div className="px-7 py-4 min-h-[300px] max-h-[3000px] overflow-y-scroll scroll">
                <p className="text-N-700 text-md">
                    {loadingStateMessage === LLMCompareLoadingState.WAITING_FOR_GOOGLE_RESPONSE ? (
                        <div className="relative items-center justify-center pt-24">
                            <PageLoading height="20px" title={LLMCompareLoadingState.WAITING_FOR_GOOGLE_RESPONSE} />
                        </div>
                    ) : (
                        <>
                            {loadingStateMessage === LLMCompareLoadingState.COMPLETED &&
                            typeof compareResponse === 'string' ? (
                                <FormattedChatMessage text={compareResponse as string} />
                            ) : (
                                <div className="relative items-center justify-center">
                                    <div className="absolute w-fit top-[90px] left-[350px]">
                                        {loadingMessage.map((message: string) => {
                                            return <p className="text-sm font-400 text-N-600">{message}</p>;
                                        })}
                                    </div>
                                    <ParagraphSkeleton lineCount={10} />
                                </div>
                            )}
                        </>
                    )}
                </p>
            </div>
        </div>
    );
};
