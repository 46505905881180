import { useCustomKplContext } from '../../../../../../../contexts';

export const ConversationStarters = () => {
    const { isQuestionLoading, conversationStarters } = useCustomKplContext();

    if (isQuestionLoading)
        return (
            <div className="w-full flex flex-col items-center justify-center">
                <span>Conversation starters are generating...</span>
            </div>
        );

    return conversationStarters?.length ? (
        <div className="flex flex-col gap-y-3 w-full">
            {conversationStarters?.map((conversation: any, i: number) => (
                <div
                    className="bg-N-200 px-5 py-2 text-left rounded-[3px] duration-300 animate-fade-up animate-once"
                    key={i}
                >
                    {conversation}
                </div>
            ))}
        </div>
    ) : (
        <span>Click on 'Save Changes' to generate conversation starters.</span>
    );
};
