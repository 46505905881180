import { SpinLoader, TextField } from '@kaya/kaya-ui-design-system-pb';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { CSS_LIST } from '../../../constants';
import {
    AppConfigType,
    useChatContext,
    useSideBarContext,
    AdminPanelSectionSelectionTypes,
    useCustomKplContext,
} from '../../../contexts';
import { CN, getFilteredFeaturesByEmail } from '../../../utils';
import { MobileMenuButton } from '../buttons';
import { KPLCard } from '../kpl-card';
import { ChatType, FeatureType } from '../../../__generated__/graphql';
import { useMobile } from '../../../hooks';

export const KPLList = () => {
    const { handleMobileMenuIconClick, setShowFeedbackModal, setAdminPanelSelection } = useSideBarContext();
    const { appConfig, featureType, currentUserConfig, handleAppConfigSelection, isCurrentUserGuest, kplListLoading } =
        useChatContext();

    const { isGuestKplCreated } = useCustomKplContext();

    const [features, setFeatures] = useState<AppConfigType[]>(
        getFilteredFeaturesByEmail(currentUserConfig, appConfig, isGuestKplCreated , isCurrentUserGuest)
    );
    const [value, setValue] = useState('');
    const [debounced] = useDebouncedValue(value, 200);
    const isMobile = useMobile();

    //useEffect is used to fetch the KPL list once the API call is completed.
    //TODO :: use useLayoutEffect
    useEffect(() => {
        setFeatures(getFilteredFeaturesByEmail(currentUserConfig, appConfig, isCurrentUserGuest));
    }, [appConfig]);

    const KPL_LIST = features
        .filter(item => item.title.toLowerCase().includes(debounced.toLowerCase()))
        .map((feature, index) => {
            const actionKpl = [ChatType.Operation].includes(feature.chatType);

            return {
                id: index,
                feature: feature,
                currentFeatureType: featureType,
                actionKpl,
                onClick: () => {
                    if (feature.configKey === FeatureType.Feedback) {
                        setShowFeedbackModal(true);
                    } else {
                        handleAppConfigSelection(feature);
                        handleMobileMenuIconClick();
                        setAdminPanelSelection(AdminPanelSectionSelectionTypes.NOT_SELECTED);
                    }
                },
            };
        });

    return (
        <div>
            <div className="flex justify-between items-center border-b border-b-N-200">
                <div className="chat_history_title py-[20px] sm:py-4 px-6 sm:px-3 flex gap-x-[10px] items-center border-b-N-200">
                    <p className="text-lg sm:text-lg font-600 text-N-900">Explore KPLs</p>
                </div>
                <MobileMenuButton />
            </div>
            <div className="chat_history_input p-3 border-b-N-200">
                <TextField
                    value={value}
                    onChange={(event: any) => setValue(event.currentTarget.value)}
                    view="default"
                    wrapperClassName={`w-full`}
                    placeholder="Search KPLs"
                    iconBefore="ri-search-line"
                />
            </div>
            <div
                className={CN(CSS_LIST, {
                    '!h-full': isMobile,
                })}
            >
                {!kplListLoading ? (
                    KPL_LIST.map((item: any, index: number) => <KPLCard key={index} {...item} />)
                ) : (
                    <div className="flex items-center gap-x-3 px-4 py-3">
                        <SpinLoader />
                        <span>Preparing your KPLs.</span>
                    </div>
                )}
            </div>
        </div>
    );
};
