import { IMG_KAYA_BUTTON_SEND } from '../../../../../constants';
import { CustomKplSectionCreationSteps, useChatContext, useCustomKplContext } from '../../../../../contexts';
import { useCustomKplStepper } from '../../../../../hooks/useCustomKplStepper';
import { CN } from '../../../../../utils';
import { TextBox } from '../../../custom/TextBox';
import { ChatStepper } from './common/ChatStepper';

export const CustomKplCreate = () => {
    const sendButtonIcon = IMG_KAYA_BUTTON_SEND;
    const { kplCreateSteps } = useCustomKplStepper();
    const { handleSubmit, currentStep } = useCustomKplContext();
    const { currentMessage } = useChatContext();

    return (
        <div className="h-full">
            <div className="flex-grow !min-h-[calc(100vh-216px)] sm:!h-[calc(100vh-290px)] sm:!min-h-[calc(100vh-167px)] !h-[calc(100vh-216px)] overflow-auto px-[15%] sm:px-[2%] xxl:!p-5 pb-4">
                {kplCreateSteps?.map((steps: any) => <ChatStepper steps={steps} key={steps?.id} />)}
            </div>

            <div className="bg-white px-6 pb-4 pt-6 sm:pt-0  flex items-center border-t border-t-N-200">
                <div className="flex items-center justify-between w-full relative">
                    <TextBox
                        onSend={() => {
                            handleSubmit();
                        }}
                        isDisabled={
                            currentStep !== CustomKplSectionCreationSteps.CREATE_NAME &&
                            currentStep !== CustomKplSectionCreationSteps.RESPONSE_CONFIGURE
                        }
                        placeholder="Ask your question here..."
                    />
                    <div className="flex gap-x-2 items-center absolute right-0 top-[20px] pr-3">
                        <button
                            title={'Send'}
                            onClick={() => {
                                if (
                                    currentStep === CustomKplSectionCreationSteps.CREATE_NAME ||
                                    currentStep === CustomKplSectionCreationSteps.RESPONSE_CONFIGURE
                                ) {
                                    handleSubmit();
                                }
                            }}
                            disabled={
                                (currentStep !== CustomKplSectionCreationSteps.CREATE_NAME &&
                                    currentStep !== CustomKplSectionCreationSteps.RESPONSE_CONFIGURE) ||
                                !currentMessage?.length
                            }
                            type="button"
                            className={CN(
                                'inline-flex items-center justify-center h-10 w-10 transition duration-150 ease-in-out text-N-700 hover:cursor-pointer hover:scale-110 focus:outline-none',
                                {
                                    'hover:scale-100 cursor-not-allowed opacity-[.7]':
                                        (currentStep !== CustomKplSectionCreationSteps.CREATE_NAME &&
                                            currentStep !== CustomKplSectionCreationSteps.RESPONSE_CONFIGURE) ||
                                        !currentMessage?.length,
                                }
                            )}
                        >
                            <img
                                src={sendButtonIcon}
                                alt="send_message_icon"
                                className={CN({
                                    'hover:scale-100 cursor-not-allowed opacity-[.7]':
                                        (currentStep !== CustomKplSectionCreationSteps.CREATE_NAME &&
                                            currentStep !== CustomKplSectionCreationSteps.RESPONSE_CONFIGURE) ||
                                        !currentMessage?.length,
                                })}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
