import {
    AdminPanelSectionSelectionTypes,
    CustomKplSectionCreationSteps,
    CustomKplSectionSelectionTypes,
    SideBarButtonSelectionTypes,
    useChatContext,
    useCustomKplContext,
    useSideBarContext,
} from '../../../../contexts';
import { CN } from '../../../../utils';
import { ButtonWithTooltip, SideBarButton } from '../../buttons';
import { useMobile } from '../../../../hooks';
import { useEffect } from 'react';

export const SidebarPrimaryBtnSection = () => {
    const { handleNewChat, isCurrentUserGuest } = useChatContext();
    const {
        handleMobileMenuIconClick,
        setIsCollapsed,
        setAdminPanelSelection,
        isMenuCollapsed,
        setCustomKplSelection,
    } = useSideBarContext();
    const isMobile = useMobile();
    const { isGuestKplCreated, currentStep } = useCustomKplContext();

    useEffect(() => {
        if (currentStep !== CustomKplSectionCreationSteps?.KPL_CREATED) {
            if (isCurrentUserGuest && !isGuestKplCreated) {
                setAdminPanelSelection(AdminPanelSectionSelectionTypes.CUSTOM_KPL);
            } else if (isCurrentUserGuest && isGuestKplCreated) {
                setAdminPanelSelection(AdminPanelSectionSelectionTypes.NOT_SELECTED);
            }
        }
    }, [isGuestKplCreated, isCurrentUserGuest]);

    return (
        <div
            className={CN('sidebar_primary_section flex flex-col gap-y-6 px-3 py-1', {
                'pt-4 !gap-y-4 px-[24px]': isMobile,
            })}
        >
            <ButtonWithTooltip title="Start a New Chat" showTooltip={!isMenuCollapsed && !isMobile}>
                <button
                    onClick={() => {
                        handleNewChat();
                        handleMobileMenuIconClick();
                        setIsCollapsed(true);
                        setAdminPanelSelection(AdminPanelSectionSelectionTypes.NOT_SELECTED);
                    }}
                    className={CN(
                        'flex items-center justify-center gap-x-2 py-3 px-4 border-[0.5px] border-N-200 rounded-[2px] shadow appearance-none focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-B-700 active:outline-B-700  hover:border-N-300 ease-in-out duration-75 delay-400',
                        {
                            'h-[40px] w-[40px] rounded-[8px] bg-B-600': !isMenuCollapsed && !isMobile,
                            'opacity-[.8] cursor-not-allowed': isCurrentUserGuest && !isGuestKplCreated,
                        }
                    )}
                    disabled={isCurrentUserGuest && !isGuestKplCreated}
                >
                    <i
                        className={CN('ri-add-circle-fill text-md text-B-600', {
                            '!text-B-50': !isMenuCollapsed && !isMobile,
                            'text-N-500': isCurrentUserGuest && !isGuestKplCreated,
                        })}
                    />
                    {(isMenuCollapsed || isMobile) && (
                        <p
                            className={CN(
                                'text-B-600 font-500 text-sm whitespace-nowrap duration-300 ease-in-out delay-150',
                                {
                                    'text-N-500': isCurrentUserGuest && !isGuestKplCreated,
                                }
                            )}
                        >
                            Start a New Chat
                        </p>
                    )}
                </button>
            </ButtonWithTooltip>
            <div className="bg-[#E5E7EB] h-[1px] w-full" />
            <div className="flex flex-col gap-y-3">
                {!isCurrentUserGuest || (isCurrentUserGuest && isGuestKplCreated) ? (
                    <SideBarButton
                        label="Chat History"
                        type="primary"
                        icon="ri-chat-history-fill"
                        buttonSelectionType={SideBarButtonSelectionTypes.ChatHistorySelection}
                        isIconOnly={!isMenuCollapsed && !isMobile}
                    />
                ) : (
                    <></>
                )}
                {isCurrentUserGuest && (
                    <SideBarButton
                        label="Custom KPL"
                        type="primary"
                        icon="ri-function-add-line"
                        buttonSelectionType={SideBarButtonSelectionTypes.CustomKplSection}
                        isIconOnly={!isMenuCollapsed && !isMobile}
                        onClick={() => {
                            setAdminPanelSelection(AdminPanelSectionSelectionTypes.CUSTOM_KPL);
                            setCustomKplSelection(
                                isGuestKplCreated
                                    ? CustomKplSectionSelectionTypes.CONFIGURE
                                    : CustomKplSectionSelectionTypes.CREATE
                            );
                        }}
                    />
                )}
                <SideBarButton
                    label="Explore KPLs"
                    type="primary"
                    icon="ri-stack-fill"
                    buttonSelectionType={SideBarButtonSelectionTypes.KplListSelection}
                    isIconOnly={!isMenuCollapsed && !isMobile}
                    isDisabled={isCurrentUserGuest && !isGuestKplCreated}
                />
            </div>
        </div>
    );
};
