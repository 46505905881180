import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { RequirementType } from '../__generated__/graphql';
import { GenTechSpecQuestionType, IGenerationQuestion } from '../components/common';
import { useChatContext } from '../contexts';
import { GENERATE_CODE_FROM_REQUIREMENTS, GENERATE_JIRA_TICKETS, GENERATE_REQUIREMENTS } from '../graphql';
import { useScroll } from '../hooks';
import * as Mock from '../constants';
import { logger } from '../utils';

const isMock = false;

const useGenerateTechnicalSpec = () => {
    const [fileUploaded, setFileUploaded] = useState<File | null>(null);
    const [businessRequirement, setBusinessRequirement] = useState<string>(
        isMock ? Mock.GENERATE_REQUIREMENTS_MOCK : ''
    );
    const [technicalRequirement, setTechnicalRequirement] = useState<string>(
        isMock ? Mock.GENERATE_TECHNICAL_REQUIREMENTS_MOCK : ''
    );
    const [jiraBusinessRequirement, setJiraBusinessRequirement] = useState<string>(
        isMock ? Mock.GENERATE_REQUIREMENTS_JIRA_MOCK : ''
    );
    const [jiraTechnicalRequirement, setJiraTechnicalRequirement] = useState<string>(
        isMock ? Mock.GENERATE_TECHNICAL_REQUIREMENTS_JIRA_MOCK : ''
    );
    const [impactAnalysisCompleted, setImpactAnalysisCompleted] = useState<string>('');
    const [codeGenerated, setCodeGenerated] = useState<string>(isMock ? Mock.GENERATED_CODE_BLOCK : '');
    const [codeGenerationExecution, setCodeGenerationExecution] = useState<boolean>(false);
    const [feedbackSaved, setFeedbackSaved] = useState('');
    const [requirementLoading, setRequirementLoading] = useState({
        business: false,
        technical: false,
        businessJira: false,
        technicalJira: false,
        generateCode: false,
        downloadCode: false,
        impactAnalysis: false,
    });

    const { loading, userDetails, userFirstName, jiraOptions, showJiraButtons, technicalRequirementValue } =
        useChatContext();
    const { scrollRef } = useScroll([loading, requirementLoading]);

    const [generateRequirements] = useMutation(GENERATE_REQUIREMENTS);
    const [generateJiraTickets] = useMutation(GENERATE_JIRA_TICKETS);
    const [generateCodeFromRequirements] = useMutation(GENERATE_CODE_FROM_REQUIREMENTS);

    const [questions, setQuestions] = useState<IGenerationQuestion[]>([
        {
            text: 'Could you give us a brief overview of your current system architecture?',
            options: ['Monolithic', 'Microservices', 'Serverless', 'Event-driven', 'Service-oriented', 'Layered'],
            type: GenTechSpecQuestionType.OPTIONS,
            answer: '',
        },
        {
            text: 'What programming languages and frameworks are you leaning towards for this project?',
            options: ['Python', 'JavaScript', 'Java'],
            type: GenTechSpecQuestionType.OPTIONS,
            answer: '',
        },
        {
            text: 'Which database management system are you considering for this application?',
            options: ['MySQL', 'PostgreSQL', 'MongoDB', 'SQL Server', 'Oracle'],
            type: GenTechSpecQuestionType.OPTIONS,
            answer: '',
        },
        {
            text: 'Where do you plan to deploy this application?',
            options: ['AWS', 'Google Cloud Platform (GCP)', 'Microsoft Azure', 'Heroku', 'DigitalOcean'],
            type: GenTechSpecQuestionType.OPTIONS,
            answer: '',
        },
        {
            text: 'How do you currently handle user authentication and authorization?',
            options: ['Keycloak', 'OAuth2.0', 'OpenID Connect', 'SAML', 'JWT'],
            type: GenTechSpecQuestionType.OPTIONS,
            answer: '',
        },
        {
            text: 'Do you have any preferred libraries or services? Please list them.',
            options: [],
            type: GenTechSpecQuestionType.TEXT,
            answer: '',
        },
        {
            text: 'Would you like to share your coding standards and best practices? If so, please upload the document.',
            options: [],
            type: GenTechSpecQuestionType.FILE,
            answer: '',
        },
    ]);

    const handleQuestionAnswerChange = (questionIndex: number, selectedOption: string | File) => {
        setQuestions(prevQuestions => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[questionIndex].answer = selectedOption;
            return updatedQuestions;
        });
    };

    const handleBusinessRequirements = useCallback(async () => {
        try {
            if (fileUploaded) {
                setRequirementLoading(l => ({ ...l, business: true }));

                const { data } = await generateRequirements({
                    variables: {
                        input: {
                            userId: userDetails.id,
                            transcript: fileUploaded,
                            type: RequirementType.Business,
                        },
                    },
                });

                setBusinessRequirement(data?.generateRequirements || '');
                setRequirementLoading(l => ({ ...l, business: false }));
            }
        } catch (error) {
            setRequirementLoading(l => ({ ...l, business: false }));
        }
    }, [fileUploaded, generateRequirements, userDetails.id]);

    const handleTechnicalRequirements = useCallback(async () => {
        try {
            setRequirementLoading(l => ({ ...l, technical: true }));

            const { data } = await generateRequirements({
                variables: {
                    input: {
                        userId: userDetails.id,
                        requirements: `Requirements: ${technicalRequirement} \n\n Jira Ticket Details:\n ${jiraBusinessRequirement}`,
                        type: RequirementType.Technical,
                    },
                },
            });

            setTechnicalRequirement(data?.generateRequirements || '');
            setRequirementLoading(l => ({ ...l, technical: false }));
        } catch (error) {
            setRequirementLoading(l => ({ ...l, technical: false }));
        }
    }, [generateRequirements, jiraBusinessRequirement, technicalRequirement, userDetails.id]);

    const handleGenerateBusinessJiraTickets = useCallback(async () => {
        try {
            setRequirementLoading(l => ({ ...l, businessJira: true }));

            const { data } = await generateJiraTickets({
                variables: {
                    input: {
                        userId: userDetails.id,
                        requirements: businessRequirement,
                        jiraUserEmail: jiraOptions.jiraUserEmail,
                        jiraUserToken: jiraOptions.jiraUserToken,
                        jiraInstanceName: jiraOptions.jiraInstanceName,
                        jiraProjectKey: jiraOptions.jiraProjectKey,
                        type: RequirementType.Business,
                    },
                },
            });

            setJiraBusinessRequirement(data?.generateJiraTickets || '');
            setRequirementLoading(l => ({ ...l, businessJira: false }));
        } catch (error) {
            logger.log(error);
            setRequirementLoading(l => ({ ...l, businessJira: false }));
        }
    }, [businessRequirement, generateJiraTickets, jiraOptions, userDetails]);

    const handleGenerateTechnicalJiraTickets = useCallback(async () => {
        try {
            setRequirementLoading(l => ({ ...l, technicalJira: true }));

            const { data } = await generateJiraTickets({
                variables: {
                    input: {
                        userId: userDetails.id,
                        requirements: `Requirements: ${technicalRequirementValue} \n\n Jira Ticket Details:\n ${jiraBusinessRequirement}`,
                        jiraUserEmail: jiraOptions.jiraUserEmail,
                        jiraUserToken: jiraOptions.jiraUserToken,
                        jiraInstanceName: jiraOptions.jiraInstanceName,
                        jiraProjectKey: jiraOptions.jiraProjectKey,
                        type: RequirementType.Technical,
                    },
                },
            });

            setJiraTechnicalRequirement(data?.generateJiraTickets || '');
            setRequirementLoading(l => ({ ...l, technicalJira: false }));
        } catch (error) {
            logger.log(error);
            setRequirementLoading(l => ({ ...l, technicalJira: false }));
        }
    }, [generateJiraTickets, jiraBusinessRequirement, technicalRequirementValue, jiraOptions, userDetails.id]);

    const handleImpactAnalysis = () => {
        setRequirementLoading(l => ({ ...l, impactAnalysis: true }));
        setTimeout(() => {
            setImpactAnalysisCompleted(Mock.IMPACT_INTRO);
            setRequirementLoading(l => ({ ...l, impactAnalysis: false }));
        }, 12000);
    };

    const handleProceedToCodeGeneration = useCallback(() => {
        // setCodeGenerationExecution(true);
        handleGenerateCode();
    }, []);

    const handleGenerateCode = useCallback(async () => {
        try {
            setRequirementLoading(l => ({ ...l, generateCode: true }));

            const { data } = await generateCodeFromRequirements({
                variables: {
                    input: {
                        requirements: technicalRequirement,
                        language: String(questions[0].answer),
                        database: String(questions[1].answer),
                        deployment: String(questions[2].answer),
                        authentication: String(questions[3].answer),
                        libraries: String(questions[4].answer),
                        architecture: String(questions[5].answer),
                        // guidelines: questions[6].answer,
                        userId: userDetails.id,
                    },
                },
            });

            setCodeGenerated(data?.generateCodeFromRequirements || '');
            setRequirementLoading(l => ({ ...l, generateCode: false }));
        } catch (error) {
            logger.log(error);
            setRequirementLoading(l => ({ ...l, generateCode: false }));
        }
    }, [generateCodeFromRequirements, technicalRequirement, questions, userDetails]);

    const handleDownloadCode = useCallback(() => {
        try {
            window.open(Mock.GENERATED_CODE_FILE, '_blank');
        } catch (error) {
            logger.log(error);
        }
    }, []);

    return {
        userFirstName,
        fileUploaded,
        scrollRef,
        requirementLoading,
        businessRequirement,
        jiraBusinessRequirement,
        technicalRequirement,
        jiraTechnicalRequirement,
        codeGenerationExecution,
        codeGenerated,
        questions,
        showJiraButtons,
        setFileUploaded,
        handleBusinessRequirements,
        handleTechnicalRequirements,
        handleGenerateBusinessJiraTickets,
        handleGenerateTechnicalJiraTickets,
        handleGenerateCode,
        handleDownloadCode,
        handleProceedToCodeGeneration,
        handleQuestionAnswerChange,
        feedbackSaved,
        setFeedbackSaved,
        impactAnalysisCompleted,
        handleImpactAnalysis,
    };
};

export { useGenerateTechnicalSpec };
