import React, { useState } from 'react';
import { Button, TextField, UIModalV2 } from '@kaya/kaya-ui-design-system-pb';

const TranscriptModal = ({ isOpen, onClose, onSave, editedData, setEditedData }: any) => {
    const [newAttendees, setNewAttendees] = useState<any>('');
    const [newProspectAttendees, setNewProspectAttendees] = useState<any>('');
    const [newKeyInsights, setNewKeyInsights] = useState<any>('');

    const handleSave = () => {
        onSave(editedData);
        onClose();
    };

    const handleAddAttendee = () => {
        if (newAttendees.trim()) {
            const updatedAttendees = [...editedData.ecoCartAttendance, newAttendees];
            setEditedData({ ...editedData, ecoCartAttendance: updatedAttendees });
            setNewAttendees('');
        }
    };

    const handleAddProspectAttendee = () => {
        if (newProspectAttendees.trim()) {
            const updatedProspects = [...editedData.prospectAttendance, newProspectAttendees];
            setEditedData({ ...editedData, prospectAttendance: updatedProspects });
            setNewProspectAttendees('');
        }
    };

    const handleAddKeyInsight = () => {
        if (newKeyInsights.trim()) {
            const updatedInsights = [...editedData.keyInsights, newKeyInsights];
            setEditedData({ ...editedData, keyInsights: updatedInsights });
            setNewKeyInsights('');
        }
    };

    const handleDeleteAttendee = (index: number) => {
        const updatedAttendees = editedData.ecoCartAttendance.filter((_: any, i: any) => i !== index);
        setEditedData({ ...editedData, ecoCartAttendance: updatedAttendees });
    };

    const handleDeleteProspectAttendee = (index: number) => {
        const updatedProspects = editedData.prospectAttendance.filter((_: any, i: any) => i !== index);
        setEditedData({ ...editedData, prospectAttendance: updatedProspects });
    };

    const handleDeleteKeyInsight = (index: number) => {
        const updatedInsights = editedData.keyInsights.filter((_: any, i: any) => i !== index);
        setEditedData({ ...editedData, keyInsights: updatedInsights });
    };

    return (
        <UIModalV2
            header={
                <div className="flex items-center justify-between">
                    <div className="flex gap-x-3">
                        <i className="ri-body-scan-fill text-h6" />
                        <p className="text-md text-N-900 font-500">Edit generated content</p>
                    </div>
                </div>
            }
            body={
                <div className="h-[70vh] overflow-y-scroll p-2">
                    <div className="flex flex-row justify-between my-3">
                        <div>
                            <p>Date: </p>
                            <TextField
                                value={editedData?.date}
                                onChange={(e: any) => setEditedData({ ...editedData, date: e.target.value })}
                                view="default"
                                type="date"
                                wrapperClassName="w-full"
                            />
                        </div>
                        <div>
                            <p>Time: </p>
                            <TextField
                                value={editedData?.time}
                                onChange={(e: any) => setEditedData({ ...editedData, time: e.target.value })}
                                type="time"
                                view="default"
                                wrapperClassName="w-full"
                            />
                        </div>
                        <div>
                            <p>Call Length: </p>
                            <TextField
                                value={editedData?.callLength}
                                onChange={(e: any) => setEditedData({ ...editedData, callLength: e.target.value })}
                                view="default"
                                wrapperClassName="w-full"
                            />
                        </div>
                    </div>

                    <div className="my-3">
                        <p>Account Name: </p>
                        <TextField
                            value={editedData?.accountName}
                            onChange={(e: any) => setEditedData({ ...editedData, accountName: e.target.value })}
                            view="default"
                            type="text"
                            wrapperClassName="w-full"
                        />
                    </div>

                    <div className="my-3">
                        <p>EcoCart Attendees: </p>
                        {editedData.ecoCartAttendance.map((name: any, index: any) => (
                            <div key={index} className="flex gap-1 items-center">
                                <TextField
                                    className="my-0"
                                    value={name}
                                    onChange={(e: any) => {
                                        const newEcoCartAttendance = [...editedData.ecoCartAttendance];
                                        newEcoCartAttendance[index] = e.target.value;
                                        setEditedData({ ...editedData, ecoCartAttendance: newEcoCartAttendance });
                                    }}
                                    view="default"
                                    type="text"
                                    wrapperClassName="w-full"
                                />
                                <Button
                                    className="mb-1"
                                    view="ghost"
                                    appearance="danger"
                                    size="small"
                                    onClick={() => handleDeleteAttendee(index)}
                                    iconAfter="ri-delete-bin-6-line"
                                />
                            </div>
                        ))}
                        <div className="w-full flex items-center gap-2">
                            <TextField
                                value={newAttendees}
                                view="default"
                                type="text"
                                placeholder="Add new EcoCart Attendees"
                                wrapperClassName="flex-grow mt-1"
                                onChange={(e: any) => {
                                    setNewAttendees(e.target.value);
                                }}
                            />
                            <Button
                                className="w-1/12 h-full"
                                view="solid"
                                appearance="primary"
                                size="medium"
                                onClick={handleAddAttendee}
                                iconAfter="ri-add-circle-line"
                            />
                        </div>
                    </div>

                    <div className="my-3">
                        <p>Prospect Attendees: </p>
                        {editedData.prospectAttendance.map((name: any, index: any) => (
                            <div key={index} className="flex justify-center items-center">
                                <TextField
                                    className="my-0"
                                    value={name}
                                    onChange={(e: any) => {
                                        const newProspectAttendance = [...editedData.prospectAttendance];
                                        newProspectAttendance[index] = e.target.value;
                                        setEditedData({ ...editedData, prospectAttendance: newProspectAttendance });
                                    }}
                                    view="default"
                                    type="text"
                                    wrapperClassName="w-full"
                                />
                                <Button
                                    className="mb-1"
                                    view="ghost"
                                    appearance="danger"
                                    size="small"
                                    onClick={() => handleDeleteProspectAttendee(index)}
                                    iconAfter="ri-delete-bin-6-line"
                                />
                            </div>
                        ))}
                        <div className="w-full flex items-center gap-2">
                            <TextField
                                value={newProspectAttendees}
                                view="default"
                                type="text"
                                placeholder="Add new Prospect Attendees"
                                wrapperClassName="flex-grow mt-1"
                                onChange={(e: any) => {
                                    setNewProspectAttendees(e.target.value);
                                }}
                            />
                            <Button
                                className="w-1/12 h-full"
                                view="solid"
                                appearance="primary"
                                size="medium"
                                onClick={handleAddProspectAttendee}
                                iconAfter="ri-add-circle-line"
                            />
                        </div>
                    </div>

                    <div className="my-3">
                        <p>Key Insights: </p>
                        {editedData.keyInsights.map((name: any, index: any) => (
                            <div key={index} className="flex justify-center items-center">
                                <TextField
                                    className="my-0"
                                    value={name}
                                    onChange={(e: any) => {
                                        const newKeyInsights = [...editedData.keyInsights];
                                        newKeyInsights[index] = e.target.value;
                                        setEditedData({ ...editedData, keyInsights: newKeyInsights });
                                    }}
                                    view="default"
                                    type="text"
                                    wrapperClassName="w-full"
                                />
                                <Button
                                    className="mb-1"
                                    view="ghost"
                                    appearance="danger"
                                    size="small"
                                    onClick={() => handleDeleteKeyInsight(index)}
                                    iconAfter="ri-delete-bin-6-line"
                                />
                            </div>
                        ))}
                        <div className="w-full flex items-center gap-2">
                            <TextField
                                value={newKeyInsights}
                                view="default"
                                type="text"
                                placeholder="Add new Key Insights"
                                wrapperClassName="flex-grow mt-1"
                                onChange={(e: any) => {
                                    setNewKeyInsights(e.target.value);
                                }}
                            />
                            <Button
                                className="w-1/12 h-full"
                                view="solid"
                                appearance="primary"
                                size="medium"
                                onClick={handleAddKeyInsight}
                                iconAfter="ri-add-circle-line"
                            />
                        </div>
                    </div>

                    <div className="my-3">
                        <p>Next Steps: </p>
                        <TextField
                            value={editedData?.nextStep}
                            onChange={(e: any) => setEditedData({ ...editedData, nextStep: e.target.value })}
                            view="default"
                            type="text"
                            wrapperClassName="w-full"
                        />
                    </div>

                    <div className="my-3">
                        <p>Miscellaneous: </p>
                        <TextField
                            value={editedData?.miscellaneous}
                            onChange={(e: any) => setEditedData({ ...editedData, miscellaneous: e.target.value })}
                            view="default"
                            type="text"
                            wrapperClassName="w-full"
                        />
                    </div>
                </div>
            }
            footer={
                <Button
                    size="medium"
                    view="ghost"
                    className="bg-B-500 p-2 hover:bg-B-400 text-white mt-4 self-end"
                    onClick={handleSave}
                >
                    Save & Close
                </Button>
            }
            open={isOpen}
            onClose={onClose}
        />
    );
};

export default TranscriptModal;
