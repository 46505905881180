import { Drawer } from '@kaya/kaya-ui-design-system-pb';
import { Sidebar } from '../Sidebar';
import { useSideBarContext } from '../../../../contexts';
import { SidebarLogoSection } from './SidebarLogoSection';
import { SidebarAvatarSection } from './SidebarAvatarSection';
import { ExtendedSidebar } from '../../extended-sidebar';

export const MobileSidebarContainer = () => {
    const { showMobileSideBar, setShowMobileSideBar, isCollapsed, setIsCollapsed } = useSideBarContext();
    return (
        <Drawer
            isOpen={showMobileSideBar}
            title=""
            onClose={() => {
                setShowMobileSideBar(false);
            }}
            closableOnClickOutside={false}
            fullWidth={true}
            header={<SidebarLogoSection />}
            footer={<SidebarAvatarSection />}
        >
            <>
                <Sidebar />
                <Drawer
                    isOpen={isCollapsed}
                    title=""
                    onClose={() => {
                        setIsCollapsed(false);
                    }}
                    closableOnClickOutside={false}
                    fullWidth={true}
                    isHideHeader={true}
                >
                    <ExtendedSidebar />
                </Drawer>
            </>
        </Drawer>
    );
};
