import React from 'react';
import { UIModalV3 } from '@kaya/kaya-ui-design-system-pb';
import { useChatContentContext } from '../../../contexts';
import { FactCheckResponseContainer } from '../fact-check-response-container';

export const FactCheckViewerModal = () => {
    const { isGoogleFactCheckModalOpen, setIsGoogleFactCheckModalOpen, googleFactCheckContent } =
        useChatContentContext();

    return (
        <UIModalV3
            header={
                <div className="flex items-center gap-x-2">
                    <i className="ri-article-line text-md" />
                    <p className="text-N-600 font-500 text-md">Fact Check Analysis</p>
                </div>
            }
            isOpen={isGoogleFactCheckModalOpen}
            onClose={() => setIsGoogleFactCheckModalOpen(false)}
            setOpen={() => setIsGoogleFactCheckModalOpen(false)}
            width="1080px"
            boxedBody
        >
            <FactCheckResponseContainer
                previousResponse={String(googleFactCheckContent?.previousMessage)}
                previousMessageID={String(googleFactCheckContent?.previousMessageId)}
            />
        </UIModalV3>
    );
};
