import { Button, Drawer } from '@kaya/kaya-ui-design-system-pb';
import { KPLView } from '../kpl-view/KPLView';
import { useMobile } from '../../../../../hooks';

interface IKPLViewContainerProps {
    isKplViewModalOpen: boolean;
    setKPLViewModalOpen: Function;
    selectedKPLKey: string;
}

export const KPLViewContainer = ({
    isKplViewModalOpen,
    setKPLViewModalOpen,
    selectedKPLKey,
}: IKPLViewContainerProps) => {
    const isMobile = useMobile();

    return (
        <Drawer
            closableOnClickOutside={false}
            isOpen={isKplViewModalOpen}
            onClose={() => setKPLViewModalOpen(false)}
            title="View KPL"
            headerIcon="ri-clipboard-line"
            isHideClose={true}
            width={800}
            fullWidth={isMobile}
            footer={
                <div className="flex items-center gap-x-[8px] justify-end">
                    <Button
                        view="outline"
                        appearance="primary"
                        size="medium"
                        onClick={() => {
                            setKPLViewModalOpen(false);
                        }}
                    >
                        Close
                    </Button>
                </div>
            }
        >
            <KPLView selectedKPLKey={selectedKPLKey} />
        </Drawer>
    );
};
