import React from 'react';
import { FeedbackData } from '../../../contexts';
import { CN } from '../../../utils';
import { UploadInput } from '../upload-card';

interface FeedbackFileUploadProps {
    feedback: FeedbackData;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FeedbackFileUpload = ({ feedback, handleFileChange }: FeedbackFileUploadProps) => {
    return (
        <label
            className={CN('flex flex-col items-center text-N-300 cursor-pointer hover:text-white', {
                'text-white': !!feedback.file,
            })}
        >
            <div className={CN('cursor-default ', { 'my-1 p-1 border border-N-200': !!feedback.file })}>
                {feedback.file && (
                    <img
                        src={URL.createObjectURL(feedback.file)}
                        alt="Uploaded screenshot"
                        className="w-full max-h-[200px] "
                    />
                )}
            </div>
            <UploadInput
                label={feedback.file ? 'Change Screenshot' : 'Upload Screenshot'}
                handleFileChange={handleFileChange}
                acceptedFiles="image/*"
            />
        </label>
    );
};

export { FeedbackFileUpload };
