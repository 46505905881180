import { useMutation } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import { FeatureType, MessageAuthor } from '../../../__generated__/graphql';
import { SYSTEM_TEMP_ID } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { UPLOAD_TIMESHEETS } from '../../../graphql';
import { logger, uniqueId } from '../../../utils';
import { FormattedChatMessage } from '../message';
import { UploadButton, UploadCard, UploadInput } from '../upload-card';

const TimesheetUpload = () => {
    const { userDetails, appConfig, setLoading, setMessages, featureType } = useChatContext();
    const [timesheetFile, setTimesheetFile] = useState<File | null>(null);
    const [timesheetUploaded, setTimesheetUploaded] = useState(false);
    const [uploadTimesheet, { loading }] = useMutation(UPLOAD_TIMESHEETS);
    const instructions =
        appConfig?.find(config => config?.configKey === FeatureType.AddTimesheet)?.metadata?.welcomeMessage || '';

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;
        setTimesheetFile(selectedFile);
        setTimesheetUploaded(true);
        e.target.value = '';
    };

    const handleAddToChat = async () => {
        try {
            setLoading(true);
            const { data } = await uploadTimesheet({
                variables: {
                    input: {
                        file: timesheetFile,
                        userId: userDetails.id,
                        feature: featureType,
                        fullname: userDetails.name,
                    },
                },
            });

            if (data?.uploadTimesheet) {
                setMessages(messages => [
                    ...messages,
                    {
                        id: `${SYSTEM_TEMP_ID}_${uniqueId()}`,
                        author: MessageAuthor.System,
                        content: String(data?.uploadTimesheet),
                    },
                ]);
            }
        } catch (error) {
            logger.error(error);
        } finally {
            setLoading(false);
            setTimesheetUploaded(false);
        }
    };

    const handleRemoveFile = () => {
        setTimesheetFile(null);
    };

    return (
        <div className="max-w-[500px]">
            <FormattedChatMessage text={String(instructions)} />
            <div className="my-2 w-full">
                <UploadInput
                    label={timesheetFile ? 'Re-Upload Task Sheet' : 'Upload Task Sheet'}
                    handleFileChange={handleFileChange}
                    acceptedFiles=".xlsx"
                />
            </div>
            {timesheetFile && (
                <>
                    <div className="py-1 text-sm add-timesheet">
                        <UploadCard file={timesheetFile} handleRemove={() => handleRemoveFile()} hasRemove />
                    </div>
                    <UploadButton
                        label={!timesheetUploaded ? 'Task Sheet added' : 'Add Task Sheet'}
                        loading={loading}
                        disabled={loading || !timesheetFile}
                        onClick={handleAddToChat}
                    />
                </>
            )}
        </div>
    );
};

export { TimesheetUpload };
