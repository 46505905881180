import { useEffect, useState } from 'react';

type LoadingStates = {
    business: boolean;
    businessJira: boolean;
    technical: boolean;
    technicalJira: boolean;
    impactAnalysis: boolean;
    [key: string]: boolean;
};

type LoadingMessages = {
    [key in keyof LoadingStates]: {
        loading: string[];
        success: string;
    };
};

interface LoadingState {
    currentMessage: string;
    showLoadingText: boolean;
    progress: number;
}

const calculateProgress = (currentIndex: number, totalMessages: number) => {
    return Math.round(((currentIndex + 1) / totalMessages) * 100);
};

const useLoadingMessages = (requirementLoading: LoadingStates, MESSAGES: LoadingMessages): LoadingState => {
    const [loadingState, setLoadingState] = useState<LoadingState>({
        currentMessage: '',
        showLoadingText: false,
        progress: 10, // Initialize progress to 10%
    });

    useEffect(() => {
        const activeLoadingKeys = Object.keys(requirementLoading).filter(key => requirementLoading[key]);

        if (activeLoadingKeys.length > 0) {
            const activeKey = activeLoadingKeys[0];
            const timeout = activeKey === 'businessJira' || activeKey === 'technicalJira' ? 10000 : 3000;

            let currentIndex = 0;
            setLoadingState({
                currentMessage: MESSAGES[activeKey].loading[currentIndex],
                showLoadingText: true,
                progress: calculateProgress(currentIndex, MESSAGES[activeKey].loading.length), // Set initial progress
            });

            const interval = setInterval(() => {
                if (currentIndex < MESSAGES[activeKey].loading.length - 1) {
                    currentIndex++;
                    setLoadingState(prevState => ({
                        ...prevState,
                        currentMessage: MESSAGES[activeKey].loading[currentIndex],
                        progress: calculateProgress(currentIndex, MESSAGES[activeKey].loading.length), // Update progress
                    }));
                }
            }, timeout);

            return () => clearInterval(interval);
        } else {
            setLoadingState({ currentMessage: '', showLoadingText: false, progress: 10 });
        }
    }, [requirementLoading, MESSAGES]);

    return loadingState;
};

export { useLoadingMessages };
