import { SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { ALL_VALUE, findModelType, MODEL_TYPES, NOT_AVAILABLE_VALUE } from '../../../constants';

export interface IModelSelectProps {
    onChange: Function;
    hasEmptyOption?: boolean;
    value?: string | null;
    hasAllOption?: boolean;
    disabled?: boolean;
}

export const ModelSelect = ({ onChange, hasEmptyOption, hasAllOption, value, disabled = false }: IModelSelectProps) => {
    const [currentModel, setCurrentModel] = useState<string>(
        value === null || value === undefined
            ? hasAllOption
                ? ALL_VALUE
                : hasEmptyOption
                  ? NOT_AVAILABLE_VALUE
                  : MODEL_TYPES[0].name
            : value
    );

    let ALL_MODELS = MODEL_TYPES.map(modelType => modelType.name);

    if (hasEmptyOption) {
        ALL_MODELS = [NOT_AVAILABLE_VALUE, ...ALL_MODELS];
    }

    if (hasAllOption) {
        ALL_MODELS = [ALL_VALUE, ...ALL_MODELS];
    }

    useEffect(() => {
        if (value === ALL_VALUE) {
            setCurrentModel(value);
        }

        if (value === null || value === undefined) {
            setCurrentModel(hasAllOption ? ALL_VALUE : hasEmptyOption ? NOT_AVAILABLE_VALUE : MODEL_TYPES[0].name);
        }
    }, [value]);

    return (
        <SearchInput
            value={currentModel}
            onChange={(value: string) => {
                const name = value;
                const model = value === NOT_AVAILABLE_VALUE || value === ALL_VALUE ? null : findModelType(value);
                setCurrentModel(name);
                onChange({ name, model });
            }}
            placeholder="Select Modal"
            view="default"
            wrapperClassName="w-full"
            items={ALL_MODELS}
            disabled={disabled}
        />
    );
};
