import { DropdownItemProps } from '@kaya/kaya-ui-design-system-pb/dist/src/components/atoms/Dropdown/DropdownItem';
import { FeatureType, MessageAuthor } from '../__generated__/graphql';
import { SYSTEM_MODEL_TEMP_ID } from '../constants';
import { useChatContext } from '../contexts';
import { getCurrentTime, getFilteredModelsByUser, uniqueId } from '../utils';

const useChatHeader = () => {
    const {
        currentUserConfig,
        messages,
        settings,
        setMessages,
        setSettings,
        currentAppConfig,
        conversation,
        featureType,
    } = useChatContext();

    const title = conversation?.title || 'New Chat';
    const label = currentAppConfig?.title || FeatureType.General;
    const updatedDate = conversation?.updatedAt || new Date().toISOString();
    const date = updatedDate.split('T')[0];
    const modelTypes = getFilteredModelsByUser(currentUserConfig);
    const currentModelImage = modelTypes.find(model => model.model === settings.model)?.imageURL;
    const description =
        currentAppConfig?.metadata.welcomeMessage ||
        `Welcome to ${label} KPL 🎉! Explore anything with our comprehensive knowledge base.`;
    const currentSelectedModel = modelTypes.find(model => model.model === settings.model)?.model;

    const handleModelChange = (model: string, name: string) => {
        const content = name;
        const currentTime = getCurrentTime();

        setSettings(s => ({ ...s, model }));

        const modelChangeMessage = {
            id: `${SYSTEM_MODEL_TEMP_ID}_${uniqueId()}`,
            content: content,
            author: MessageAuthor.System,
            time: currentTime,
        };

        const lastMessage = messages[messages.length - 1];

        if (lastMessage?.id?.includes(SYSTEM_MODEL_TEMP_ID)) {
            lastMessage.content = content;
            lastMessage.time = currentTime;
            setMessages(prevMessages => [...prevMessages]);
        } else {
            setMessages(prevMessages => [...prevMessages, modelChangeMessage]);
        }
    };

    const dropDownModels: DropdownItemProps[] = modelTypes.map((modelType, i) => {
        const { model, name, imageURL } = modelType;

        return {
            id: i + 1,
            label: name,
            leadingNodeType: 'avatar',
            avatarProps: {
                size: 'md',
                type: 'image',
                imageURL,
            },
            onClick: () => {
                handleModelChange(model, name);
            },
        };
    });

    return {
        title,
        label,
        date,
        currentModelImage,
        modelTypes,
        dropDownModels,
        description,
        featureType,
        currentSelectedModel,
    };
};

export { useChatHeader };
