import { Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { ReactNode } from 'react';
import { uniqueId } from '../../../utils';

type IButtonWithToogle = {
    children: ReactNode;
    showTooltip?: boolean;
    title: string;
};

export const ButtonWithTooltip = ({ children, showTooltip, title }: IButtonWithToogle) => {
    return (
        <>
            {showTooltip ? (
                <Tooltip id={uniqueId()} place="right" variant="dark" tooltipType="detailed" title={title}>
                    {children}
                </Tooltip>
            ) : (
                children
            )}
        </>
    );
};
