import { base64toBlob } from './utils';

interface IPDFReaderProps {
    base64PDF: string;
    pageIndex: number;
}

export const PDFReader = ({ base64PDF, pageIndex }: IPDFReaderProps) => {
    const blob = base64toBlob(base64PDF);
    const pdfURL = URL.createObjectURL(blob);

    return (
        <iframe
            src={`${pdfURL}#page=${pageIndex}`}
            style={{
                width: '100%',
                height: '100%',
            }}
        />
    );
};
