import { IMG_KAYA_MIC_DEFAULT_GIF, IMG_KAYA_MIC_ON_GIF } from '../../../constants';
import { IconButton } from '.';

type ISpeechButton = {
    triggerMic: () => void;
    isTimesheet: boolean;
    browserSupport: boolean;
    listening: boolean;
};

const SpeechButton = ({ browserSupport, listening, triggerMic, isTimesheet }: ISpeechButton) => {
    if (!browserSupport) {
        return <i className="ri-volume-mute-line" title="Mic not supported!"></i>;
    }

    return isTimesheet ? (
        <img
            src={listening ? IMG_KAYA_MIC_ON_GIF : IMG_KAYA_MIC_DEFAULT_GIF}
            width={45}
            height={45}
            onClick={triggerMic}
        />
    ) : (
        <IconButton icon={listening ? 'ri-mic-line' : 'ri-mic-off-line text-lg'} onClick={triggerMic} />
    );
};

export { SpeechButton };
