import '@kaya/kaya-ui-design-system-pb/dist/style.css';
import 'remixicon/fonts/remixicon.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { ChatbotApp } from './lib';

function App() {
    return <ChatbotApp />;
}

export default App;
