import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { useCustomKplContext } from '../../../../../../contexts';
import { ChatMessage, CommonMessage } from '../../../../message';

export const KplPromptResponse = () => {
    const { tempPromptInstructions, enhancedPromptInstructions, promptEnhanceLoading } = useCustomKplContext();

    const processText = (input: string): string => {
        const parts = input.split('<hr>');

        if (parts.length === 1) {
            return input;
        } else if (parts.length === 2) {
            return parts[1].trim();
        } else {
            return parts
                .slice(1, parts.length - 1)
                .join('<hr>')
                .trim();
        }
    };

    return (
        <div>
            <CommonMessage
                isUser
                disableCopy
                text=""
                content={
                    <span>
                        {tempPromptInstructions.split('\n').map((item, index) => (
                            <span key={index}>
                                {item}
                                <br />
                            </span>
                        ))}
                        <br />
                    </span>
                }
            />
            <CommonMessage
                disableCopy
                text={``}
                content={
                    <div className="flex flex-col h-full items-start gap-y-2">
                        <span>
                            Perfect!
                            <br />
                            Bernie will structure the response according to the given structure.
                        </span>
                        {promptEnhanceLoading && <SpinLoader />}
                    </div>
                }
            />
            {enhancedPromptInstructions && (
                <>
                    <ChatMessage disableCopy text={processText(enhancedPromptInstructions)} />
                    <ChatMessage
                        disableCopy
                        text="You have the ability to edit this improved response prompt by navigating to the Custom KPL -> Configure -> Response Prompt Instructions, once you create this custom KPL."
                    />
                </>
            )}
        </div>
    );
};
