import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { JiraProjects } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { GENERATE_SPRINT, GER_JIRA_SETTINGS } from '../../../graphql';
import { downloadSprintAnalysisAsPDF } from '../../../services';
import { logger } from '../../../utils';
import { useMessageContent } from '../../../hooks';

const useSprintAnalysisReport = () => {
    const [getJiraSettings, { loading: projectsLoading }] = useLazyQuery(GER_JIRA_SETTINGS);
    const [generateSprintAnalysisReport] = useMutation(GENERATE_SPRINT);

    const { containerRef, showScrollButton, scrollToBottom } = useMessageContent();
    const { userDetails, userFirstName, jiraOptions } = useChatContext();
    const [loadSprint, setLoadSprint] = useState({ sprint: false });
    const [jiraProjects, setJiraProjects] = useState<JiraProjects[]>([]);
    const [pdfGenerated, setPdfGenerated] = useState(false);
    const [selectedJiraProject, setSelectedJiraProject] = useState('');
    const [selectedJiraSprint, setSelectedJiraSprint] = useState('');
    const [openSprintAnalysisModal, setOpenSprintAnalysisModal] = useState(false);
    const [sprintOverview, setSprintOverview] = useState('');
    const [report, setReport] = useState('');
    const [openJira, setOpenJira] = useState(false);

    useEffect(() => {
        scrollToBottom();
    }, [sprintOverview, pdfGenerated]);

    useEffect(() => {
        const fetchJiraSettings = async () => {
            if (jiraOptions && userDetails.id) {
                const { data } = await getJiraSettings({
                    variables: {
                        input: {
                            userId: userDetails.id,
                            jiraUserEmail: jiraOptions.jiraUserEmail,
                            jiraUserToken: jiraOptions.jiraUserToken,
                            jiraInstanceName: jiraOptions.jiraInstanceName,
                            jiraProjectKey: jiraOptions.jiraProjectKey,
                        },
                    },
                });

                const projects: JiraProjects[] =
                    data?.getJiraSettings?.map(jira => ({
                        id: jira?.id as string,
                        name: jira?.name as string,
                        key: jira?.key as string,
                        sprints: jira?.sprints,
                    })) || [];

                setJiraProjects(projects);
            }
        };

        fetchJiraSettings();
    }, [jiraOptions, userDetails, getJiraSettings]);

    const onAnalyzeSprint = async () => {
        try {
            setLoadSprint({ sprint: true });
            setReport('');
            setSprintOverview('');

            const { data } = await generateSprintAnalysisReport({
                variables: {
                    input: {
                        project: selectedJiraProject,
                        sprint: selectedJiraSprint,
                        userId: userDetails.id,
                        jiraUserEmail: jiraOptions.jiraUserEmail,
                        jiraUserToken: jiraOptions.jiraUserToken,
                        jiraInstanceName: jiraOptions.jiraInstanceName,
                        jiraProjectKey: jiraOptions.jiraProjectKey,
                    },
                },
            });

            setReport(data?.generateSprintAnalysisReport?.report ?? '');
            setSprintOverview(data?.generateSprintAnalysisReport?.overview ?? '');
        } catch (error) {
            logger.log(error);
        } finally {
            setLoadSprint({ sprint: false });
        }
    };

    const onReviewAnalysisReport = () => {
        setOpenSprintAnalysisModal(true);
    };

    const onDownloadPDF = () => {
        downloadSprintAnalysisAsPDF(report, jiraOptions);
    };

    const onModalClose = (value: string) => {
        setReport(value);
        setOpenSprintAnalysisModal(false);
        setPdfGenerated(true);
    };

    return {
        containerRef,
        showScrollButton,
        userFirstName,
        selectedJiraProject,
        selectedJiraSprint,
        loadSprint,
        report,
        openSprintAnalysisModal,
        jiraProjects,
        sprintOverview,
        pdfGenerated,
        openJira,
        projectsLoading,
        scrollToBottom,
        setOpenJira,
        setSelectedJiraSprint,
        setSelectedJiraProject,
        onAnalyzeSprint,
        onReviewAnalysisReport,
        onDownloadPDF,
        onModalClose,
    };
};

export { useSprintAnalysisReport };
