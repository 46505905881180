type GuidelineItemProps = {
    question: string;
    disabled?: boolean;
    onClick: Function;
};

const GuidelineItem = ({ question, disabled, onClick }: GuidelineItemProps) => (
    <button
        disabled={disabled}
        onClick={() => onClick()}
        className="flex justify-between cursor-pointer bg-transparent text-white hover:border-N-100 my-2 py-2 px-4 border border-N-400 rounded-md w-full text-left hoverEffect disabled:bg-N-500 disabled:cursor-not-allowed"
    >
        <span className="p-1">{question}</span>
        <i className="ri-send-plane-line p-1"></i>
    </button>
);

export { GuidelineItem };
