import { useLazyQuery } from '@apollo/client';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { FeatureType } from '../__generated__/graphql';
import { Conversation, useChatContext, useSideBarContext } from '../contexts';
import { GET_USER } from '../graphql';
import { useMobile } from './useMobile';

const useChatHistory = () => {
    const { userDetails, appConfig, conversationId, handleConversationSelection } = useChatContext();
    const { isCollapsed, handleMobileMenuIconClick } = useSideBarContext();
    const [getUser, { data, loading, refetch }] = useLazyQuery(GET_USER);
    const [value, setValue] = useState('');
    const [debounced] = useDebouncedValue(value, 200);

    const isMobile = useMobile();

    useEffect(() => {
        const loadConversations = async () => {
            if (userDetails.id) {
                await getUser({ variables: { userId: userDetails.id } });
            }
        };

        loadConversations();
    }, [getUser, userDetails]);

    const groupedConversations = useMemo(() => {
        if (!data?.getParticipant?.conversations) {
            return [];
        }

        const debouncedLowerCase = debounced.toLowerCase();

        // Group conversations by date
        const groupedConversations = data.getParticipant.conversations
            .filter(conversation => conversation?.title?.toLowerCase().includes(debouncedLowerCase))
            .reduce(
                (acc, conversation) => {
                    const date = String(conversation?.updatedAt).split('T')[0];
                    const threadType = conversation?.threadType || FeatureType.General;
                    const threadConfig = appConfig?.find(config => config?.configKey === threadType) || appConfig[0];

                    acc[date] = acc[date] || [];
                    acc[date].push({
                        id: String(conversation?.id),
                        title: String(conversation?.title),
                        feature: threadConfig,
                        updatedAt: String(conversation?.updatedAt),
                    });

                    return acc;
                },
                {} as { [date: string]: Conversation[] }
            );

        // Convert the groupedConversations object to an array
        return Object.entries(groupedConversations).map(([date, conversations]) => ({
            date,
            conversations,
        }));
    }, [data, debounced]);

    const count = groupedConversations.reduce((acc, { conversations }) => acc + conversations.length, 0);

    const onConversationUpdate = async (conversation: Conversation) => {
        await refetch();
        handleConversationSelection(conversation);
    };

    return {
        count,
        groupedConversations,
        value,
        loading,
        conversationId,
        handleMobileMenuIconClick,
        isCollapsed,
        isMobile,
        setValue,
        fetchConversations: refetch,
        onConversationUpdate,
        handleConversationSelection,
    };
};

export { useChatHistory };
