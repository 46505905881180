import { useClipboard } from '@mantine/hooks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

type CodeProps = {
    children: string;
    language?: string;
};

const syntaxHighlighterStyles = /* CSS */ `
    code {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    pre {
        overflow: overlay;

        &::-webkit-scrollbar {
            height: 10px;
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(96, 105, 110, 0.718);
            border-radius: 0.5rem;
        }
    }
`;

const Code = ({ children, language }: CodeProps) => {
    const { copy, copied } = useClipboard({ timeout: 2000 });

    if (!children) return null;

    return (
        <div className="w-full rounded-md mt-[1em]">
            <div className="lang bg-[#dddddd] h-8 z-50 px-2 mt-2 rounded-md flex flex-row justify-between items-center">
                <span className="text-sm font-code">{language || 'Code'}</span>
                <div
                    className="flex flex-row items-center gap-1 cursor-pointer font-mono"
                    onClick={() => copy(children)}
                >
                    <i className={copied ? 'ri-checkbox-circle-line' : 'ri-file-copy-2-line'}></i>
                    <span>{copied ? 'Copied' : 'Copy'}</span>
                </div>
            </div>
            <div className="z-10">
                <style>{syntaxHighlighterStyles}</style>
                <SyntaxHighlighter wrapLines={true} language={language ?? 'other'} style={atomDark}>
                    {children}
                </SyntaxHighlighter>
            </div>
        </div>
    );
};

export { Code };
