import { Button, TextField } from '@kaya/kaya-ui-design-system-pb';
import { KnowledgeDocument } from './common/knowledge-document/KnowledgeDocument';
import { useEffect, useRef, useState } from 'react';
import { useCustomKplContext } from '../../../../../contexts';
import { ConversationStarters } from './common/conversation-starters/ConversationStarters';
import { CN } from '../../../../../utils';

export const CustomKplConfigure = () => {
    const fileRef = useRef<HTMLInputElement | null>(null);

    const {
        files,
        setFiles,
        name,
        setName,
        promptInstructions,
        setPromptInstructions,
        isLoading,
        handleUpdate,
        handlePromptEnhancement,
        promptEnhanceLoading,
        fetchConversationStarters,
        isQuestionGenerated,
        headerMetaDataList,
        setRemovedFiles,
        setUpdatedFiles,
        isQuestionLoading,
        fetchSavedKplData,
        isFormEdited,
        setIsFormEdited,
    } = useCustomKplContext();

    const [warningMessage, setWarningMessage] = useState<string>('');
    const [isFileLimitationValid, setIsFileLimitationValid] = useState<boolean>(true);
    const [isUploadBtnDisable, setIsUploadBtnDisable] = useState<boolean>(false);

    const handleUpload = () => {
        const fileInput = fileRef?.current;

        if (fileInput) {
            fileInput?.click();
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files?.length) return null;

        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const newFiles = Array.from(selectedFiles);
            setFiles((prevFiles: File[]) => [...prevFiles, ...newFiles]);
            setUpdatedFiles((prevUpdatedFiles: File[]) => [...prevUpdatedFiles, ...newFiles]);
            setIsFormEdited(true);
        }
    };

    const onRemoveFileSave = (removedFile: any) => {
        setRemovedFiles((prevRemovedFiles: string[]) => [...prevRemovedFiles, removedFile.id]);
    };

    const onClose = (index: number) => {
        setFiles((prevFiles: File[]) => {
            const removedFile = prevFiles[index];
            onRemoveFileSave(removedFile);
            return prevFiles.filter((_, i) => i !== index);
        });
        setIsFormEdited(true);
    };

    useEffect(() => {
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);

        if (totalSize > headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_SIZE_LIMIT * 1024 * 1024) {
            setWarningMessage(
                `Total file size exceeds the maximum limit of ${headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_SIZE_LIMIT} MB.`
            );
            setIsFileLimitationValid(false);
        } else if (totalSize === headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_SIZE_LIMIT * 1024 * 1024) {
            setWarningMessage('');
            setIsUploadBtnDisable(true);
            setIsFileLimitationValid(true);
        } else if (files.length > headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_COUNT_LIMIT) {
            setWarningMessage(
                `You can only upload a maximum of ${headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_COUNT_LIMIT} files.`
            );
            setIsFileLimitationValid(false);
        } else if (files.length === headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_COUNT_LIMIT) {
            setWarningMessage('');
            setIsUploadBtnDisable(true);
            setIsFileLimitationValid(true);
        } else {
            setWarningMessage('');
            setIsFileLimitationValid(true);
            setIsUploadBtnDisable(false);
        }
    }, [files, headerMetaDataList]);

    useEffect(() => {
        fetchSavedKplData();
    }, []);

    return (
        <div className="h-auto w-[800px] md:w-full flex flex-col gap-y-5 items-start p-4">
            <div className='"flex flex-col gap-y-1 w-full items-end"'>
                <TextField
                    step="1"
                    view="default"
                    label="Name"
                    wrapperClassName="w-full"
                    placeholder="Name"
                    value={name}
                    onChange={(e: any) => {
                        setName(e?.target?.value);
                        setIsFormEdited(true);
                    }}
                    type="text"
                    autoComplete="nope"
                    className="h-[45px] min-w-[216px]"
                    required={true}
                />
            </div>

            <div className="flex flex-col gap-y-[4px] w-full">
                <div className="flex items-center justify-between">
                    <span className='text-xs text-N-900 font-500 flex items-center w-full cursor-default after:content-["*"] after:ml-[2px] after:text-R-500 after:inline-flex after:items-center after:mt-[4px] after:leading-[0] justify-start'>
                        Response Prompt Instructions
                    </span>
                </div>
                <TextField
                    step="1"
                    view="default"
                    wrapperClassName="w-full"
                    placeholder="Response Prompt Instructions"
                    type="text"
                    autoComplete="nope"
                    className="min-h-[235px]"
                    rows={10}
                    onChange={(e: any) => {
                        setPromptInstructions(e?.target?.value);
                        setIsFormEdited(true);
                    }}
                    required={true}
                    value={promptInstructions}
                />
                <div className="flex justify-end items-center">
                    <Button
                        size="medium"
                        view="outline"
                        appearance="primaryBlack"
                        className={CN(
                            'text-sm font-500 text-N-900 cursor-pointer hover:text-[#1D5BD7] hover:font-bold flex'
                        )}
                        iconBefore={promptEnhanceLoading ? '' : 'ri-sparkling-2-fill'}
                        onClick={() => {
                            !promptEnhanceLoading ? handlePromptEnhancement(promptInstructions) : () => {};
                            setIsFormEdited(true);
                        }}
                        isLoading={promptEnhanceLoading}
                    >
                        <span className="text-nowrap">Enhance prompt</span>
                    </Button>
                </div>
            </div>
            <div className="flex flex-col gap-3 w-full">
                <span className="text-xs text-N-900 font-500 w-full cursor-default">Knowledge</span>
                <div className="flex flex-col gap-2">
                    <div className="flex gap-3 w-full flex-wrap">
                        {files?.length ? (
                            files.map((file, i) => (
                                <KnowledgeDocument
                                    name={file?.name}
                                    key={i}
                                    onClose={() => onClose(i)}
                                    fileSize={file?.size}
                                />
                            ))
                        ) : (
                            <span className=" text-A-600">No files uploaded. Please select files to upload.</span>
                        )}
                    </div>
                    <p className="mt-2 text-xs text-R-600">
                        <i>{warningMessage && warningMessage}</i>
                    </p>

                    <div className="flex gap-x-4 gap-y-2 flex-wrap items-start flex-row">
                        <div className="flex items-center gap-2">
                            <div className="text-xs">
                                <span className="text-N-700 font-medium">Knowledge Document Used:</span>
                                <span className="ml-1">
                                    {(headerMetaDataList?.totalDocumentSize / (1024 * 1024)).toFixed(2)}/
                                    {headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_SIZE_LIMIT} MB
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-xs">
                                <span className="text-N-700 font-medium">Documents ingested:</span>
                                <span className="ml-1">
                                    {headerMetaDataList?.totalDocumentCount}/
                                    {headerMetaDataList?.metaDataconfigs?.GUEST_DOCUMENT_COUNT_LIMIT}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <Button
                            appearance="primary"
                            size="medium"
                            view="outline"
                            onClick={handleUpload}
                            isDisabled={!isFileLimitationValid || isUploadBtnDisable}
                            iconBefore="ri-upload-line"
                        >
                            Upload
                        </Button>
                        <input
                            type="file"
                            ref={fileRef}
                            multiple
                            className="hidden"
                            onChange={handleFileSelect}
                            accept=".txt,.docx,.pdf,.xlsx,.csv"
                        />
                        <div className="flex items-center gap-x-3">
                            <Button
                                appearance="primary"
                                size="medium"
                                view="solid"
                                onClick={() => handleUpdate()}
                                isLoading={isLoading}
                                isDisabled={
                                    promptEnhanceLoading || isLoading || !isFileLimitationValid || !isFormEdited
                                }
                            >
                                Save changes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-2 w-full">
                <hr className="text-N-300 font-400 my-5 " />

                <span className="text-xs text-N-900 font-500 w-full cursor-default">
                    Generate Conversation Starters
                </span>
                <Button
                    appearance="primary"
                    size="small"
                    view="solid"
                    onClick={() => fetchConversationStarters()}
                    className={CN('w-[120px]')}
                    isDisabled={isQuestionGenerated}
                    isLoading={isQuestionLoading}
                >
                    Generate
                </Button>
                <div className="w-full border border-dashed p-4 flex justify-center items-center min-h-[200px] text-center">
                    <ConversationStarters />
                </div>
            </div>
        </div>
    );
};
