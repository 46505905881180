import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { DEMO_MSG_MAESTRO_COMPANY_DETAILS } from '../../../../constants';

interface LeadCompanySelectorProps {
    UsersLoading: boolean;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    selectedValue?: string;
    placeholder?: string;
}

const LeadCompanySelector: React.FC<LeadCompanySelectorProps> = ({
    UsersLoading,
    disabled = false,
    onChange,
    selectedValue = '',
    placeholder = 'Select Lead Name',
}) => {
    return (
        <>
            <div className="w-full flex justify-center mt-2">
                {UsersLoading ? (
                    <SpinLoader color="#384151" />
                ) : (
                    <select
                        disabled={disabled}
                        name="user"
                        id="user-select"
                        className="mb-2 p-2 text-N-600 w-full"
                        onChange={onChange}
                        value={selectedValue}
                    >
                        <option value="">{placeholder}</option>
                        {DEMO_MSG_MAESTRO_COMPANY_DETAILS.map((user, i) => (
                            <option key={i} value={user.id}>
                                {user.name}
                            </option>
                        ))}
                    </select>
                )}
            </div>
        </>
    );
};

export default LeadCompanySelector;
