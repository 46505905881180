import { Accordion, SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { ChatMessage, CommonMessage, FormattedChatMessage, JiraSettingsModal, ScrollableWrapper } from '../../common';
import { SprintAnalysisModal } from './SprintAnalysisModal';
import { useLoadingMessages } from './useLoadingMessages';
import { useSprintAnalysisReport } from './useSprintAnalysisReport';
import { ScrollDownButton } from '../../common/buttons';

const SPRINT_MESSAGE = {
    sprint: {
        initial: 'Analyze Sprint 📊',
        loading: [
            'Establishing connection with JIRA 🔗',
            'Fetching Sprint stories from JIRA 📂',
            'Fetching Sprint tasks from JIRA 📂',
            'Fetching subtasks from JIRA 📂',
            'Analyzing sprint data 📈',
            'Generating sprint analysis report 📄',
        ],
        success: 'Sprint Analyzed ✅',
    },
};

const SprintAnalysisReport = () => {
    const {
        containerRef,
        showScrollButton,
        userFirstName,
        selectedJiraProject,
        selectedJiraSprint,
        loadSprint,
        report,
        openSprintAnalysisModal,
        jiraProjects,
        sprintOverview,
        pdfGenerated,
        openJira,
        projectsLoading,
        scrollToBottom,
        setOpenJira,
        setSelectedJiraSprint,
        setSelectedJiraProject,
        onAnalyzeSprint,
        onReviewAnalysisReport,
        onDownloadPDF,
        onModalClose,
    } = useSprintAnalysisReport();

    const { currentMessage, showLoadingText, progress } = useLoadingMessages(loadSprint, SPRINT_MESSAGE);
    const disableButton = !selectedJiraProject || !selectedJiraSprint || showLoadingText || pdfGenerated;

    return (
        <ScrollableWrapper ref={containerRef}>
            <ChatMessage
                disableCopy
                text={`Hi, ${userFirstName} 👋
                \n Welcome to "Measure Twice, Cut Once," the latest feature in Bernie designed to supercharge your sprint planning and execution!
                \n- **Set Up Your Sprint:** Begin by entering your sprint details and goals.
                \n- **Analyze Performance:** Bernie will analyze your past sprint data and provide actionable insights and metrics.
                \n- **Optimize:** Utilize these insights to make data-driven adjustments to your current and future sprints.
                \n- **Track Progress:** Monitor your team's progress in real-time and adjust as needed to stay on track.
                \n- **Report:** At the end of the sprint, generate comprehensive reports to review performance and plan better for the next cycle.
                \n _Bernie is dedicated to enhancing your project management efficiency, helping you make informed decisions for successful sprints. Let's maximize productivity together!_`}
            />
            <CommonMessage
                isUser
                disableCopy
                text={'Select'}
                content={
                    <>
                        <div className="my-2 text-md">👇 Please select your JIRA settings to proceed</div>
                        <div className="w-full">
                            <label htmlFor="jira-project">JIRA Project:</label>
                            <div className="flex justify-center">
                                {projectsLoading ? (
                                    <SpinLoader color="#384151" />
                                ) : (
                                    <select
                                        name="project"
                                        id="jira-project"
                                        className="my-2 p-2 text-N-600 w-full"
                                        value={selectedJiraProject}
                                        onChange={e => {
                                            setSelectedJiraProject(e.target.value);
                                            setSelectedJiraSprint('');
                                        }}
                                    >
                                        <option className="rounded-none" value="">
                                            Select Project
                                        </option>
                                        {jiraProjects.map((project, i) => (
                                            <option key={i} value={String(project.key)}>
                                                <div className="py-2">{String(project.name)}</div>
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        </div>
                        {selectedJiraProject && (
                            <div className="w-full">
                                <label htmlFor="jira-sprint">JIRA Sprint:</label>
                                <select
                                    name="sprint"
                                    id="jira-sprint"
                                    className="my-2 p-2 text-N-600 w-full"
                                    value={selectedJiraSprint}
                                    onChange={e => setSelectedJiraSprint(e.target.value)}
                                >
                                    <option value="">Select Sprint</option>
                                    {jiraProjects.map(project => {
                                        if (project.key === selectedJiraProject) {
                                            return project?.sprints?.map((sprint, i) => (
                                                <option key={i} value={String(sprint?.id)}>
                                                    {String(sprint?.name)}
                                                </option>
                                            ));
                                        }
                                    })}
                                </select>
                            </div>
                        )}
                        <div className="flex">
                            <button
                                disabled={disableButton}
                                onClick={onAnalyzeSprint}
                                className="bg-B-600 w-full cursor-pointer hover:bg-N-600 my-2 py-2 px-4 text-white border-none rounded-[2px] disabled:bg-N-500 disabled:pointer-events-none"
                            >
                                {pdfGenerated ? SPRINT_MESSAGE.sprint.success : SPRINT_MESSAGE.sprint.initial}
                            </button>
                            <button
                                disabled={pdfGenerated || showLoadingText}
                                title="Edit"
                                className="bg-B-600 hover:bg-N-600 text-white my-2 py-2 px-4 ml-1 rounded-[2px] disabled:bg-N-500 disabled:pointer-events-none"
                                onClick={() => setOpenJira(o => !o)}
                            >
                                <i className="ri-settings-3-line"></i>
                            </button>
                            <JiraSettingsModal hideJiraProjectKey open={openJira} onClose={() => setOpenJira(false)} />
                        </div>
                    </>
                }
            />
            {showLoadingText && (
                <CommonMessage
                    disableCopy
                    text={currentMessage}
                    content={
                        <>
                            <FormattedChatMessage text={currentMessage} />
                            <div className="bg-N-400 rounded-full h-2.5 animate-fade my-1">
                                <div
                                    style={{ width: `${progress}%` }}
                                    className={'bg-B-600 h-2.5 rounded-full animate-pulse'}
                                />
                            </div>
                        </>
                    }
                />
            )}
            {sprintOverview && (
                <CommonMessage
                    disableCopy
                    text="Download PDF"
                    content={
                        <>
                            <FormattedChatMessage text={sprintOverview} />
                            <div className="border border-N-200 w-[80%]">
                                <Accordion
                                    items={[
                                        {
                                            title: 'View analysis report',
                                            content: (
                                                <div className="py-7 px-7">
                                                    <FormattedChatMessage text={report} />
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                            <div className="reference relative">
                                <button
                                    onClick={onReviewAnalysisReport}
                                    className="bg-B-500 hover:bg-B-400 py-2 px-4 text-white border-none float-right rounded-[2px] disabled:bg-N-600 disabled:pointer-events-none"
                                >
                                    Review Analysis Report
                                </button>
                                <SprintAnalysisModal
                                    open={openSprintAnalysisModal}
                                    response={report}
                                    onClose={onModalClose}
                                />
                            </div>
                        </>
                    }
                />
            )}
            {pdfGenerated && (
                <CommonMessage
                    disableCopy
                    text="Download PDF"
                    content={
                        <div>
                            <div className="my-2">👇 Please click on the below button to Download as PDF</div>
                            <button
                                onClick={onDownloadPDF}
                                className="bg-B-500 hover:bg-B-400 py-2 px-4 cursor-pointer text-white border-none float-right rounded-md disabled:bg-N-600 disabled:pointer-events-none w-full"
                            >
                                Download Analysis Report <i className="ri-download-2-line mx-2"></i>
                            </button>
                        </div>
                    }
                />
            )}
            {showScrollButton && <ScrollDownButton scrollToBottom={scrollToBottom} />}
        </ScrollableWrapper>
    );
};

export { SprintAnalysisReport };
