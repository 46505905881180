import { useEffect } from 'react';
import { useChatContext } from '../../contexts';
import { ChatContent } from './ChatContent';

type TChatbotFull = {
    showHeader: boolean;
};

const ChatbotFull = ({ showHeader }: TChatbotFull) => {
    const { setFullscreen } = useChatContext();

    useEffect(() => {
        setFullscreen(true);
    }, [setFullscreen]);

    return (
        <div className="h-[75vh]">
            <ChatContent showHeader={showHeader} />
        </div>
    );
};

export { ChatbotFull };
