import React, { useEffect, useState } from 'react';
import { GET_HIGHLIGHTED_PDF } from '../../../graphql';
import { useMutation } from '@apollo/client';
import { Button, DisplayPDFThumbnail, UIModalV3 } from '@kaya/kaya-ui-design-system-pb';
import { useChatContentContext } from '../../../contexts';
import { PageLoading } from '../full-page-loader';
import { truncate } from '../../../utils';
import { PDFReader } from '../pdf-reader';
import classNames from 'classnames';

export type PDFReferenceType = {
    id: number;
    sourceName: string;
    base64PDF: string;
    pageIndex: number;
    source: string | null;
};

export const ReferencePDFViewerModal = () => {
    const [getHighlightedPDF, { loading }] = useMutation(GET_HIGHLIGHTED_PDF);
    const [pdfThumbnailList, setPdfThumbnailList] = useState<PDFReferenceType[]>([]);
    const [base64PDF, setBase64PDF] = useState<PDFReferenceType>();
    const [selectedPdfTitle, setSelectedPdfTitle] = useState<string>();

    const {
        isPDFReferenceModalOpen,
        setIsPDFReferenceModalOpen,
        getHighlightedPDFReferenceInputValues,
        highlightedPDFReferencesThumbnails,
    } = useChatContentContext();

    const handleClickPDF = () => {
        if (base64PDF?.source !== null) {
            window.open(base64PDF?.source, '_blank');
        }
    };

    useEffect(() => {
        const getAllPDFThumbnails = async () => {
            highlightedPDFReferencesThumbnails?.map((pdfThumbnailReference, index: number) => {
                getHighlightedPDF({
                    variables: {
                        input: {
                            chunk_id: pdfThumbnailReference.sourceId,
                            feature: String(getHighlightedPDFReferenceInputValues?.feature),
                        },
                    },
                }).then(highlightedPDFData => {
                    setPdfThumbnailList(prePdfs => [
                        ...prePdfs,
                        {
                            id: index,
                            sourceName: pdfThumbnailReference.sourceName,
                            base64PDF: String(highlightedPDFData.data?.getHighlightedPDF?.pdf),
                            pageIndex: highlightedPDFData.data?.getHighlightedPDF?.page as number,
                            source: String(highlightedPDFData.data?.getHighlightedPDF?.source),
                        },
                    ]);
                });
            });
        };

        if (isPDFReferenceModalOpen) {
            getAllPDFThumbnails();
        }

        if (!isPDFReferenceModalOpen) {
            setPdfThumbnailList([]);
        }
    }, [isPDFReferenceModalOpen]);

    useEffect(() => {
        const userClickedPDFReference = getHighlightedPDFReferenceInputValues?.sourceName;
        const pdf = pdfThumbnailList.find(
            pdfReference => pdfReference.sourceName.toLowerCase() === userClickedPDFReference?.toLowerCase()
        );
        setSelectedPdfTitle(userClickedPDFReference);
        setBase64PDF(pdf);
    }, [pdfThumbnailList]);

    return (
        <UIModalV3
            header={
                <div className="flex items-center gap-x-12 w-full">
                    <div className="flex items-center gap-x-2">
                        <i className="ri-article-line text-md" />
                        <p className="text-N-600 font-500 text-md">
                            {pdfThumbnailList.length === 0 || loading ? 'Please hold on...' : selectedPdfTitle}
                        </p>
                    </div>
                    {pdfThumbnailList.length > 0 && base64PDF?.source !== 'null' && !loading && (
                        <Button onClick={handleClickPDF} appearance="primary" view="outline" size="small">
                            View Original PDF
                        </Button>
                    )}
                </div>
            }
            isOpen={isPDFReferenceModalOpen}
            onClose={() => {
                setIsPDFReferenceModalOpen(true);
            }}
            setOpen={() => {
                setIsPDFReferenceModalOpen(false);
            }}
            width="1300px"
            boxedBody
        >
            <>
                {loading ? (
                    <div className="h-[85vh] flex items-center justify-center overflow-hidden">
                        <PageLoading title="Please hold on. PDFs are loading..." />
                    </div>
                ) : (
                    <div className="flex gap-x-4 h-[750px] overflow-hidden">
                        <div className="min-h-[600px] flex gap-x-2 overflow-y-scroll overflow-x-hidden scrollable_effect_multi_browser_without_thumb w-[170px]">
                            {pdfThumbnailList.length > 0 && (
                                <div className="flex flex-col gap-y-4">
                                    {pdfThumbnailList.map(pdf => {
                                        return (
                                            <div className="w-[130px] flex flex-col items-center gap-y-1">
                                                <div
                                                    onClick={() => {
                                                        setBase64PDF(pdf);
                                                        setSelectedPdfTitle(pdf.sourceName);
                                                    }}
                                                    className={classNames({
                                                        'p-1 bg-B-200 rounded-sm':
                                                            !loading && base64PDF && base64PDF.id === pdf.id,
                                                    })}
                                                >
                                                    <DisplayPDFThumbnail
                                                        fileUrl={`data:application/pdf;base64,${pdf.base64PDF}`}
                                                        pageIndex={pdf.pageIndex - 1}
                                                    />
                                                </div>
                                                <p className="flex text-xs text-N-400" title={pdf.sourceName}>
                                                    {truncate(pdf.sourceName, 18)}...
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="w-full h-full">
                            {base64PDF !== undefined && (
                                <PDFReader
                                    base64PDF={`data:application/pdf;base64,${base64PDF.base64PDF}`}
                                    pageIndex={base64PDF.pageIndex}
                                />
                            )}
                        </div>
                    </div>
                )}
            </>
        </UIModalV3>
    );
};
