import React, { useState } from 'react';
import { CN, truncate } from '../../../utils';

interface TranscriptionUploadProps {
    onFileUpload: (selectedFile: File) => void;
}

const TranscriptionUpload = ({ onFileUpload }: TranscriptionUploadProps) => {
    const [transcription, setTranscription] = useState<File | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];

        if (selectedFile) {
            setTranscription(selectedFile);
            onFileUpload(selectedFile);
        }
    };

    const handleGenerationPrompt = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setTranscription(null);
    };

    return (
        <label
            className={CN('flex flex-col items-center text-B-600 cursor-pointer hover:text-B-800', {
                'text-white': !!transcription,
            })}
        >
            {transcription ? (
                <>
                    <div
                        className={CN('cursor-default text-B-900', {
                            'my-1 p-2 border border-B-200 bg-B-50 rounded-sm': !!transcription,
                        })}
                    >
                        <div className="flex items-center gap-1">
                            <i className="ri-article-line"></i>
                            <span className="mr-1">{truncate(transcription.name)}</span>
                            <i
                                className="cursor-pointer ri-close-circle-line hover:text-N-200"
                                onClick={handleGenerationPrompt}
                            ></i>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <span>
                        <svg
                            className="w-8 h-8"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                    </span>
                    <span>{transcription ? 'Update Transcript' : 'Upload Transcript'}</span>
                    <input type="file" className="hidden" onChange={handleFileChange} />
                </>
            )}
        </label>
    );
};

export { TranscriptionUpload };
