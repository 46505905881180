import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { createClient } from 'graphql-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { KAYA_CHATBOT_API_URL, KAYA_CHATBOT_WEB_SOCKET_URL } from '../constants';
import { setContext } from '@apollo/client/link/context';
import { KeycloakService } from '../services';
import { logger } from '../utils';

export const getApolloClient = (token: string) => {
    let activeSocket: any, timedOut: any;

    const wsClient = createClient({
        url: KAYA_CHATBOT_WEB_SOCKET_URL,
        keepAlive: 10_000,
        lazy: true, // ping server every 10 seconds
        // retryWait: () => new Promise((resolve) => setTimeout(resolve, 2000)),
        shouldRetry: () => true,
        on: {
            connected: socket => {
                activeSocket = socket;
                logger.log('Connected to the socket: ', activeSocket);
            },
            ping: received => {
                if (!received /* sent */) {
                    timedOut = setTimeout(() => {
                        // a close event `4499: Terminated` is issued to the current WebSocket and an
                        // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
                        // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
                        //
                        // calling terminate is not considered fatal and a connection retry will occur as expected
                        //
                        // see: https://github.com/enisdenjo/graphql-ws/discussions/290
                        logger.log('Terminating the connection');
                        wsClient.terminate();
                    }, 5_000);
                }
            },
            pong: received => {
                if (received) {
                    clearTimeout(timedOut);
                }
            },
            closed: closed => {
                logger.log('Socket closed: ', closed);
            },
        },
    });

    const wsLink = new GraphQLWsLink(wsClient);

    const uploadLink: any = createUploadLink({
        uri: KAYA_CHATBOT_API_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
        const authToken = await KeycloakService.getToken(); // token
        const authorization = `Bearer ${authToken}`;

        return {
            headers: {
                ...headers,
                authorization,
            },
        };
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        uploadLink
    );

    const client = new ApolloClient({
        cache: new InMemoryCache({
            addTypename: false, //need this enabled TODO: Search more on this
        }),
        credentials: 'include',
        link: ApolloLink.from([authLink, splitLink]),
    });

    return client;
};
