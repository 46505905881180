import { Button, TextField, UIModalV3 } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { FEEDBACK_QUESTIONS } from '../../../constants';
import { useFeedback } from '../../../hooks';
import { CN, getRatingField } from '../../../utils';
import { FeedbackFileUpload, Rating } from '../feedback';

export const ModalContainer = () => {
    const {
        feedback,
        loading,
        feedbackText,
        showFeedbackModal,
        submitDisabled,
        lengthExceeded,
        lengthCount,
        handleFileChange,
        handleFeedbackModalClose,
        setShowFeedbackModal,
        setFeedbackText,
        onSubmit,
        handleRatingChange,
    } = useFeedback();

    return (
        <React.Fragment>
            {/* Modal for Feedback */}
            <UIModalV3
                header={
                    <div className="flex items-center gap-x-2">
                        <i className="ri-feedback-line text-md" />
                        <p className="text-N-600 font-500 text-md">Bernie Feedback</p>
                    </div>
                }
                footer={
                    <div className="flex items-center justify-end gap-x-2">
                        <Button appearance="primary" size="medium" view="outline" onClick={handleFeedbackModalClose}>
                            Cancel
                        </Button>
                        <Button
                            appearance="primary"
                            size="medium"
                            view="solid"
                            onClick={onSubmit}
                            isDisabled={submitDisabled}
                            isLoading={loading}
                        >
                            Submit
                        </Button>
                    </div>
                }
                isOpen={showFeedbackModal}
                onClose={handleFeedbackModalClose}
                setOpen={setShowFeedbackModal}
                width="640px"
            >
                <div className="flex flex-col gap-y-6 px-2">
                    {FEEDBACK_QUESTIONS.map(({ text, type }, index) => (
                        <div key={index} className="flex flex-col gap-y-2">
                            <p className="text-sm text-N-700" style={{ wordBreak: 'break-word' }}>
                                {text}
                            </p>
                            {type === 'FILE' ? (
                                <FeedbackFileUpload feedback={feedback} handleFileChange={handleFileChange} />
                            ) : type === 'RATING' ? (
                                <Rating
                                    value={Number(feedback[getRatingField(index)])}
                                    onRatingChange={value => handleRatingChange(getRatingField(index), value)}
                                />
                            ) : (
                                <>
                                    <TextField
                                        className={'w-full' + (lengthExceeded ? ' border border-R-500' : '')}
                                        view="default"
                                        placeholder="Provide your feedback here..."
                                        rows={6}
                                        value={feedbackText}
                                        onChange={(e: any) => setFeedbackText(e.target.value)}
                                    />
                                    <span
                                        className={CN('text-xs text-N-400 w-full flex justify-end', {
                                            'text-R-500': lengthExceeded,
                                        })}
                                    >
                                        {lengthCount}
                                    </span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </UIModalV3>
        </React.Fragment>
    );
};
