import { IChatMessage } from '../../pages/messenger/types';

const ModelSwitchChatMessage = ({ id = '', text = '', time }: IChatMessage) => {
    return (
        <div className="flex items-center justify-center" key={id}>
            <div className="flex gap-2 text-center text-N-500">
                <div className="text-right">{time}</div>
                <i className="ri-arrow-left-right-line"></i>
                <span className="">
                    <span className="">Switched to</span>
                    <span className="font-bold ml-1">{text}</span>
                </span>
            </div>
        </div>
    );
};

export { ModelSwitchChatMessage };
