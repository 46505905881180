import { gql } from '../__generated__';

export const CHAT_SUBSCRIPTION = gql(/* GraphQL */ `
    subscription NewMessage($input: MessageSubscriptionInput!) {
        newMessage(input: $input) {
            id
            content
            previousContent
            author
            processing
            feedback
            type
            sources {
                id
                sources
                domainUrl
            }
        }
    }
`);

export const TIMESHEET_SUBSCRIPTION = gql(/* GraphQL */ `
    subscription NewWorklog($input: WorklogSubscriptionInput!) {
        newWorklog(input: $input) {
            id
            isUser
            description
            showApprove
            type
        }
    }
`);
