import { useMutation, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { ActivityLogMessage, ActivityLogMessageType } from '../__generated__/graphql';
import { useChatContext } from '../contexts';
import { CREATE_TIMESHEET, TIMESHEET_SUBSCRIPTION } from '../graphql';
import { logger } from '../utils';

export const useTimesheet = () => {
    const { userDetails, timesheetFormData, timesheets, setTimesheets, setLoading } = useChatContext();

    const [addTimesheet] = useMutation(CREATE_TIMESHEET);
    const { data } = useSubscription(TIMESHEET_SUBSCRIPTION, {
        variables: {
            input: {
                date: timesheetFormData.date,
                userId: userDetails.id,
            },
        },
    });

    useEffect(() => {
        if (!data || !data.newWorklog || data.newWorklog.length === 0) {
            logger.debug('No work logs!');
            return;
        }

        setTimesheets(prevMessages => {
            // Create a copy of the previous timesheets and append new worklog data (if available)
            const updatedMessages = [...prevMessages, ...(data?.newWorklog || [])];

            // Use a Map to keep track of unique descriptions for Init type
            const uniqueInitDescriptionsMap = new Map();

            // Filter the timesheets based on the specified conditions
            const filteredTimesheets = updatedMessages.filter(timesheet => {
                if (timesheet.type === ActivityLogMessageType.Init) {
                    // Check if the description is unique
                    if (!uniqueInitDescriptionsMap.has(timesheet.description)) {
                        // Add the description to the map to mark it as seen
                        uniqueInitDescriptionsMap.set(timesheet.description, true);
                        return true; // Include this entry in the result
                    }
                    return false; // Skip duplicate Init entries
                }
                return true; // Include other types of entries
            });

            return filteredTimesheets; // Return the filtered timesheets
        });
    }, [data, setLoading, setTimesheets]);

    const submitTimesheet = async (update: string) => {
        if (timesheetFormData.date && update) {
            try {
                setLoading(true);

                const activityLogObject: ActivityLogMessage = {
                    id: crypto.randomUUID(),
                    isUser: true,
                    description: update,
                    showApprove: false,
                };

                setTimesheets(prevTimesheets => [...prevTimesheets, activityLogObject]);
                const updates: ActivityLogMessage[] = timesheets?.filter(timesheet => timesheet.isUser === true);
                updates.push(activityLogObject);
                const finalUpdates = updates.slice(-2);

                const input = {
                    date: timesheetFormData.date,
                    worklog: finalUpdates?.[0]?.description || '',
                    updatedWorklog: finalUpdates?.[1]?.description || '',
                    userId: userDetails.id,
                };

                await addTimesheet({ variables: { input } });
            } catch (error) {
                logger.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    return { submitTimesheet };
};
