import { Button, Drawer } from '@kaya/kaya-ui-design-system-pb';
import React, { useState } from 'react';
import { useSideBarContext } from '../../../contexts';
import { useMobile } from '../../../hooks';
import { CN } from '../../../utils';
import { ALL_VALUE_CAPITALIZED } from '../../../constants';
import { ExternalUserFilterContainer, ExternalUserTableContainer } from '../ExternalUserManagement';

export type IFilterExternalUserCriteria = {
    requestStatus: string | null;
    setRequestStatus: React.Dispatch<React.SetStateAction<string | null>>;
    activeStatus: string | null;
    setActiveStatus: React.Dispatch<React.SetStateAction<string | null>>;
};

export type IAdminFilterExternalUserContainerProps = {
    filterExternalUserCriteria: IFilterExternalUserCriteria;
};

export const ExternalUserManagementContainer = () => {
    const [requestStatus, setRequestStatus] = useState<string | null>(ALL_VALUE_CAPITALIZED);
    const [activeStatus, setActiveStatus] = useState<string | null>(ALL_VALUE_CAPITALIZED);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);

    const isMobile = useMobile();
    const { isCollapsed, isMenuCollapsed } = useSideBarContext();

    const filterExternalUserCriteria: IFilterExternalUserCriteria = {
        requestStatus,
        setRequestStatus,
        activeStatus,
        setActiveStatus,
    };

    return (
        <div
            className={CN('admin-panel flex xxl:flex-col gap-x-16 w-full overflow-auto', {
                'gap-x-6': isMenuCollapsed || !isCollapsed,
            })}
        >
            <div className="sm:justify-end hidden xxl:flex">
                <Button
                    onClick={() => {
                        setIsFilterDrawerOpen(true);
                    }}
                    className="mb-3 text-B-500"
                    iconBefore="ri-equalizer-line"
                >
                    <p className="text-sm font-400 ">Filters</p>
                </Button>
            </div>
            <div className="xxl:hidden">
                <ExternalUserFilterContainer filterExternalUserCriteria={filterExternalUserCriteria} />
            </div>

            <ExternalUserTableContainer filterExternalUserCriteria={filterExternalUserCriteria} />

            <Drawer
                isOpen={isFilterDrawerOpen}
                onClose={() => {
                    setIsFilterDrawerOpen(false);
                }}
                title="Filters"
                isHideClose={true}
                fullWidth={isMobile}
                headerIcon="ri-contacts-line"
                width={400}
                closableOnClickOutside={true}
                className="z-[999]"
                footer={
                    <div className="flex items-center gap-x-[8px] justify-end">
                        <Button
                            view="outline"
                            appearance="primary"
                            size="medium"
                            onClick={() => {
                                setIsFilterDrawerOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                }
                children={
                    <>
                        <div className="p-5 mb-[10vh]">
                            <ExternalUserFilterContainer filterExternalUserCriteria={filterExternalUserCriteria} />
                        </div>
                    </>
                }
            />
        </div>
    );
};
