import { useChatContext } from '../../../contexts';
import { useMessageContent } from '../../../hooks';
import {
    ButtonWithMessage,
    ChatMessage,
    CommonMessage,
    FormattedChatMessage,
    ScrollableWrapper,
    TranscriptionUpload,
} from '../../common';
import { useEffect, useState } from 'react';
import { useLoadingMessages } from './useLoadingMessages';
import { DEMO_GOOGLE_MEET_REPORT } from '../../../constants';
import TranscriptModal from './common/transcriptModal';
import TranscriptSummary from './common/transcriptSummary';
import { toast } from 'react-toastify';
import { Button } from '@kaya/kaya-ui-design-system-pb';

const ANALYZE_TRANSCRIPT = {
    analyzeTranscript: {
        initial: 'Analyze Transcript 📊',
        loading: [
            'Establishing connection to the analysis engine 🔗',
            'Extracting text from transcript 📝',
            'Generating insights from the transcript 📈',
            'Compiling transcript analysis report 📄',
        ],
        success: 'Transcript Analyzed Successfully ✅',
    },
};

const TranscriptIQKPL = () => {
    const { containerRef } = useMessageContent();
    const { userFirstName } = useChatContext();
    const [analyzeTranscript, setAnalyzeTranscript] = useState({ analyzeTranscript: false });
    const { currentMessage, showLoadingText, progress } = useLoadingMessages(analyzeTranscript, ANALYZE_TRANSCRIPT);

    const [showContent, setShowContent] = useState(false);
    const [fileUploaded, setFileUploaded] = useState<File | null>(null);
    const [disbleButton, setDisableButton] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [demoData, setDemoData] = useState<any>(DEMO_GOOGLE_MEET_REPORT);
    const [refreshKey, setRefreshKey] = useState(0);
    const [editedData, setEditedData] = useState({ ...demoData });
    const [isPushHubSpotBtnLoading, setIsPushHubSpotBtnLoading] = useState<boolean>(false);

    useEffect(() => {
        if (progress === 100) {
            setAnalyzeTranscript({ analyzeTranscript: false });
            setDisableButton(true);
            setShowContent(true);
        }
    }, [progress]);

    const handleAnalyzeTranscript = () => {
        setAnalyzeTranscript({ analyzeTranscript: true });
    };

    const openModal = () => {
        setIsModalOpen(true);
        setEditedData({ ...demoData });
    };
    const closeModal = () => setIsModalOpen(false);

    const handleSave = () => {
        setDemoData(editedData);
        closeModal();
    };

    const downloadMeetingSummary = (meetingData: any) => {
        const content = `
    Meeting Summary (ID: ${meetingData.id})
    -----------------------------------------
    Date: ${meetingData.date}
    Time: ${meetingData.time}
    Call Length: ${meetingData.callLength}
    Account Name: ${meetingData.accountName}
    
    Eco Cart Attendance:
    ${meetingData.ecoCartAttendance.map((name: any) => `- ${name}`).join('\n')}
    
    Prospect Attendance:
    ${meetingData.prospectAttendance.map((name: any) => `- ${name}`).join('\n')}
    
    Key Insights:
    ${meetingData.keyInsights.map((insight: any) => `- ${insight}`).join('\n')}
    
    Next Step:
    ${meetingData.nextStep}
    
    Miscellaneous:
    ${meetingData.miscellaneous}
        `.trim();

        const downloadTextFile = (content: any, filename = `meeting_summary_${meetingData.id}.txt`) => {
            const blob = new Blob([content], { type: 'text/plain' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        };

        downloadTextFile(content);
    };

    const refreshComponent = () => {
        setRefreshKey(prevKey => prevKey + 1);
        setShowContent(false);
        setFileUploaded(null);
        setDisableButton(false);
    };

    const handleReferenceView = (refUrl: string) => {
        const url = `${refUrl}`;
        window.open(url, '_blank');
    };

    const handlePushIntoHubSpot = () => {
        toast.success('Successfully updated in HubSpot', {
            autoClose: 1500,
        });
        setIsPushHubSpotBtnLoading(true);
        setTimeout(() => {
            setIsPushHubSpotBtnLoading(false);
        }, 1500);
    };

    return (
        <ScrollableWrapper ref={containerRef} key={refreshKey}>
            <ChatMessage
                disableCopy
                text={`Hi, ${userFirstName} 👋
                \nWelcome to TranscriptIQ! 🎉 This tool is designed to make analyzing and summarizing your call transcripts seamless and efficient.
                \n- No more manual summaries! TranscriptIQ automatically generates detailed call reports from your Google Meets transcripts, capturing key insights, next steps, and all essential details.
                \nReady to dive in? Let’s streamline your call summaries and keep your HubSpot records up-to-date with ease!`}
            />

            <CommonMessage
                content={<TranscriptionUpload onFileUpload={setFileUploaded} />}
                text="Please upload the transcript"
                isUser={true}
            />

            <ButtonWithMessage
                displayCondition={Boolean(fileUploaded)}
                disabledCondition={disbleButton}
                buttonText="Analyzing Transcript"
                disabledText="Transcript analyzed successfully"
                isLoading={analyzeTranscript.analyzeTranscript && progress < 100}
                onClick={handleAnalyzeTranscript}
                icon="ri-sparkling-line"
                text="👇 Please click on the button below to analyze the transcript"
            />

            {showLoadingText && (
                <CommonMessage
                    disableCopy
                    text={currentMessage}
                    content={
                        <>
                            <FormattedChatMessage text={currentMessage} />
                            <div className="bg-N-400 rounded-full h-2.5 animate-fade my-1">
                                <div
                                    style={{ width: `${progress}%` }}
                                    className={'bg-B-600 h-2.5 rounded-full animate-pulse'}
                                />
                            </div>
                        </>
                    }
                    isUser={false}
                />
            )}

            {showContent && (
                <>
                    <CommonMessage
                        disableCopy
                        text={currentMessage}
                        content={
                            <>
                                <TranscriptSummary
                                    demoData={demoData}
                                    openModal={openModal}
                                    downloadMeetingSummary={() => {
                                        downloadMeetingSummary(editedData);
                                    }}
                                    refreshComponent={() => {
                                        refreshComponent();
                                    }}
                                />
                            </>
                        }
                        isUser={false}
                    />
                    <CommonMessage
                        isUser={false}
                        disableCopy
                        text="Select"
                        content={
                            <>
                                <FormattedChatMessage
                                    text={`🎉 All Set!
                                    \n Your call transcript has been successfully analyzed using TranscriptIQ! With AI-driven insights and seamless integration with Google Meets and HubSpot, 
                                    we hope this tool saves you time and keeps your call reports accurate and organized. 
                                    \n To push these into HubSpot, please click the button below.`}
                                />

                                <Button
                                    className="bg-A-500 hover:bg-A-400 text-white outline-none border-none py-2 px-4 rounded-md w-50 flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                    iconBefore="ri-upload-cloud-fill"
                                    view="outline"
                                    isLoading={isPushHubSpotBtnLoading}
                                    onClick={() => {
                                        handlePushIntoHubSpot();
                                    }}
                                >
                                    Push into HubSpot
                                </Button>
                            </>
                        }
                    />
                    <div>
                        <p className="mb-6 text-lg font-extra-bold text-black flex items-center">
                            <span role="img" aria-label="books">
                                📚
                            </span>{' '}
                            References
                        </p>

                        <div className="ms-10">
                            <span
                                onClick={() => {
                                    handleReferenceView(DEMO_GOOGLE_MEET_REPORT.References);
                                }}
                                className="cursor-pointer text-B-600 font-medium underline decoration-solid hover:text-blue-800"
                            >
                                View in HubSpot
                            </span>
                        </div>
                    </div>{' '}
                </>
            )}

            <TranscriptModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onSave={handleSave}
                editedData={editedData}
                setEditedData={setEditedData}
            />
        </ScrollableWrapper>
    );
};

export { TranscriptIQKPL };
