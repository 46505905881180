import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { ADD_MESSAGE_FEEDBACK } from '../graphql';

const useMessageFeedback = () => {
    const [addMessageFeedback, { loading }] = useMutation(ADD_MESSAGE_FEEDBACK);

    const submitUserFeedback = useCallback(
        async (messageId: string, feedback: boolean) => {
            await addMessageFeedback({
                variables: {
                    input: {
                        feedback,
                        messageId,
                    },
                },
            });
        },
        [addMessageFeedback]
    );

    return { submitUserFeedback, loading };
};

export { useMessageFeedback };
