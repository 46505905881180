import { Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { MouseEventHandler } from 'react';
import { uniqueId } from '../../../utils';

type TIconButton = {
    title?: string;
    icon: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
};

const IconButton = ({ title, icon, onClick, disabled = false }: TIconButton) => {
    const ButtonComponent = () => {
        return (
            <button
                disabled={disabled}
                onClick={onClick}
                type="button"
                className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-N-700 hover:bg-N-200 focus:outline-none"
            >
                <i className={icon} />
            </button>
        );
    };

    if (title) {
        return (
            <Tooltip id={uniqueId()} title={title} tooltipType="minimal" variant="dark">
                <ButtonComponent />
            </Tooltip>
        );
    }

    return <ButtonComponent />;
};

export { IconButton };
