import { useEffect, useState } from 'react';

export const usePromptFilter = () => {
    const [model, setModel] = useState(null);
    const [kpl, setKpl] = useState(null);
    const [category, setCategory] = useState(null);
    const [search, setSearch] = useState('');
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        //fetch filter data and updated with states
    }, []);

    return {
        model: model,
        setModel: setModel,
        kpl: kpl,
        setKpl: setKpl,
        category: category,
        setCategory: setCategory,
        search: search,
        setSearch: setSearch,
        count: count,
        setCount: setCount,
    };
};
