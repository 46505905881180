import { FilterCard } from '@kaya/kaya-ui-design-system-pb';
import { NOT_AVAILABLE_VALUE } from '../../../../../constants';
import { KPLSelect, ModelSelect } from '../../../custom-select-components';
import { CategorySelect } from '../../../custom-select-components/CategorySelect.tsx';

type IPromptFilter = {
    model: string | null;
    setModel: Function;
    kpl: string | null;
    setKpl: Function;
    category: string | null;
    setCategory: Function;
};

export const PromptFilterContainer = ({ model, setModel, kpl, setKpl, category, setCategory }: IPromptFilter) => {
    return (
        <div className="admin-panel__filter-section sm:w-full min-w-[296px] flex flex-col gap-y-6 pb-[30vh]">
            <div className="min-h-[36px] flex xxl:hidden items-center justify-between">
                <p className="text-[18px] font-600">Filter</p>
            </div>
            <div className="admin-panel__filter-card-container flex flex-col border border-N-200 border-b-0">
                <FilterCard
                    title="Model"
                    showClear={model !== null ? true : false}
                    show
                    handleClear={() => setModel(null)}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <ModelSelect
                            hasAllOption={true}
                            hasEmptyOption={true}
                            value={model}
                            onChange={(e: any) => {
                                setModel(e.name === NOT_AVAILABLE_VALUE ? e.name : e.model);
                            }}
                        />
                    </div>
                </FilterCard>
                <FilterCard title="KPL" showClear={kpl !== null ? true : false} show handleClear={() => setKpl(null)}>
                    <div className="flex flex-col gap-y-[12px]">
                        <KPLSelect
                            hasAllOption={true}
                            hasEmptyOption={true}
                            value={kpl}
                            onKPLChange={(e: any) => {
                                setKpl(e.title === NOT_AVAILABLE_VALUE ? e.title : e.configKey);
                            }}
                        />
                    </div>
                </FilterCard>
                <FilterCard
                    title="Category"
                    showClear={category !== null ? true : false}
                    show
                    handleClear={() => setCategory(null)}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <CategorySelect
                            hasAllOption={true}
                            value={category}
                            onChange={(e: any) => {
                                setCategory(e.key);
                            }}
                        />
                    </div>
                </FilterCard>
            </div>
        </div>
    );
};
