import { Tooltip, Button } from '@kaya/kaya-ui-design-system-pb';
import { EmailContent } from '../messageMaestroKPL';
import { FormattedChatMessage } from '../../../common';
import { KnowledgeDocument } from '../../../common/custom-kpl-container/common/custom-kpl-configure/common/knowledge-document/KnowledgeDocument';
import { uniqueId } from '../../../../utils';

interface EmailActionsProps {
    email: EmailContent;
    generatedEmails: EmailContent[];
    openModal: () => void;
    downloadEmlFile: (email: EmailContent) => void;
    downloadAllEmail: (emails: EmailContent[]) => void;
    refreshComponent: () => void;
    setIsSelectorVisible: (visible: boolean) => void;
    setSelectedEmailId: (id: string) => void;
}

const EmailActions: React.FC<EmailActionsProps> = ({
    email,
    generatedEmails,
    openModal,
    downloadEmlFile,
    downloadAllEmail,
    refreshComponent,
    setIsSelectorVisible,
    setSelectedEmailId,
}) => {
    return (
        <div>
            <p>
                <strong>Subject: </strong>
                {email.subject}
            </p>
            <div className="mt-2">
                <FormattedChatMessage text={email.body} />
            </div>
            <div>
                <KnowledgeDocument
                    name={`EcoCart Sustainability Report.pdf`}
                    isHideClose
                    key={uniqueId()}
                    onClose={() => {}}
                    fileSize={1}
                />
            </div>

            <p className="mt-5 mb-2 text-xs">
                <i>
                    If you feel the tone of the email could be improved, you can easily adjust it using the available
                    buttons on edit option.
                </i>
            </p>

            <div className="flex space-x-2 mb-5 w-[9vw]">
                <Tooltip id={uniqueId()} place="top" variant="dark" tooltipType="detailed" title="Edit Email Content">
                    <Button
                        className="bg-R-500 hover:bg-R-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                        iconBefore="ri-edit-box-fill"
                        view="outline"
                        onClick={() => {
                            openModal();
                            setSelectedEmailId(email.id);
                        }}
                    />
                </Tooltip>

                <Tooltip id={uniqueId()} place="top" variant="dark" tooltipType="detailed" title="Download as EML File">
                    <Button
                        className="bg-B-500 hover:bg-B-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                        iconBefore="ri-mail-download-fill"
                        view="outline"
                        onClick={() => {
                            downloadEmlFile(email);
                        }}
                    />
                </Tooltip>

                <Tooltip
                    id={uniqueId()}
                    place="top"
                    variant="dark"
                    tooltipType="detailed"
                    title={generatedEmails?.length >= 4 ? 'All emails generated' : 'Generate Next Email'}
                >
                    <Button
                        className="bg-G-500 hover:bg-G-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                        iconBefore="ri-corner-down-right-line"
                        isDisabled={generatedEmails?.length >= 4}
                        onClick={() => {
                            setIsSelectorVisible(true);
                        }}
                    />
                </Tooltip>

                {generatedEmails?.length > 1 && (
                    <Tooltip
                        id={uniqueId()}
                        place="top"
                        variant="dark"
                        tooltipType="detailed"
                        title="Download All Emails"
                    >
                        <Button
                            className="bg-A-500 hover:bg-A-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                            iconBefore="ri-folder-download-fill"
                            view="outline"
                            onClick={() => {
                                downloadAllEmail(generatedEmails);
                            }}
                        />
                    </Tooltip>
                )}

                <Tooltip id={uniqueId()} place="top" variant="dark" tooltipType="detailed" title="Refresh">
                    <Button
                        className="bg-N-500 hover:bg-N-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                        iconBefore="ri-refresh-line"
                        view="outline"
                        onClick={() => {
                            refreshComponent();
                        }}
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default EmailActions;
