import {
    CustomKplSectionSelectionTypes,
    SideBarButtonSelectionTypes,
    useChatContext,
    useCustomKplContext,
    useSideBarContext,
} from '../contexts';
import { useMobile } from './useMobile';

type IUseGuestCustomKpl = {
    handleDefaultSidebarSelection: Function;
};

export const useGuestCustomKpl = (): IUseGuestCustomKpl => {
    const { isCurrentUserGuest } = useChatContext();
    const { setSideBarButtonSelection, setIsCollapsed, setCustomKplSelection } = useSideBarContext();
    const isMobile = useMobile();
    const { isGuestKplCreated } = useCustomKplContext();

    const handleDefaultSidebarSelection = () => {
        if (!isCurrentUserGuest || isMobile) return;
        const section = isGuestKplCreated
            ? SideBarButtonSelectionTypes.KplListSelection
            : SideBarButtonSelectionTypes.CustomKplSection;

        setCustomKplSelection(
            isGuestKplCreated ? CustomKplSectionSelectionTypes.CONFIGURE : CustomKplSectionSelectionTypes.CREATE
        );

        setSideBarButtonSelection(section);
        if (!isMobile) {
            setIsCollapsed(false);
        } else {
            setIsCollapsed(true);
        }
    };

    return {
        handleDefaultSidebarSelection,
    };
};
