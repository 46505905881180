import { ChatType, FeatureType } from '../../../__generated__/graphql';
import { AdminPanelSectionSelectionTypes, useChatContext, useSideBarContext } from '../../../contexts';
import { useMobile } from '../../../hooks';
import { CN } from '../../../utils';
import { ChatActions } from '../../chat-bot/ChatActions';
import { ChatHeader } from '../../chat-bot/ChatHeader';
import { AdminPanelLayout } from '../../layout';
import { CustomKPLComponent, MessageContent } from '../../pages';
import { AdminPanel } from '../admin-panel';
import { ChatContentModalContainer } from '../chat-content-modal-container';
import { CustomKplContainer } from '../custom-kpl-container/CustomKplContainer';

const ChatTypeContent = {
    [ChatType.Assistant]: MessageContent,
    [ChatType.Custom]: CustomKPLComponent,
};

export const ChatContentContainer = () => {
    const { chatType, featureType } = useChatContext();
    const { adminPanelSelection } = useSideBarContext();
    const ChatContentComponent = ChatTypeContent[chatType as keyof typeof ChatTypeContent];
    const showChatActions = chatType === ChatType.Assistant && featureType !== FeatureType.Timesheet;
    const isMobile = useMobile();

    const renderAdminPanelContent = () => {
        switch (adminPanelSelection) {
            case AdminPanelSectionSelectionTypes.NOT_SELECTED:
                return <ChatContentComponent />;
            case AdminPanelSectionSelectionTypes.CUSTOM_KPL:
                return <CustomKplContainer />;
            default:
                return (
                    <AdminPanelLayout>
                        <AdminPanel />
                    </AdminPanelLayout>
                );
        }
    };

    return (
        <div
            className={CN('chat_content_container w-full h-full flex flex-col bg-N-50', {
                'overflow-hidden': adminPanelSelection !== AdminPanelSectionSelectionTypes.NOT_SELECTED && !isMobile,
            })}
        >
            {<ChatHeader />}
            <div className="flex-grow h-[calc(100vh-236px)]">{renderAdminPanelContent()}</div>
            {showChatActions && adminPanelSelection === AdminPanelSectionSelectionTypes.NOT_SELECTED && <ChatActions />}
            {/* This ChatContentModalContainer include all the modals open from a chat */}
            <ChatContentModalContainer />
        </div>
    );
};
