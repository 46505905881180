import { FilterCard, SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { IAdminFilterExternalUserContainerProps } from '../external-user-management-container';
import { capitalize } from 'lodash';
import { ALL_VALUE_CAPITALIZED, EXTERNAL_USERS_REQUEST_STATUS, USER_STATUS_COLORS } from '../../../constants';

export const ExternalUserFilterContainer = ({ filterExternalUserCriteria }: IAdminFilterExternalUserContainerProps) => {
    const { requestStatus, setRequestStatus, activeStatus, setActiveStatus } = filterExternalUserCriteria;

    return (
        <div className="admin-panel__filter-section sm:w-full min-w-[296px] flex flex-col gap-y-6 pb-[30vh]">
            <div className="min-h-[36px] flex xxl:hidden items-center justify-between">
                <p className="text-[18px] font-600">Filter</p>
            </div>
            <div className="admin-panel__filter-card-container flex flex-col border border-N-200 border-b-0">
                <FilterCard
                    title="Request Status"
                    showClear={requestStatus !== ALL_VALUE_CAPITALIZED ? true : false}
                    show
                    handleClear={() => {
                        setRequestStatus(ALL_VALUE_CAPITALIZED);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            value={capitalize(requestStatus?.toLowerCase()) || ''}
                            view="default"
                            items={[
                                capitalize(ALL_VALUE_CAPITALIZED),
                                ...Object.keys(EXTERNAL_USERS_REQUEST_STATUS).map(status =>
                                    capitalize(status.toLowerCase())
                                ),
                            ]}
                            onChange={(value: any) => setRequestStatus(value.toUpperCase())}
                        />
                    </div>
                </FilterCard>

                <FilterCard
                    title="Active status"
                    showClear={activeStatus !== ALL_VALUE_CAPITALIZED ? true : false}
                    show
                    handleClear={() => {
                        setActiveStatus(ALL_VALUE_CAPITALIZED);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            value={capitalize(activeStatus?.toLowerCase()) || ''}
                            view="default"
                            items={[
                                capitalize(ALL_VALUE_CAPITALIZED),
                                ...Object.keys(USER_STATUS_COLORS).map(status => capitalize(status.toLowerCase())),
                            ]}
                            onChange={(value: any) => setActiveStatus(value.toUpperCase())}
                        />
                    </div>
                </FilterCard>
            </div>
        </div>
    );
};
