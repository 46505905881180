import { useMobile } from '../../../../../../hooks';
import { CN, truncateWithEllipsis } from '../../../../../../utils';

export type IKplInfoChips = {
    label: any;
    icon: string;
};
export const KplInfoChips = ({ list }: { list: IKplInfoChips }) => {
    const isMobile = useMobile();
    return (
        <div
            className={CN(
                'relative flex items-center gap-x-1 justify-center cursor-default py-[1px] px-1 rounded bg-N-200',
                {
                    '!bg-N-800 !justify-start': isMobile,
                }
            )}
        >
            <i
                className={CN('text-sm text-N-700 relative', list?.icon, {
                    '!text-N-300': isMobile,
                })}
            />
            <p
                className={CN('text-xs text-N-700 relative top-[1px]', {
                    '!text-N-300': isMobile,
                })}
            >
                {truncateWithEllipsis(String(list?.label), isMobile ? 20 : 100)}
            </p>
        </div>
    );
};
