import { ReactNode } from 'react';
import { StepperTypes } from '../../../../generate-technical-spec';

export type IChatSteps = {
    id: number | string;
    response: ReactNode;
    isShowStep: boolean;
    inputs?: ReactNode;
    type: StepperTypes;
};

export const ChatStepper = ({ steps }: { steps: IChatSteps }) => {
    return (
        <div>
            {steps?.isShowStep && steps?.response}
            {steps?.isShowStep && steps?.inputs ? steps?.inputs : <></>}
        </div>
    );
};
