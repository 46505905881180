import { createContext, ReactNode, useContext, useState } from 'react';

export type IHighlightedPDFReferenceType = {
    sourceId: string;
    sourceName: string;
    feature: string;
};

type IGoogleFactCheckType = {
    previousMessageId?: string;
    previousMessage?: string;
    factCheckMessageId?: string;
    factCheckMessage?: string;
    llmComparisonContent?: string;
};

interface IChatContentContext {
    isPDFReferenceModalOpen: boolean;
    setIsPDFReferenceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getHighlightedPDFReferenceInputValues: IHighlightedPDFReferenceType | undefined;
    setGetHighlightedPDFReferenceInputValues: React.Dispatch<
        React.SetStateAction<IHighlightedPDFReferenceType | undefined>
    >;
    isGoogleFactCheckModalOpen: boolean;
    setIsGoogleFactCheckModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    googleFactCheckContent: IGoogleFactCheckType | undefined;
    setGoogleFactCheckContent: React.Dispatch<React.SetStateAction<IGoogleFactCheckType | undefined>>;
    highlightedPDFReferencesThumbnails: IHighlightedPDFReferenceType[] | undefined;
    setHighlightedPDFReferencesThumbnails: React.Dispatch<
        React.SetStateAction<IHighlightedPDFReferenceType[] | undefined>
    >;
}

interface IChatContentContextProvider {
    children: ReactNode;
}

const ChatContentContext = createContext<IChatContentContext>({
    isPDFReferenceModalOpen: false,
    setIsPDFReferenceModalOpen: () => {},
    getHighlightedPDFReferenceInputValues: { sourceId: '', sourceName: '', feature: '' },
    setGetHighlightedPDFReferenceInputValues: () => {},
    isGoogleFactCheckModalOpen: false,
    setIsGoogleFactCheckModalOpen: () => {},
    googleFactCheckContent: undefined,
    setGoogleFactCheckContent: () => {},
    highlightedPDFReferencesThumbnails: undefined,
    setHighlightedPDFReferencesThumbnails: () => {},
});

export const ChatContentContextProvider: React.FC<IChatContentContextProvider> = ({ children }) => {
    const [isPDFReferenceModalOpen, setIsPDFReferenceModalOpen] = useState(false);
    const [getHighlightedPDFReferenceInputValues, setGetHighlightedPDFReferenceInputValues] = useState<
        IHighlightedPDFReferenceType | undefined
    >();
    const [isGoogleFactCheckModalOpen, setIsGoogleFactCheckModalOpen] = useState(false);
    const [googleFactCheckContent, setGoogleFactCheckContent] = useState<IGoogleFactCheckType>();
    const [highlightedPDFReferencesThumbnails, setHighlightedPDFReferencesThumbnails] =
        useState<IHighlightedPDFReferenceType[]>();

    return (
        <ChatContentContext.Provider
            value={{
                isPDFReferenceModalOpen,
                setIsPDFReferenceModalOpen,
                getHighlightedPDFReferenceInputValues,
                setGetHighlightedPDFReferenceInputValues,
                googleFactCheckContent,
                setGoogleFactCheckContent,
                isGoogleFactCheckModalOpen,
                setIsGoogleFactCheckModalOpen,
                highlightedPDFReferencesThumbnails,
                setHighlightedPDFReferencesThumbnails,
            }}
        >
            {children}
        </ChatContentContext.Provider>
    );
};

export const useChatContentContext = (): IChatContentContext => {
    const context = useContext(ChatContentContext);

    if (!context) {
        throw new Error('useChatContext must be used within a ChatContextProvider');
    }

    return context;
};
