import { CSS_INPUT, CSS_SCROLL } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { CN } from '../../../utils';

interface IBitBucketSettingsModal {
    open: boolean;
    onClose: () => void;
    hideJiraProjectKey?: boolean;
}

const BitBucketSettingsModal = ({ hideJiraProjectKey, open, onClose }: IBitBucketSettingsModal) => {
    const { jiraOptions } = useChatContext();

    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="fixed bottom-[3.5em] right-[1.5em] p-4 pt-2 bg-white border border-N-200 drop-shadow-sm rounded-md w-full max-w-md m-auto">
                <form className="grid grid-cols-3 gap-2 my-2 justify-items-start">
                    <label className="flex items-center justify-end gap-1 col-span-1">
                        <span>Bitbucket Email:</span>
                    </label>
                    <input
                        title={`${jiraOptions.jiraUserEmail}`}
                        type="text"
                        className={CN(
                            `${CSS_INPUT} !leading-[18px] ${CSS_SCROLL} w-full bg-card-default text-N-900 col-span-2`
                        )}
                        name="jiraUserEmail"
                        defaultValue={jiraOptions.jiraUserEmail}
                    />
                    <label className="flex items-center justify-end gap-1 col-span-1">
                        <span>Bitbucket Token:</span>
                    </label>
                    <input
                        title={`${jiraOptions.jiraUserToken}`}
                        type="text"
                        name="jiraUserToken"
                        className={CN(
                            `${CSS_INPUT} !leading-[18px] ${CSS_SCROLL} w-full bg-card-default text-N-900 col-span-2`
                        )}
                        defaultValue={jiraOptions.jiraUserToken}
                    />
                    <label className="flex items-center justify-end gap-1 col-span-1">
                        <span>Repository:</span>
                    </label>
                    <input
                        title={`${jiraOptions.jiraInstanceName}`}
                        type="text"
                        name="jiraInstanceName"
                        className={CN(
                            `${CSS_INPUT} !leading-[18px] ${CSS_SCROLL} w-full bg-card-default text-N-900 col-span-2 mb-2`
                        )}
                        defaultValue="https://bitbucket.org/techprovint/kaya-core-api/src/master/"
                    />
                </form>
                <div className="flex gap-2">
                    <button
                        className="bg-B-500 hover:bg-B-400 text-white py-2 px-4 rounded-md w-full"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Save
                    </button>
                    <button
                        className="bg-R-600 hover:bg-N-500 text-white py-2 px-4 rounded-md w-full"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export { BitBucketSettingsModal };
