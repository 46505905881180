/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  JSONArray: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type ActionOnDislikedMessageInput = {
  messageId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  user: Scalars['String']['input'];
};

export enum ActiveStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type ActivityLog = {
  __typename?: 'ActivityLog';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  problems?: Maybe<Array<Maybe<Problem>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ActivityLogMessage = {
  __typename?: 'ActivityLogMessage';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isUser?: Maybe<Scalars['Boolean']['output']>;
  showApprove?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<ActivityLogMessageType>;
};

export enum ActivityLogMessageType {
  Chat = 'CHAT',
  Init = 'INIT'
}

export type AddActivityLogInput = {
  date: Scalars['DateTime']['input'];
  updatedWorklog?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  worklog?: InputMaybe<Scalars['String']['input']>;
};

export type AddApproveLogInput = {
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  worklog?: InputMaybe<Scalars['String']['input']>;
};

export type AddCommonMessageInput = {
  content: Scalars['String']['input'];
  type: CommonMessageType;
  userId: Scalars['ID']['input'];
};

export type AddFactCheckMessageInput = {
  messageId: Scalars['ID']['input'];
};

export type AddFeedbackInput = {
  feedback: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  userName: Scalars['String']['input'];
};

export type AddMessageFeedbackInput = {
  feedback: Scalars['Boolean']['input'];
  messageId: Scalars['ID']['input'];
};

export type AddMessageInput = {
  author: MessageAuthor;
  content: Scalars['String']['input'];
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  isGuestUser?: InputMaybe<Scalars['Boolean']['input']>;
  replyTo?: InputMaybe<Scalars['ID']['input']>;
  sessionId: Scalars['ID']['input'];
  settings?: InputMaybe<MessageSettingsInput>;
  type: MessageType;
  userId: Scalars['ID']['input'];
};

export type AddSmeMessageInput = {
  answer: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  responseMessageId: Scalars['ID']['input'];
  smeUserId: Scalars['ID']['input'];
};

export type AddTimesheetInput = {
  feature: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  fullname: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type AiaCommonMessage = {
  __typename?: 'AiaCommonMessage';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type AiaMessageSmeResponse = {
  __typename?: 'AiaMessageSmeResponse';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  smeUser?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnalyzeCallTranscriptInput = {
  transcript: Scalars['Upload']['input'];
};

export type AnalyzeTestCasesInput = {
  testCases: Scalars['Upload']['input'];
};

export type ApplicationConfig = {
  __typename?: 'ApplicationConfig';
  chatType: ChatType;
  configKey: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  metadata: Scalars['JSONObject']['output'];
  title: Scalars['String']['output'];
};

export type ApplicationUserConfig = {
  __typename?: 'ApplicationUserConfig';
  email: Scalars['String']['output'];
  features: Scalars['String']['output'];
  metadata: Scalars['JSONObject']['output'];
  models: Scalars['String']['output'];
  override: Scalars['Boolean']['output'];
};

export enum ApplicationUserType {
  ApplicationUser = 'APPLICATION_USER',
  BernieGuestUser = 'BERNIE_GUEST_USER',
  SystemUser = 'SYSTEM_USER'
}

export type CallReportOptions = {
  __typename?: 'CallReportOptions';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  option?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
};

export enum ChatType {
  Assistant = 'ASSISTANT',
  Custom = 'CUSTOM',
  Operation = 'OPERATION'
}

export enum CommonMessageType {
  LearningMaterial = 'LEARNING_MATERIAL',
  SmeResponse = 'SME_RESPONSE'
}

export type Conversation = {
  __typename?: 'Conversation';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
  threadType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ConversationResult = {
  __typename?: 'ConversationResult';
  feature: ApplicationConfig;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreatePromptTemplateInput = {
  category: PromptTemplateCategory;
  isDeletable?: InputMaybe<Scalars['Boolean']['input']>;
  kpl?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  template: Scalars['String']['input'];
  templateKey: Scalars['String']['input'];
  variables: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CustomApplicationUserConfig = {
  __typename?: 'CustomApplicationUserConfig';
  email: Scalars['String']['output'];
  features: Array<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  metadata: Scalars['JSONObject']['output'];
  models: Array<Scalars['String']['output']>;
  override: Scalars['Boolean']['output'];
  status?: Maybe<UserStatus>;
  userType?: Maybe<ApplicationUserType>;
};

export type CustomKplCreateConfigurationInput = {
  configKey: Scalars['String']['input'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  predefinedQuestions?: InputMaybe<Array<Scalars['String']['input']>>;
  prompt: Scalars['String']['input'];
  removedDocuments?: InputMaybe<Array<Scalars['ID']['input']>>;
  title: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type DeleteDocumentInput = {
  conversationId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type DeleteIndexInput = {
  conversationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type DetailedKplConfiguration = {
  __typename?: 'DetailedKPLConfiguration';
  chatType: ChatType;
  configKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  enabled: Scalars['Boolean']['output'];
  guidelines?: Maybe<Array<Maybe<KplGuideline>>>;
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  metadata: Scalars['JSONObject']['output'];
  prompts?: Maybe<Array<Maybe<ModelPrompt>>>;
  title: Scalars['String']['output'];
  users?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum DocumentPersistenceType {
  Permanent = 'PERMANENT',
  Temporary = 'TEMPORARY'
}

export type ExistingUsers = {
  __typename?: 'ExistingUsers';
  domains?: Maybe<Array<Scalars['String']['output']>>;
  users?: Maybe<Array<Scalars['String']['output']>>;
};

export enum FeatureType {
  AddTimesheet = 'ADD_TIMESHEET',
  ChatWithDocuments = 'CHAT_WITH_DOCUMENTS',
  ClientKbCitiInsights = 'CLIENT_KB_CITI_INSIGHTS',
  ClientKbHealthfirstSupport = 'CLIENT_KB_HEALTHFIRST_SUPPORT',
  ClientKbPerceptive = 'CLIENT_KB_PERCEPTIVE',
  ClientKbSantander = 'CLIENT_KB_SANTANDER',
  CodeAnalysis = 'CODE_ANALYSIS',
  Feedback = 'FEEDBACK',
  General = 'GENERAL',
  GenerateTechnicalSpec = 'GENERATE_TECHNICAL_SPEC',
  KayaFlexForce = 'KAYA_FLEX_FORCE',
  KayaKbDev = 'KAYA_KB_DEV',
  KayaKbRecruitment = 'KAYA_KB_RECRUITMENT',
  KayaKbSqlAgent = 'KAYA_KB_SQL_AGENT',
  LeadSyncKpl = 'LEAD_SYNC_KPL',
  MessageMaestroKpl = 'MESSAGE_MAESTRO_KPL',
  SprintAnalysisReport = 'SPRINT_ANALYSIS_REPORT',
  TestCaseAnalysis = 'TEST_CASE_ANALYSIS',
  Timesheet = 'TIMESHEET',
  TranscriptIqKpl = 'TRANSCRIPT_IQ_KPL',
  WealthManagementTranscriptAnalysis = 'WEALTH_MANAGEMENT_TRANSCRIPT_ANALYSIS'
}

export type FollowUpQuestion = {
  __typename?: 'FollowUpQuestion';
  answer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['ID']['output']>;
  question?: Maybe<Scalars['String']['output']>;
};

export type GenerateCodeFromRequirementsInput = {
  architecture: Scalars['String']['input'];
  authentication: Scalars['String']['input'];
  database: Scalars['String']['input'];
  deployment: Scalars['String']['input'];
  guidelines?: InputMaybe<Scalars['Upload']['input']>;
  language: Scalars['String']['input'];
  libraries: Scalars['String']['input'];
  requirements: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GenerateJiraTicketsInput = {
  jiraInstanceName?: InputMaybe<Scalars['String']['input']>;
  jiraProjectKey?: InputMaybe<Scalars['String']['input']>;
  jiraUserEmail?: InputMaybe<Scalars['String']['input']>;
  jiraUserToken?: InputMaybe<Scalars['String']['input']>;
  requirements: Scalars['String']['input'];
  type?: InputMaybe<RequirementType>;
  userId: Scalars['ID']['input'];
};

export type GenerateRequirementsInput = {
  requirements?: InputMaybe<Scalars['String']['input']>;
  transcript?: InputMaybe<Scalars['Upload']['input']>;
  type?: InputMaybe<RequirementType>;
  userId: Scalars['ID']['input'];
};

export type GenerateSprintAnalysisReportInput = {
  jiraInstanceName?: InputMaybe<Scalars['String']['input']>;
  jiraProjectKey?: InputMaybe<Scalars['String']['input']>;
  jiraUserEmail?: InputMaybe<Scalars['String']['input']>;
  jiraUserToken?: InputMaybe<Scalars['String']['input']>;
  project: Scalars['String']['input'];
  sprint: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GetActivityLogsInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type GetAllKplConfigurationsInput = {
  chatType?: InputMaybe<ChatType>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  status?: InputMaybe<ActiveStatus>;
};

export type GetAllKplRequestUsersInput = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  requestStatus?: InputMaybe<KplRequestUserStatus>;
  userStatus?: InputMaybe<UserStatus>;
};

export type GetAllUserConfigurationsInput = {
  featureTypes: Array<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  userType?: InputMaybe<Scalars['String']['input']>;
};

export type GetConversationHistoryInput = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  searchKey: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GetFollowUpQuestionsInput = {
  messageId: Scalars['ID']['input'];
};

export type GetHighlightedPdfInput = {
  chunk_id: Scalars['ID']['input'];
  feature: Scalars['String']['input'];
};

export type GetJiraSettingsInput = {
  jiraInstanceName?: InputMaybe<Scalars['String']['input']>;
  jiraProjectKey?: InputMaybe<Scalars['String']['input']>;
  jiraUserEmail?: InputMaybe<Scalars['String']['input']>;
  jiraUserToken?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetKplGuidelinesInput = {
  configKey: Scalars['String']['input'];
};

export type GetMessageInput = {
  conversationId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type GetPromptTemplateByCriteriaInput = {
  category?: InputMaybe<PromptTemplateCategory>;
  key?: InputMaybe<Scalars['String']['input']>;
  kpl?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type GetTechnicalQuestionsInput = {
  messageId: Scalars['ID']['input'];
};

export type GetTimesheetsInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type GroupedConversationResult = {
  __typename?: 'GroupedConversationResult';
  conversations: Array<ConversationResult>;
  date: Scalars['String']['output'];
};

export type GuestUserMetaData = {
  __typename?: 'GuestUserMetaData';
  guestKPLNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isGuestKPLCreated?: Maybe<Scalars['Boolean']['output']>;
  kplUserDocument?: Maybe<Array<Maybe<Scalars['JSONObject']['output']>>>;
  metaDataconfigs?: Maybe<Scalars['JSONObject']['output']>;
  questions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  remainingDays?: Maybe<Scalars['Int']['output']>;
  tokenUsage?: Maybe<Scalars['Int']['output']>;
  totalDocumentCount?: Maybe<Scalars['Int']['output']>;
  totalDocumentSize?: Maybe<Scalars['Int']['output']>;
};

export type HighlightPdfResponse = {
  __typename?: 'HighlightPDFResponse';
  page?: Maybe<Scalars['Int']['output']>;
  pdf: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

export type JiraProjects = {
  __typename?: 'JiraProjects';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sprints?: Maybe<Array<Maybe<JiraSprints>>>;
};

export type JiraSprints = {
  __typename?: 'JiraSprints';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originBoardId: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type KplConfigurationInput = {
  additionalSettings: Scalars['JSONObject']['input'];
  autoGenerate: Scalars['Boolean']['input'];
  chatType: ChatType;
  configKey: Scalars['String']['input'];
  criteria: Scalars['JSONObject']['input'];
  detailedDescription: Scalars['String']['input'];
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  icon: Scalars['String']['input'];
  operation: OperationType;
  predefinedQuestions?: InputMaybe<Array<Scalars['String']['input']>>;
  prompts?: InputMaybe<Array<ModelPromptInput>>;
  shortDescription: Scalars['String']['input'];
  title: Scalars['String']['input'];
  users?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum KplOptionsType {
  DynamicPrompts = 'dynamicPrompts',
  NoLlm = 'noLLM',
  WebSearch = 'webSearch'
}

export type KplRequestUser = {
  __typename?: 'KPLRequestUser';
  id: Scalars['Int']['output'];
  status: KplRequestUserStatus;
  user?: Maybe<User>;
};

export enum KplRequestUserStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum KplType {
  Standard = 'STANDARD',
  Trial = 'TRIAL'
}

export type KplGuideline = {
  __typename?: 'KplGuideline';
  guidelinesText: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  kplConfigKey: Scalars['String']['output'];
};

export type LlmComparisonInput = {
  messageId: Scalars['ID']['input'];
};

export type LiveCustomerAgentDetails = {
  __typename?: 'LiveCustomerAgentDetails';
  audioFileUrl: Scalars['String']['output'];
  transcript: Array<LiveCustomerAgentTranscript>;
};

export type LiveCustomerAgentTranscript = {
  __typename?: 'LiveCustomerAgentTranscript';
  message: Scalars['String']['output'];
  time: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type MarkMessageAsReadInput = {
  messageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Message = {
  __typename?: 'Message';
  author?: Maybe<MessageAuthor>;
  content?: Maybe<Scalars['String']['output']>;
  conversationId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  feedback?: Maybe<Scalars['Boolean']['output']>;
  followupQuestions?: Maybe<Array<Maybe<FollowUpQuestion>>>;
  id?: Maybe<Scalars['ID']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  previousContent?: Maybe<Scalars['String']['output']>;
  processing?: Maybe<Scalars['Boolean']['output']>;
  replyTo?: Maybe<Scalars['ID']['output']>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  sources?: Maybe<Array<Maybe<Source>>>;
  type?: Maybe<MessageType>;
};

export enum MessageAuthor {
  System = 'SYSTEM',
  User = 'USER'
}

export type MessageSettingsInput = {
  feature: Scalars['String']['input'];
  hasTemporaryFiles: Scalars['Boolean']['input'];
  model: Scalars['String']['input'];
  options: MessageSettingsOptionsInput;
  type: Scalars['String']['input'];
};

export type MessageSettingsOptionsInput = {
  dynamicPrompts?: InputMaybe<Scalars['Boolean']['input']>;
  noLLM?: InputMaybe<Scalars['Boolean']['input']>;
  webSearch?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageSubscriptionInput = {
  conversationId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum MessageType {
  CallReportsInput = 'CALL_REPORTS_INPUT',
  CallTranscriptInput = 'CALL_TRANSCRIPT_INPUT',
  DocumentInput = 'DOCUMENT_INPUT',
  FactCheckResponse = 'FACT_CHECK_RESPONSE',
  Image = 'IMAGE',
  LearningMaterial = 'LEARNING_MATERIAL',
  SmeResponse = 'SME_RESPONSE',
  TestCasesInput = 'TEST_CASES_INPUT',
  Text = 'TEXT',
  TimesheetInput = 'TIMESHEET_INPUT'
}

export type ModelPrompt = {
  __typename?: 'ModelPrompt';
  category?: Maybe<PromptTemplateCategory>;
  model: ModelType;
  prompt: Scalars['String']['output'];
  variables?: Maybe<Array<Scalars['String']['output']>>;
};

export type ModelPromptInput = {
  category?: InputMaybe<PromptTemplateCategory>;
  model: ModelType;
  prompt: Scalars['String']['input'];
  variables?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ModelType {
  Claude = 'CLAUDE',
  Cohere = 'COHERE',
  GoogleAi = 'GOOGLE_AI',
  Mistral = 'MISTRAL',
  OpenAi = 'OPEN_AI',
  OpenAiOmni = 'OPEN_AI_OMNI'
}

export type Mutation = {
  __typename?: 'Mutation';
  actionOnDislikedMessage?: Maybe<Scalars['String']['output']>;
  addActivityLog?: Maybe<ActivityLogMessage>;
  addCommonMessage?: Maybe<AiaCommonMessage>;
  addFactCheckMessage?: Maybe<Message>;
  addFeedback?: Maybe<Scalars['String']['output']>;
  addKPLConfiguration?: Maybe<ApplicationConfig>;
  addMessage?: Maybe<Message>;
  addMessageFeedback?: Maybe<Message>;
  addPromptTemplate?: Maybe<PromptTemplate>;
  addSMEMessage?: Maybe<AiaMessageSmeResponse>;
  analyzeCallTranscript?: Maybe<Scalars['String']['output']>;
  analyzeTestCases?: Maybe<Scalars['String']['output']>;
  approveActivityLog?: Maybe<ActivityLog>;
  clearCache?: Maybe<Scalars['String']['output']>;
  compareLLMResponses?: Maybe<Scalars['String']['output']>;
  createCustomKPL?: Maybe<ApplicationConfig>;
  createNewUser?: Maybe<Scalars['String']['output']>;
  deleteDocument?: Maybe<Scalars['String']['output']>;
  deleteFaissIndex?: Maybe<Scalars['String']['output']>;
  deletePromptTemplate?: Maybe<Scalars['String']['output']>;
  enhancePrompt?: Maybe<Scalars['String']['output']>;
  generateCodeFromRequirements?: Maybe<Scalars['String']['output']>;
  generateJiraTickets?: Maybe<Scalars['String']['output']>;
  generateQuestionsForCustomKPL?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  generateRequirements?: Maybe<Scalars['String']['output']>;
  generateSprintAnalysisReport?: Maybe<SprintAnalysisReport>;
  getHighlightedPDF?: Maybe<HighlightPdfResponse>;
  markMessagesAsRead?: Maybe<Array<Maybe<AiaCommonMessage>>>;
  removeKPLConfiguration?: Maybe<Scalars['String']['output']>;
  saveNewCallReports?: Maybe<Array<Maybe<CallReportOptions>>>;
  saveNewDocuments?: Maybe<Scalars['String']['output']>;
  stopGeneration?: Maybe<Scalars['String']['output']>;
  updateConversation?: Maybe<Conversation>;
  updateCustomKPL?: Maybe<ApplicationConfig>;
  updateKPLConfiguration?: Maybe<ApplicationConfig>;
  updateKPLRequestUserStatus?: Maybe<Scalars['String']['output']>;
  updatePromptTemplate?: Maybe<PromptTemplate>;
  updateUser?: Maybe<Scalars['String']['output']>;
  uploadTimesheet?: Maybe<Scalars['String']['output']>;
  upsertParticipant?: Maybe<User>;
};


export type MutationActionOnDislikedMessageArgs = {
  input: ActionOnDislikedMessageInput;
};


export type MutationAddActivityLogArgs = {
  input: AddActivityLogInput;
};


export type MutationAddCommonMessageArgs = {
  input: AddCommonMessageInput;
};


export type MutationAddFactCheckMessageArgs = {
  input: AddFactCheckMessageInput;
};


export type MutationAddFeedbackArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  input?: InputMaybe<AddFeedbackInput>;
};


export type MutationAddKplConfigurationArgs = {
  input: KplConfigurationInput;
};


export type MutationAddMessageArgs = {
  input: AddMessageInput;
};


export type MutationAddMessageFeedbackArgs = {
  input: AddMessageFeedbackInput;
};


export type MutationAddPromptTemplateArgs = {
  input: CreatePromptTemplateInput;
};


export type MutationAddSmeMessageArgs = {
  input: AddSmeMessageInput;
};


export type MutationAnalyzeCallTranscriptArgs = {
  input: AnalyzeCallTranscriptInput;
};


export type MutationAnalyzeTestCasesArgs = {
  input: AnalyzeTestCasesInput;
};


export type MutationApproveActivityLogArgs = {
  input: AddApproveLogInput;
};


export type MutationCompareLlmResponsesArgs = {
  input?: InputMaybe<LlmComparisonInput>;
};


export type MutationCreateCustomKplArgs = {
  input: CustomKplCreateConfigurationInput;
};


export type MutationCreateNewUserArgs = {
  input: NewUserInput;
};


export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};


export type MutationDeleteFaissIndexArgs = {
  input: DeleteIndexInput;
};


export type MutationDeletePromptTemplateArgs = {
  id: Scalars['Int']['input'];
};


export type MutationEnhancePromptArgs = {
  prompt?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateCodeFromRequirementsArgs = {
  input: GenerateCodeFromRequirementsInput;
};


export type MutationGenerateJiraTicketsArgs = {
  input: GenerateJiraTicketsInput;
};


export type MutationGenerateQuestionsForCustomKplArgs = {
  kplConfigKey: Scalars['String']['input'];
};


export type MutationGenerateRequirementsArgs = {
  input?: InputMaybe<GenerateRequirementsInput>;
};


export type MutationGenerateSprintAnalysisReportArgs = {
  input: GenerateSprintAnalysisReportInput;
};


export type MutationGetHighlightedPdfArgs = {
  input: GetHighlightedPdfInput;
};


export type MutationMarkMessagesAsReadArgs = {
  input: MarkMessageAsReadInput;
};


export type MutationRemoveKplConfigurationArgs = {
  configKey: Scalars['String']['input'];
};


export type MutationSaveNewCallReportsArgs = {
  input: SaveNewDocumentsInput;
};


export type MutationSaveNewDocumentsArgs = {
  input: SaveNewDocumentsInput;
};


export type MutationStopGenerationArgs = {
  input: StopGenerationInput;
};


export type MutationUpdateConversationArgs = {
  input: UpdateConversationInput;
};


export type MutationUpdateCustomKplArgs = {
  input: CustomKplCreateConfigurationInput;
};


export type MutationUpdateKplConfigurationArgs = {
  input: KplConfigurationInput;
};


export type MutationUpdateKplRequestUserStatusArgs = {
  input: UpdateKplRequestUserStatusInput;
};


export type MutationUpdatePromptTemplateArgs = {
  input: UpdatePromptTemplateInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadTimesheetArgs = {
  input: AddTimesheetInput;
};


export type MutationUpsertParticipantArgs = {
  input: UpsertUserInput;
};

export type NewUserInput = {
  email: Scalars['String']['input'];
  features?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName: Scalars['String']['input'];
  isGuestUser?: InputMaybe<Scalars['Boolean']['input']>;
  isTempPassword: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  metadata: Scalars['JSONObject']['input'];
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  override: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
};

export enum OperationType {
  And = 'AND',
  Or = 'OR'
}

export type PaginatedGroupedConversationResult = {
  __typename?: 'PaginatedGroupedConversationResult';
  conversations: Array<GroupedConversationResult>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaginatedKplConfigurationsResult = {
  __typename?: 'PaginatedKPLConfigurationsResult';
  kpls: Array<ApplicationConfig>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaginatedKplRequestUsersResult = {
  __typename?: 'PaginatedKPLRequestUsersResult';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
  users: Array<KplRequestUser>;
};

export type PaginatedPromptTemplateResult = {
  __typename?: 'PaginatedPromptTemplateResult';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  promptTemplates: Array<PromptTemplate>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaginatedUserConfigurationsResult = {
  __typename?: 'PaginatedUserConfigurationsResult';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
  users: Array<CustomApplicationUserConfig>;
};

export type Problem = {
  __typename?: 'Problem';
  id?: Maybe<Scalars['ID']['output']>;
  problem?: Maybe<Scalars['String']['output']>;
};

export type PromptTemplate = {
  __typename?: 'PromptTemplate';
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  templateContent: Scalars['String']['output'];
  templateKey: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variables?: Maybe<Scalars['JSONArray']['output']>;
};

export enum PromptTemplateCategory {
  ChatPrompt = 'CHAT_PROMPT',
  ChatResponsePrompt = 'CHAT_RESPONSE_PROMPT',
  ChatSummaryPrompt = 'CHAT_SUMMARY_PROMPT',
  CustomDataGenerationPrompt = 'CUSTOM_DATA_GENERATION_PROMPT',
  QuestionAnswerDataGenerationPrompt = 'QUESTION_ANSWER_DATA_GENERATION_PROMPT',
  RawPrompt = 'RAW_PROMPT',
  ResponsePrompt = 'RESPONSE_PROMPT'
}

export type Query = {
  __typename?: 'Query';
  getActivityLogs?: Maybe<Array<Maybe<ActivityLog>>>;
  getAllKPLConfigurations?: Maybe<PaginatedKplConfigurationsResult>;
  getAllKPLRequestUsers?: Maybe<PaginatedKplRequestUsersResult>;
  getAllPromptTemplates?: Maybe<Array<Maybe<PromptTemplate>>>;
  getAllUsers?: Maybe<PaginatedUserConfigurationsResult>;
  getApplicationConfig?: Maybe<Array<Maybe<ApplicationConfig>>>;
  getApplicationUserConfig?: Maybe<Array<Maybe<ApplicationUserConfig>>>;
  getApplicationUserConfigByEmail?: Maybe<ApplicationUserConfig>;
  getConversationHistory?: Maybe<PaginatedGroupedConversationResult>;
  getExistingKPLUsers?: Maybe<ExistingUsers>;
  getFollowUpQuestions?: Maybe<Array<Maybe<FollowUpQuestion>>>;
  getJiraSettings?: Maybe<Array<Maybe<JiraProjects>>>;
  getKPLByConfigKey?: Maybe<DetailedKplConfiguration>;
  getKPLGuestUserMetaData?: Maybe<GuestUserMetaData>;
  getKplGuidelines?: Maybe<Array<Maybe<KplGuideline>>>;
  getLiveCustomerAgentDetails?: Maybe<LiveCustomerAgentDetails>;
  getMessages?: Maybe<Array<Maybe<Message>>>;
  getParticipant?: Maybe<User>;
  getParticipantByEmail?: Maybe<User>;
  getPromptTemplateByCriteria?: Maybe<PaginatedPromptTemplateResult>;
  getPromptTemplateById?: Maybe<PromptTemplate>;
  getPromptTemplateByKey?: Maybe<PromptTemplate>;
  getSMEMessagesResponses?: Maybe<Array<Maybe<Message>>>;
  getSourceChunks?: Maybe<Scalars['String']['output']>;
  getUserByUserName?: Maybe<CustomApplicationUserConfig>;
};


export type QueryGetActivityLogsArgs = {
  input: GetActivityLogsInput;
};


export type QueryGetAllKplConfigurationsArgs = {
  input: GetAllKplConfigurationsInput;
};


export type QueryGetAllKplRequestUsersArgs = {
  input: GetAllKplRequestUsersInput;
};


export type QueryGetAllUsersArgs = {
  input: GetAllUserConfigurationsInput;
};


export type QueryGetApplicationConfigArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetApplicationUserConfigByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetConversationHistoryArgs = {
  input: GetConversationHistoryInput;
};


export type QueryGetFollowUpQuestionsArgs = {
  input: GetFollowUpQuestionsInput;
};


export type QueryGetJiraSettingsArgs = {
  input: GetJiraSettingsInput;
};


export type QueryGetKplByConfigKeyArgs = {
  configKey: Scalars['String']['input'];
};


export type QueryGetKplGuestUserMetaDataArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetKplGuidelinesArgs = {
  input: GetKplGuidelinesInput;
};


export type QueryGetMessagesArgs = {
  input: GetMessageInput;
};


export type QueryGetParticipantArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetParticipantByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetPromptTemplateByCriteriaArgs = {
  criteria: GetPromptTemplateByCriteriaInput;
};


export type QueryGetPromptTemplateByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPromptTemplateByKeyArgs = {
  templateKey: Scalars['String']['input'];
};


export type QueryGetSourceChunksArgs = {
  sourceId: Scalars['ID']['input'];
};


export type QueryGetUserByUserNameArgs = {
  email: Scalars['String']['input'];
};

export enum RequirementType {
  Business = 'BUSINESS',
  Technical = 'TECHNICAL'
}

export type SaveNewDocumentsInput = {
  conversationId: Scalars['ID']['input'];
  feature?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['Upload']['input']>;
  type?: InputMaybe<DocumentPersistenceType>;
  userId: Scalars['ID']['input'];
};

export type Session = {
  __typename?: 'Session';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Source = {
  __typename?: 'Source';
  domainUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sources?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SprintAnalysisReport = {
  __typename?: 'SprintAnalysisReport';
  overview?: Maybe<Scalars['String']['output']>;
  report?: Maybe<Scalars['String']['output']>;
};

export type StopGenerationInput = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newMessage?: Maybe<Array<Maybe<Message>>>;
  newWorklog?: Maybe<Array<Maybe<ActivityLogMessage>>>;
};


export type SubscriptionNewMessageArgs = {
  input: MessageSubscriptionInput;
};


export type SubscriptionNewWorklogArgs = {
  input: WorklogSubscriptionInput;
};

export type Task = {
  __typename?: 'Task';
  duration?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  task?: Maybe<Scalars['String']['output']>;
  technologies?: Maybe<Array<Maybe<TaskTechnology>>>;
};

export type TaskTechnology = {
  __typename?: 'TaskTechnology';
  id?: Maybe<Scalars['ID']['output']>;
  technology?: Maybe<Scalars['String']['output']>;
};

export type UpdateConversationInput = {
  conversationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateKplRequestUserStatusInput = {
  email: Scalars['String']['input'];
  status: KplRequestUserStatus;
};

export type UpdatePromptTemplateInput = {
  category?: InputMaybe<PromptTemplateCategory>;
  id: Scalars['Int']['input'];
  kpl?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  templateKey?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateUserInput = {
  email: Scalars['String']['input'];
  features?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  firstName: Scalars['String']['input'];
  isGuestUser?: InputMaybe<Scalars['Boolean']['input']>;
  isTempPassword?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  metadata: Scalars['JSONObject']['input'];
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  override: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  passwordUpdated: Scalars['Boolean']['input'];
};

export type UpsertUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  activatedDate?: Maybe<Scalars['DateTime']['output']>;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  status?: Maybe<UserStatus>;
  userType?: Maybe<ApplicationUserType>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum UserType {
  Domain = 'DOMAIN',
  User = 'USER'
}

export type WorklogSubscriptionInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type AddMessageMutationVariables = Exact<{
  input: AddMessageInput;
}>;


export type AddMessageMutation = { __typename?: 'Mutation', addMessage?: { __typename?: 'Message', id?: string | null } | null };

export type UpsertParticipantMutationVariables = Exact<{
  input: UpsertUserInput;
}>;


export type UpsertParticipantMutation = { __typename?: 'Mutation', upsertParticipant?: { __typename?: 'User', id?: string | null } | null };

export type AddActivityLogMutationVariables = Exact<{
  input: AddActivityLogInput;
}>;


export type AddActivityLogMutation = { __typename?: 'Mutation', addActivityLog?: { __typename?: 'ActivityLogMessage', id?: string | null } | null };

export type ApproveActivityLogMutationVariables = Exact<{
  input: AddApproveLogInput;
}>;


export type ApproveActivityLogMutation = { __typename?: 'Mutation', approveActivityLog?: { __typename?: 'ActivityLog', id?: string | null } | null };

export type AddFeedbackMutationVariables = Exact<{
  input?: InputMaybe<AddFeedbackInput>;
  file?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type AddFeedbackMutation = { __typename?: 'Mutation', addFeedback?: string | null };

export type AddMessageFeedbackMutationVariables = Exact<{
  input: AddMessageFeedbackInput;
}>;


export type AddMessageFeedbackMutation = { __typename?: 'Mutation', addMessageFeedback?: { __typename?: 'Message', id?: string | null } | null };

export type MarkMessagesAsReadMutationVariables = Exact<{
  input: MarkMessageAsReadInput;
}>;


export type MarkMessagesAsReadMutation = { __typename?: 'Mutation', markMessagesAsRead?: Array<{ __typename?: 'AiaCommonMessage', id?: string | null } | null> | null };

export type UpdateConversationMutationVariables = Exact<{
  input: UpdateConversationInput;
}>;


export type UpdateConversationMutation = { __typename?: 'Mutation', updateConversation?: { __typename?: 'Conversation', id?: string | null } | null };

export type ActionOnDislikedMessageMutationVariables = Exact<{
  input: ActionOnDislikedMessageInput;
}>;


export type ActionOnDislikedMessageMutation = { __typename?: 'Mutation', actionOnDislikedMessage?: string | null };

export type GenerateRequirementsMutationVariables = Exact<{
  input?: InputMaybe<GenerateRequirementsInput>;
}>;


export type GenerateRequirementsMutation = { __typename?: 'Mutation', generateRequirements?: string | null };

export type GenerateJiraTicketsMutationVariables = Exact<{
  input: GenerateJiraTicketsInput;
}>;


export type GenerateJiraTicketsMutation = { __typename?: 'Mutation', generateJiraTickets?: string | null };

export type GenerateCodeFromRequirementsMutationVariables = Exact<{
  input: GenerateCodeFromRequirementsInput;
}>;


export type GenerateCodeFromRequirementsMutation = { __typename?: 'Mutation', generateCodeFromRequirements?: string | null };

export type StopGenerationMutationVariables = Exact<{
  input: StopGenerationInput;
}>;


export type StopGenerationMutation = { __typename?: 'Mutation', stopGeneration?: string | null };

export type SaveNewDocumentsMutationVariables = Exact<{
  input: SaveNewDocumentsInput;
}>;


export type SaveNewDocumentsMutation = { __typename?: 'Mutation', saveNewDocuments?: string | null };

export type DeleteDocumentMutationVariables = Exact<{
  input: DeleteDocumentInput;
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument?: string | null };

export type DeleteFaissIndexMutationVariables = Exact<{
  input: DeleteIndexInput;
}>;


export type DeleteFaissIndexMutation = { __typename?: 'Mutation', deleteFaissIndex?: string | null };

export type GenerateSprintAnalysisReportMutationVariables = Exact<{
  input: GenerateSprintAnalysisReportInput;
}>;


export type GenerateSprintAnalysisReportMutation = { __typename?: 'Mutation', generateSprintAnalysisReport?: { __typename?: 'SprintAnalysisReport', overview?: string | null, report?: string | null } | null };

export type UploadTimesheetMutationVariables = Exact<{
  input: AddTimesheetInput;
}>;


export type UploadTimesheetMutation = { __typename?: 'Mutation', uploadTimesheet?: string | null };

export type SaveNewCallReportsMutationVariables = Exact<{
  input: SaveNewDocumentsInput;
}>;


export type SaveNewCallReportsMutation = { __typename?: 'Mutation', saveNewCallReports?: Array<{ __typename?: 'CallReportOptions', option?: string | null, question?: string | null, enabled?: boolean | null } | null> | null };

export type AnalyzeCallTranscriptMutationVariables = Exact<{
  input: AnalyzeCallTranscriptInput;
}>;


export type AnalyzeCallTranscriptMutation = { __typename?: 'Mutation', analyzeCallTranscript?: string | null };

export type AnalyzeTestCasesMutationVariables = Exact<{
  input: AnalyzeTestCasesInput;
}>;


export type AnalyzeTestCasesMutation = { __typename?: 'Mutation', analyzeTestCases?: string | null };

export type AddFactCheckMessageMutationVariables = Exact<{
  input: AddFactCheckMessageInput;
}>;


export type AddFactCheckMessageMutation = { __typename?: 'Mutation', addFactCheckMessage?: { __typename?: 'Message', id?: string | null, model?: string | null, conversationId?: string | null, content?: string | null, author?: MessageAuthor | null, type?: MessageType | null, sources?: Array<{ __typename?: 'Source', id?: string | null, domainUrl?: string | null, sources?: Array<string | null> | null } | null> | null } | null };

export type CompareLlmResponsesMutationVariables = Exact<{
  input?: InputMaybe<LlmComparisonInput>;
}>;


export type CompareLlmResponsesMutation = { __typename?: 'Mutation', compareLLMResponses?: string | null };

export type GetHighlightedPdfMutationVariables = Exact<{
  input: GetHighlightedPdfInput;
}>;


export type GetHighlightedPdfMutation = { __typename?: 'Mutation', getHighlightedPDF?: { __typename?: 'HighlightPDFResponse', page?: number | null, pdf: string, source?: string | null } | null };

export type RemoveKplConfigurationMutationVariables = Exact<{
  configKey: Scalars['String']['input'];
}>;


export type RemoveKplConfigurationMutation = { __typename?: 'Mutation', removeKPLConfiguration?: string | null };

export type AddKplConfigurationMutationVariables = Exact<{
  input: KplConfigurationInput;
}>;


export type AddKplConfigurationMutation = { __typename?: 'Mutation', addKPLConfiguration?: { __typename?: 'ApplicationConfig', id: number } | null };

export type UpdateKplConfigurationMutationVariables = Exact<{
  input: KplConfigurationInput;
}>;


export type UpdateKplConfigurationMutation = { __typename?: 'Mutation', updateKPLConfiguration?: { __typename?: 'ApplicationConfig', id: number } | null };

export type AddPromptTemplateMutationVariables = Exact<{
  input: CreatePromptTemplateInput;
}>;


export type AddPromptTemplateMutation = { __typename?: 'Mutation', addPromptTemplate?: { __typename?: 'PromptTemplate', id: number } | null };

export type UpdatePromptTemplateMutationVariables = Exact<{
  input: UpdatePromptTemplateInput;
}>;


export type UpdatePromptTemplateMutation = { __typename?: 'Mutation', updatePromptTemplate?: { __typename?: 'PromptTemplate', id: number } | null };

export type DeletePromptTemplateMutationVariables = Exact<{
  deletePromptTemplateId: Scalars['Int']['input'];
}>;


export type DeletePromptTemplateMutation = { __typename?: 'Mutation', deletePromptTemplate?: string | null };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: string | null };

export type CreateNewUserMutationVariables = Exact<{
  input: NewUserInput;
}>;


export type CreateNewUserMutation = { __typename?: 'Mutation', createNewUser?: string | null };

export type UpdateKplRequestUserStatusMutationVariables = Exact<{
  input: UpdateKplRequestUserStatusInput;
}>;


export type UpdateKplRequestUserStatusMutation = { __typename?: 'Mutation', updateKPLRequestUserStatus?: string | null };

export type EnhancePromptMutationVariables = Exact<{
  prompt?: InputMaybe<Scalars['String']['input']>;
}>;


export type EnhancePromptMutation = { __typename?: 'Mutation', enhancePrompt?: string | null };

export type CreateCustomKplMutationVariables = Exact<{
  input: CustomKplCreateConfigurationInput;
}>;


export type CreateCustomKplMutation = { __typename?: 'Mutation', createCustomKPL?: { __typename?: 'ApplicationConfig', title: string, metadata: any, id: number, icon: string, enabled: boolean, description: string, configKey: string, chatType: ChatType } | null };

export type GenerateQuestionsForCustomKplMutationVariables = Exact<{
  kplConfigKey: Scalars['String']['input'];
}>;


export type GenerateQuestionsForCustomKplMutation = { __typename?: 'Mutation', generateQuestionsForCustomKPL?: Array<string | null> | null };

export type UpdateCustomKplMutationVariables = Exact<{
  input: CustomKplCreateConfigurationInput;
}>;


export type UpdateCustomKplMutation = { __typename?: 'Mutation', updateCustomKPL?: { __typename?: 'ApplicationConfig', id: number } | null };

export type GetMessagesQueryVariables = Exact<{
  input: GetMessageInput;
}>;


export type GetMessagesQuery = { __typename?: 'Query', getMessages?: Array<{ __typename?: 'Message', id?: string | null, replyTo?: string | null, content?: string | null, author?: MessageAuthor | null } | null> | null };

export type GetParticipantQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetParticipantQuery = { __typename?: 'Query', getParticipant?: { __typename?: 'User', id?: string | null, status?: UserStatus | null, userType?: ApplicationUserType | null, conversations?: Array<{ __typename?: 'Conversation', id?: string | null, title?: string | null, updatedAt?: any | null, threadId?: string | null, threadType?: string | null } | null> | null } | null };

export type GetSourceChunksQueryVariables = Exact<{
  sourceId: Scalars['ID']['input'];
}>;


export type GetSourceChunksQuery = { __typename?: 'Query', getSourceChunks?: string | null };

export type GetApplicationConfigQueryVariables = Exact<{
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetApplicationConfigQuery = { __typename?: 'Query', getApplicationConfig?: Array<{ __typename?: 'ApplicationConfig', configKey: string, chatType: ChatType, description: string, icon: string, id: number, enabled: boolean, metadata: any, title: string } | null> | null };

export type GetApplicationUserConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApplicationUserConfigQuery = { __typename?: 'Query', getApplicationUserConfig?: Array<{ __typename?: 'ApplicationUserConfig', email: string, features: string, metadata: any, models: string, override: boolean } | null> | null };

export type GetApplicationUserConfigByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetApplicationUserConfigByEmailQuery = { __typename?: 'Query', getApplicationUserConfigByEmail?: { __typename?: 'ApplicationUserConfig', email: string, features: string, metadata: any, models: string, override: boolean } | null };

export type GetKplGuidelinesQueryVariables = Exact<{
  input: GetKplGuidelinesInput;
}>;


export type GetKplGuidelinesQuery = { __typename?: 'Query', getKplGuidelines?: Array<{ __typename?: 'KplGuideline', guidelinesText: string } | null> | null };

export type GetJiraSettingsQueryVariables = Exact<{
  input: GetJiraSettingsInput;
}>;


export type GetJiraSettingsQuery = { __typename?: 'Query', getJiraSettings?: Array<{ __typename?: 'JiraProjects', id: string, key: string, name: string, sprints?: Array<{ __typename?: 'JiraSprints', id: string, name: string, originBoardId: string, state: string } | null> | null } | null> | null };

export type GetLiveCustomerAgentDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLiveCustomerAgentDetailsQuery = { __typename?: 'Query', getLiveCustomerAgentDetails?: { __typename?: 'LiveCustomerAgentDetails', audioFileUrl: string, transcript: Array<{ __typename?: 'LiveCustomerAgentTranscript', message: string, time: number, type: string }> } | null };

export type GetAllKplConfigurationsQueryVariables = Exact<{
  input: GetAllKplConfigurationsInput;
}>;


export type GetAllKplConfigurationsQuery = { __typename?: 'Query', getAllKPLConfigurations?: { __typename?: 'PaginatedKPLConfigurationsResult', page: number, pageSize: number, totalCount: number, totalPages: number, kpls: Array<{ __typename?: 'ApplicationConfig', id: number, enabled: boolean, configKey: string, description: string, chatType: ChatType, metadata: any, title: string, icon: string, createdAt?: any | null }> } | null };

export type GetKplByConfigKeyQueryVariables = Exact<{
  configKey: Scalars['String']['input'];
}>;


export type GetKplByConfigKeyQuery = { __typename?: 'Query', getKPLByConfigKey?: { __typename?: 'DetailedKPLConfiguration', id: number, icon: string, enabled: boolean, description: string, configKey: string, chatType: ChatType, metadata: any, title: string, domains?: Array<string | null> | null, users?: Array<string | null> | null, guidelines?: Array<{ __typename?: 'KplGuideline', guidelinesText: string } | null> | null, prompts?: Array<{ __typename?: 'ModelPrompt', model: ModelType, category?: PromptTemplateCategory | null, prompt: string, variables?: Array<string> | null } | null> | null } | null };

export type GetExistingKplUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExistingKplUsersQuery = { __typename?: 'Query', getExistingKPLUsers?: { __typename?: 'ExistingUsers', domains?: Array<string> | null, users?: Array<string> | null } | null };

export type GetPromptTemplateByCriteriaQueryVariables = Exact<{
  criteria: GetPromptTemplateByCriteriaInput;
}>;


export type GetPromptTemplateByCriteriaQuery = { __typename?: 'Query', getPromptTemplateByCriteria?: { __typename?: 'PaginatedPromptTemplateResult', page: number, pageSize: number, totalCount: number, totalPages: number, promptTemplates: Array<{ __typename?: 'PromptTemplate', category?: string | null, id: number, metadata?: any | null, templateContent: string, templateKey: string, variables?: any | null }> } | null };

export type GetAllUsersQueryVariables = Exact<{
  input: GetAllUserConfigurationsInput;
}>;


export type GetAllUsersQuery = { __typename?: 'Query', getAllUsers?: { __typename?: 'PaginatedUserConfigurationsResult', totalPages: number, totalCount: number, pageSize: number, page: number, users: Array<{ __typename?: 'CustomApplicationUserConfig', metadata: any, lastName: string, firstName: string, features: Array<string>, email: string, models: Array<string>, override: boolean }> } | null };

export type GetUserByUserNameQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetUserByUserNameQuery = { __typename?: 'Query', getUserByUserName?: { __typename?: 'CustomApplicationUserConfig', firstName: string, lastName: string, email: string, models: Array<string>, features: Array<string>, metadata: any, override: boolean, status?: UserStatus | null, userType?: ApplicationUserType | null } | null };

export type GetAllKplRequestUsersQueryVariables = Exact<{
  input: GetAllKplRequestUsersInput;
}>;


export type GetAllKplRequestUsersQuery = { __typename?: 'Query', getAllKPLRequestUsers?: { __typename?: 'PaginatedKPLRequestUsersResult', page: number, pageSize: number, totalPages: number, totalCount: number, users: Array<{ __typename?: 'KPLRequestUser', id: number, status: KplRequestUserStatus, user?: { __typename?: 'User', email?: string | null, status?: UserStatus | null, firstName?: string | null, lastName?: string | null, activatedDate?: any | null } | null }> } | null };

export type GetKplGuestUserMetaDataQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type GetKplGuestUserMetaDataQuery = { __typename?: 'Query', getKPLGuestUserMetaData?: { __typename?: 'GuestUserMetaData', guestKPLNames?: Array<string | null> | null, isGuestKPLCreated?: boolean | null, remainingDays?: number | null, tokenUsage?: number | null, totalDocumentCount?: number | null, totalDocumentSize?: number | null, kplUserDocument?: Array<any | null> | null, questions?: Array<string | null> | null, metaDataconfigs?: any | null } | null };

export type GetPromptTemplateByKeyQueryVariables = Exact<{
  templateKey: Scalars['String']['input'];
}>;


export type GetPromptTemplateByKeyQuery = { __typename?: 'Query', getPromptTemplateByKey?: { __typename?: 'PromptTemplate', category?: string | null, createdAt?: any | null, id: number, metadata?: any | null, templateContent: string, templateKey: string, updatedAt?: any | null, variables?: any | null } | null };

export type NewMessageSubscriptionVariables = Exact<{
  input: MessageSubscriptionInput;
}>;


export type NewMessageSubscription = { __typename?: 'Subscription', newMessage?: Array<{ __typename?: 'Message', id?: string | null, content?: string | null, previousContent?: string | null, author?: MessageAuthor | null, processing?: boolean | null, feedback?: boolean | null, type?: MessageType | null, sources?: Array<{ __typename?: 'Source', id?: string | null, sources?: Array<string | null> | null, domainUrl?: string | null } | null> | null } | null> | null };

export type NewWorklogSubscriptionVariables = Exact<{
  input: WorklogSubscriptionInput;
}>;


export type NewWorklogSubscription = { __typename?: 'Subscription', newWorklog?: Array<{ __typename?: 'ActivityLogMessage', id?: string | null, isUser?: boolean | null, description?: string | null, showApprove?: boolean | null, type?: ActivityLogMessageType | null } | null> | null };


export const AddMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddMessageMutation, AddMessageMutationVariables>;
export const UpsertParticipantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpsertParticipant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertParticipant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpsertParticipantMutation, UpsertParticipantMutationVariables>;
export const AddActivityLogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddActivityLog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddActivityLogInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addActivityLog"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddActivityLogMutation, AddActivityLogMutationVariables>;
export const ApproveActivityLogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApproveActivityLog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddApproveLogInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approveActivityLog"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ApproveActivityLogMutation, ApproveActivityLogMutationVariables>;
export const AddFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AddFeedbackInput"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"file"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}},{"kind":"Argument","name":{"kind":"Name","value":"file"},"value":{"kind":"Variable","name":{"kind":"Name","value":"file"}}}]}]}}]} as unknown as DocumentNode<AddFeedbackMutation, AddFeedbackMutationVariables>;
export const AddMessageFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddMessageFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddMessageFeedbackInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addMessageFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddMessageFeedbackMutation, AddMessageFeedbackMutationVariables>;
export const MarkMessagesAsReadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MarkMessagesAsRead"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MarkMessageAsReadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"markMessagesAsRead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MarkMessagesAsReadMutation, MarkMessagesAsReadMutationVariables>;
export const UpdateConversationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateConversation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateConversationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateConversation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateConversationMutation, UpdateConversationMutationVariables>;
export const ActionOnDislikedMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ActionOnDislikedMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ActionOnDislikedMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"actionOnDislikedMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<ActionOnDislikedMessageMutation, ActionOnDislikedMessageMutationVariables>;
export const GenerateRequirementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateRequirements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateRequirementsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateRequirements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GenerateRequirementsMutation, GenerateRequirementsMutationVariables>;
export const GenerateJiraTicketsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateJiraTickets"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateJiraTicketsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateJiraTickets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GenerateJiraTicketsMutation, GenerateJiraTicketsMutationVariables>;
export const GenerateCodeFromRequirementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateCodeFromRequirements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateCodeFromRequirementsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateCodeFromRequirements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<GenerateCodeFromRequirementsMutation, GenerateCodeFromRequirementsMutationVariables>;
export const StopGenerationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StopGeneration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StopGenerationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stopGeneration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<StopGenerationMutation, StopGenerationMutationVariables>;
export const SaveNewDocumentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"saveNewDocuments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveNewDocumentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveNewDocuments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<SaveNewDocumentsMutation, SaveNewDocumentsMutationVariables>;
export const DeleteDocumentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteDocument"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteDocumentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteDocument"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteFaissIndexDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteFaissIndex"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteIndexInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteFaissIndex"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<DeleteFaissIndexMutation, DeleteFaissIndexMutationVariables>;
export const GenerateSprintAnalysisReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateSprintAnalysisReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateSprintAnalysisReportInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateSprintAnalysisReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"overview"}},{"kind":"Field","name":{"kind":"Name","value":"report"}}]}}]}}]} as unknown as DocumentNode<GenerateSprintAnalysisReportMutation, GenerateSprintAnalysisReportMutationVariables>;
export const UploadTimesheetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadTimesheet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddTimesheetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadTimesheet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UploadTimesheetMutation, UploadTimesheetMutationVariables>;
export const SaveNewCallReportsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SaveNewCallReports"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveNewDocumentsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveNewCallReports"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"option"}},{"kind":"Field","name":{"kind":"Name","value":"question"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]} as unknown as DocumentNode<SaveNewCallReportsMutation, SaveNewCallReportsMutationVariables>;
export const AnalyzeCallTranscriptDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AnalyzeCallTranscript"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnalyzeCallTranscriptInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"analyzeCallTranscript"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AnalyzeCallTranscriptMutation, AnalyzeCallTranscriptMutationVariables>;
export const AnalyzeTestCasesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AnalyzeTestCases"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnalyzeTestCasesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"analyzeTestCases"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AnalyzeTestCasesMutation, AnalyzeTestCasesMutationVariables>;
export const AddFactCheckMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddFactCheckMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddFactCheckMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addFactCheckMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"model"}},{"kind":"Field","name":{"kind":"Name","value":"conversationId"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"sources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"domainUrl"}},{"kind":"Field","name":{"kind":"Name","value":"sources"}}]}}]}}]}}]} as unknown as DocumentNode<AddFactCheckMessageMutation, AddFactCheckMessageMutationVariables>;
export const CompareLlmResponsesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CompareLLMResponses"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"LLMComparisonInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"compareLLMResponses"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<CompareLlmResponsesMutation, CompareLlmResponsesMutationVariables>;
export const GetHighlightedPdfDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetHighlightedPDF"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetHighlightedPDFInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getHighlightedPDF"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"page"}},{"kind":"Field","name":{"kind":"Name","value":"pdf"}},{"kind":"Field","name":{"kind":"Name","value":"source"}}]}}]}}]} as unknown as DocumentNode<GetHighlightedPdfMutation, GetHighlightedPdfMutationVariables>;
export const RemoveKplConfigurationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveKPLConfiguration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"configKey"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeKPLConfiguration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"configKey"},"value":{"kind":"Variable","name":{"kind":"Name","value":"configKey"}}}]}]}}]} as unknown as DocumentNode<RemoveKplConfigurationMutation, RemoveKplConfigurationMutationVariables>;
export const AddKplConfigurationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddKPLConfiguration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"KPLConfigurationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addKPLConfiguration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddKplConfigurationMutation, AddKplConfigurationMutationVariables>;
export const UpdateKplConfigurationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateKPLConfiguration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"KPLConfigurationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateKPLConfiguration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateKplConfigurationMutation, UpdateKplConfigurationMutationVariables>;
export const AddPromptTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddPromptTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePromptTemplateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addPromptTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddPromptTemplateMutation, AddPromptTemplateMutationVariables>;
export const UpdatePromptTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePromptTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePromptTemplateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePromptTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdatePromptTemplateMutation, UpdatePromptTemplateMutationVariables>;
export const DeletePromptTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePromptTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deletePromptTemplateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePromptTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deletePromptTemplateId"}}}]}]}}]} as unknown as DocumentNode<DeletePromptTemplateMutation, DeletePromptTemplateMutationVariables>;
export const UpdateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateNewUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateNewUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"NewUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createNewUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<CreateNewUserMutation, CreateNewUserMutationVariables>;
export const UpdateKplRequestUserStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateKPLRequestUserStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateKPLRequestUserStatusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateKPLRequestUserStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<UpdateKplRequestUserStatusMutation, UpdateKplRequestUserStatusMutationVariables>;
export const EnhancePromptDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EnhancePrompt"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"prompt"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enhancePrompt"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"prompt"},"value":{"kind":"Variable","name":{"kind":"Name","value":"prompt"}}}]}]}}]} as unknown as DocumentNode<EnhancePromptMutation, EnhancePromptMutationVariables>;
export const CreateCustomKplDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCustomKPL"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CustomKPLCreateConfigurationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCustomKPL"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"icon"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"configKey"}},{"kind":"Field","name":{"kind":"Name","value":"chatType"}}]}}]}}]} as unknown as DocumentNode<CreateCustomKplMutation, CreateCustomKplMutationVariables>;
export const GenerateQuestionsForCustomKplDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateQuestionsForCustomKPL"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"kplConfigKey"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateQuestionsForCustomKPL"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"kplConfigKey"},"value":{"kind":"Variable","name":{"kind":"Name","value":"kplConfigKey"}}}]}]}}]} as unknown as DocumentNode<GenerateQuestionsForCustomKplMutation, GenerateQuestionsForCustomKplMutationVariables>;
export const UpdateCustomKplDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCustomKPL"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CustomKPLCreateConfigurationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCustomKPL"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateCustomKplMutation, UpdateCustomKplMutationVariables>;
export const GetMessagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMessages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"replyTo"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"author"}}]}}]}}]} as unknown as DocumentNode<GetMessagesQuery, GetMessagesQueryVariables>;
export const GetParticipantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetParticipant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getParticipant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"userType"}},{"kind":"Field","name":{"kind":"Name","value":"conversations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"threadId"}},{"kind":"Field","name":{"kind":"Name","value":"threadType"}}]}}]}}]}}]} as unknown as DocumentNode<GetParticipantQuery, GetParticipantQueryVariables>;
export const GetSourceChunksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSourceChunks"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sourceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSourceChunks"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sourceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sourceId"}}}]}]}}]} as unknown as DocumentNode<GetSourceChunksQuery, GetSourceChunksQueryVariables>;
export const GetApplicationConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetApplicationConfig"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getApplicationConfig"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"enabled"},"value":{"kind":"Variable","name":{"kind":"Name","value":"enabled"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"configKey"}},{"kind":"Field","name":{"kind":"Name","value":"chatType"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"icon"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<GetApplicationConfigQuery, GetApplicationConfigQueryVariables>;
export const GetApplicationUserConfigDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetApplicationUserConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getApplicationUserConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"models"}},{"kind":"Field","name":{"kind":"Name","value":"override"}}]}}]}}]} as unknown as DocumentNode<GetApplicationUserConfigQuery, GetApplicationUserConfigQueryVariables>;
export const GetApplicationUserConfigByEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetApplicationUserConfigByEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getApplicationUserConfigByEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"models"}},{"kind":"Field","name":{"kind":"Name","value":"override"}}]}}]}}]} as unknown as DocumentNode<GetApplicationUserConfigByEmailQuery, GetApplicationUserConfigByEmailQueryVariables>;
export const GetKplGuidelinesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetKplGuidelines"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetKplGuidelinesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getKplGuidelines"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"guidelinesText"}}]}}]}}]} as unknown as DocumentNode<GetKplGuidelinesQuery, GetKplGuidelinesQueryVariables>;
export const GetJiraSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetJiraSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetJiraSettingsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getJiraSettings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"sprints"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"originBoardId"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]}}]} as unknown as DocumentNode<GetJiraSettingsQuery, GetJiraSettingsQueryVariables>;
export const GetLiveCustomerAgentDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetLiveCustomerAgentDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLiveCustomerAgentDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"audioFileUrl"}},{"kind":"Field","name":{"kind":"Name","value":"transcript"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"time"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<GetLiveCustomerAgentDetailsQuery, GetLiveCustomerAgentDetailsQueryVariables>;
export const GetAllKplConfigurationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAllKPLConfigurations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAllKPLConfigurationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllKPLConfigurations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"kpls"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"configKey"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"chatType"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"icon"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"page"}},{"kind":"Field","name":{"kind":"Name","value":"pageSize"}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"totalPages"}}]}}]}}]} as unknown as DocumentNode<GetAllKplConfigurationsQuery, GetAllKplConfigurationsQueryVariables>;
export const GetKplByConfigKeyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetKPLByConfigKey"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"configKey"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getKPLByConfigKey"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"configKey"},"value":{"kind":"Variable","name":{"kind":"Name","value":"configKey"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"icon"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"configKey"}},{"kind":"Field","name":{"kind":"Name","value":"chatType"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"domains"}},{"kind":"Field","name":{"kind":"Name","value":"users"}},{"kind":"Field","name":{"kind":"Name","value":"guidelines"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"guidelinesText"}}]}},{"kind":"Field","name":{"kind":"Name","value":"prompts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"model"}},{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"prompt"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]}}]} as unknown as DocumentNode<GetKplByConfigKeyQuery, GetKplByConfigKeyQueryVariables>;
export const GetExistingKplUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExistingKPLUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getExistingKPLUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"domains"}},{"kind":"Field","name":{"kind":"Name","value":"users"}}]}}]}}]} as unknown as DocumentNode<GetExistingKplUsersQuery, GetExistingKplUsersQueryVariables>;
export const GetPromptTemplateByCriteriaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPromptTemplateByCriteria"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"criteria"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetPromptTemplateByCriteriaInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPromptTemplateByCriteria"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"criteria"},"value":{"kind":"Variable","name":{"kind":"Name","value":"criteria"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"page"}},{"kind":"Field","name":{"kind":"Name","value":"pageSize"}},{"kind":"Field","name":{"kind":"Name","value":"promptTemplates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"templateContent"}},{"kind":"Field","name":{"kind":"Name","value":"templateKey"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"totalPages"}}]}}]}}]} as unknown as DocumentNode<GetPromptTemplateByCriteriaQuery, GetPromptTemplateByCriteriaQueryVariables>;
export const GetAllUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAllUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAllUserConfigurationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"models"}},{"kind":"Field","name":{"kind":"Name","value":"override"}}]}},{"kind":"Field","name":{"kind":"Name","value":"totalPages"}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"pageSize"}},{"kind":"Field","name":{"kind":"Name","value":"page"}}]}}]}}]} as unknown as DocumentNode<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetUserByUserNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserByUserName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUserByUserName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"models"}},{"kind":"Field","name":{"kind":"Name","value":"features"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"override"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"userType"}}]}}]}}]} as unknown as DocumentNode<GetUserByUserNameQuery, GetUserByUserNameQueryVariables>;
export const GetAllKplRequestUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAllKPLRequestUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAllKPLRequestUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllKPLRequestUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"page"}},{"kind":"Field","name":{"kind":"Name","value":"pageSize"}},{"kind":"Field","name":{"kind":"Name","value":"totalPages"}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"activatedDate"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetAllKplRequestUsersQuery, GetAllKplRequestUsersQueryVariables>;
export const GetKplGuestUserMetaDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetKPLGuestUserMetaData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getKPLGuestUserMetaData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"guestKPLNames"}},{"kind":"Field","name":{"kind":"Name","value":"isGuestKPLCreated"}},{"kind":"Field","name":{"kind":"Name","value":"remainingDays"}},{"kind":"Field","name":{"kind":"Name","value":"tokenUsage"}},{"kind":"Field","name":{"kind":"Name","value":"totalDocumentCount"}},{"kind":"Field","name":{"kind":"Name","value":"totalDocumentSize"}},{"kind":"Field","name":{"kind":"Name","value":"kplUserDocument"}},{"kind":"Field","name":{"kind":"Name","value":"questions"}},{"kind":"Field","name":{"kind":"Name","value":"metaDataconfigs"}}]}}]}}]} as unknown as DocumentNode<GetKplGuestUserMetaDataQuery, GetKplGuestUserMetaDataQueryVariables>;
export const GetPromptTemplateByKeyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPromptTemplateByKey"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"templateKey"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPromptTemplateByKey"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"templateKey"},"value":{"kind":"Variable","name":{"kind":"Name","value":"templateKey"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"templateContent"}},{"kind":"Field","name":{"kind":"Name","value":"templateKey"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<GetPromptTemplateByKeyQuery, GetPromptTemplateByKeyQueryVariables>;
export const NewMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"NewMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MessageSubscriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"previousContent"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"processing"}},{"kind":"Field","name":{"kind":"Name","value":"feedback"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"sources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sources"}},{"kind":"Field","name":{"kind":"Name","value":"domainUrl"}}]}}]}}]}}]} as unknown as DocumentNode<NewMessageSubscription, NewMessageSubscriptionVariables>;
export const NewWorklogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"NewWorklog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WorklogSubscriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newWorklog"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isUser"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"showApprove"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<NewWorklogSubscription, NewWorklogSubscriptionVariables>;