import { useMutation } from '@apollo/client';
import { Avatar, TextField } from '@kaya/kaya-ui-design-system-pb';
import { useHover } from '@mantine/hooks';
import { useState } from 'react';
import { UPDATE_CONVERSATION } from '../../../graphql';
import { CN, logger } from '../../../utils';
import { Conversation } from '../../../contexts';
import { useMobile } from '../../../hooks';

interface IChatHistoryCardProps {
    id: string;
    selected: boolean;
    onClick: () => void;
    onUpdate: (id: string, title: string) => void;
    chatContent: string;
    icon: string;
}

export const ChatHistoryCard = ({ id, selected, chatContent, icon, onClick, onUpdate }: IChatHistoryCardProps) => {
    const { hovered, ref } = useHover();
    const isMobile = useMobile();
    const [updateConversation] = useMutation(UPDATE_CONVERSATION);
    const [editableTitle, setEditableTitle] = useState(chatContent);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMaxLengthExceeded, setIsMaxLengthExceeded] = useState(false);

    const handleSaveClick = async () => {
        if (isMaxLengthExceeded) {
            setEditableTitle(chatContent);
            return;
        }
        setLoading(true);
        try {
            await updateConversation({
                variables: { input: { conversationId: id, title: editableTitle } },
            });

            onUpdate(id, editableTitle);
        } catch (error) {
            logger.error(error);
        } finally {
            setIsEditing(false);
            setLoading(false);
            setIsMaxLengthExceeded(false);
        }
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setEditableTitle(inputValue);
        setIsMaxLengthExceeded(inputValue.length > 100);
    };

    const handleEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setIsEditing(true);
    };

    const handleEnterClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') handleSaveClick();
    };

    const handleOnBlur = () => handleSaveClick();

    return (
        <div
            ref={ref}
            onClick={onClick}
            className={CN(
                'flex gap-x-2 cursor-pointer rounded-sm justify-start items-center px-4 py-2 hover:bg-B-50 hover:border hover:border-B-600',
                {
                    'border border-B-600 bg-B-50': selected,
                    'border border-white': !selected,
                    '!justify-between': hovered,
                }
            )}
        >
            <div className="size-[40px]">
                <Avatar size="md" shape="square" type="icon" icon={icon} />
            </div>
            {isEditing ? (
                <TextField
                    value={editableTitle}
                    onChange={handleTitleChange}
                    onBlur={handleOnBlur}
                    onKeyDown={handleEnterClick}
                    onClick={event => event.stopPropagation()}
                    view={isMaxLengthExceeded ? 'error' : 'default'}
                    wrapperClassName="w-full"
                    placeholder="Search chat history"
                    autoFocus
                />
            ) : (
                <p className="text-sm text-N-900 w-full break-words truncate" title={chatContent}>
                    {chatContent}
                </p>
            )}
            {(hovered || isMobile) && (
                <button
                    title={isEditing ? 'Save Conversation' : 'Edit Conversation'}
                    className="mx-1 px-2 py-0 rounded-sm"
                    onClick={isEditing ? handleSaveClick : handleEditClick}
                >
                    <i
                        className={`${loading ? 'ri-refresh-line' : isEditing ? 'ri-save-line' : 'ri-edit-box-line'} hover:font-bold`}
                    ></i>
                </button>
            )}
        </div>
    );
};
