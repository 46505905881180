import { useQuery } from '@apollo/client';
import { Tag, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { GET_KPL_BY_KEY } from '../../../../../graphql';
import { CN, uniqueId } from '../../../../../utils';
import { NOT_AVAILABLE_VALUE, SETTING_TYPES } from '../../../../../constants';
import React from 'react';
import { PageLoading } from '../../../full-page-loader';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useMobile } from '../../../../../hooks';

interface IKPLViewProps {
    selectedKPLKey: string;
}

export const KPLView = ({ selectedKPLKey }: IKPLViewProps) => {
    const isMobile = useMobile();
    const { data, loading } = useQuery(GET_KPL_BY_KEY, {
        fetchPolicy: 'network-only',
        variables: {
            configKey: selectedKPLKey,
        },
    });

    const currentKPL = data?.getKPLByConfigKey;

    const settings = SETTING_TYPES.map(setting => ({
        label: setting.name,
        key: setting.key,
        enabled: currentKPL?.metadata?.[setting.key]?.enabled,
    }));

    const renderLists = (list: any[]) => {
        return Number(list?.length) > 0 ? (
            <ul className="list-disc ml-4">
                {list.map((item: string, index: number) => (
                    <li key={index} className="my-1">
                        {item}
                    </li>
                ))}
            </ul>
        ) : (
            NOT_AVAILABLE_VALUE
        );
    };

    const syntaxHighlighterStyles = /* CSS */ `
    code {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    pre {
        overflow: overlay;

        &::-webkit-scrollbar {
            height: 10px;
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(96, 105, 110, 0.718);
            border-radius: 0.5rem;
        }
    }
`;

    return (
        <React.Fragment>
            {loading ? (
                <div className="h-full flex items-center w-full justify-center">
                    <PageLoading title="Please wait, KPL data is loading..." />
                </div>
            ) : (
                <div className="flex flex-col animate-fade animate-once h-full">
                    <div className="px-8 pb-4 pt-4 flex items-center justify-between border-b border-N-200">
                        <div className="flex flex-col gap-[10px]">
                            <p className="text-h5 font-600 text-N-900">{currentKPL?.title || ''} </p>
                            <p className="text-N-400 font-400 text-xs flex items-center gap-1">
                                {currentKPL?.configKey || ''}
                                <Tooltip
                                    id={uniqueId()}
                                    place="bottom"
                                    subText={currentKPL?.metadata?.welcomeMessage || ''}
                                    variant="dark"
                                    tooltipType="detailed"
                                >
                                    <i className="ri-question-line text-sm text-B-700 relative" />
                                </Tooltip>
                            </p>
                        </div>
                        <div className="min-h-8 flex items-center justify-center ">
                            <Tag
                                label={Boolean(currentKPL?.enabled) ? 'Enabled' : 'Disabled'}
                                appearance={Boolean(currentKPL?.enabled) ? 'green' : 'red'}
                                size="small"
                            />
                        </div>
                    </div>
                    <div
                        className={CN('py-6 px-8 flex flex-col gap-y-6 h-full pb-[76px] overflow-auto', {
                            'h-[calc(100dvh-170px)]': isMobile,
                        })}
                    >
                        <div className="flex flex-col gap-y-3">
                            <p className="text-N-900 font-600">KPL Options</p>
                            <div className="flex items-center gap-x-2">
                                {settings.map((setting, index) => (
                                    <Tag
                                        key={index}
                                        label={setting.label}
                                        appearance={setting.enabled ? 'green' : 'neutral'}
                                        hasIcon={true}
                                        icon={setting.enabled ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-N-900 font-600">KPL Description</p>
                            <p className="text-N-600 text-sm font-400 whitespace-pre-line">
                                {currentKPL?.description || ''}
                            </p>
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <p className="text-N-900 font-600">Predefined Questions</p>
                            <p className="text-N-600 text-sm font-400">
                                {renderLists(currentKPL?.guidelines?.map(question => question?.guidelinesText) || [])}
                            </p>
                        </div>

                        <div className="flex flex-col gap-y-3">
                            <p className="text-N-900 font-600">Filter Criteria</p>
                            <div className="z-0">
                                <style>{syntaxHighlighterStyles}</style>
                                <SyntaxHighlighter wrapLines={true} language="json" style={atomDark}>
                                    {currentKPL?.metadata?.filter
                                        ? JSON.stringify(currentKPL.metadata.filter, null, 2)
                                        : '-'}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
