import { useState, useEffect } from 'react';
import {
    ButtonWithMessage,
    ChatMessage,
    CommonMessage,
    FormattedChatMessage,
    ScrollableWrapper,
    TranscriptionUpload,
} from '../../common';
import { useMessageContent } from '../../../hooks/useMessageContent';
import { useChatContext } from '../../../contexts/ChatContext';
import { useLoadingMessages } from './useLoadingMessages';
import { FOLLOW_UP_EMAIL } from './followUpEmail';
import { Button } from '@kaya/kaya-ui-design-system-pb';

type ReferenceFile = {
    id: string;
    source: {
        domainUrl: string;
    };
    fileName: string;
};

const ANALYZE_TRANSCRIPT = {
    analyzeTranscript: {
        initial: 'Analyze Transcript 📊',
        loading: [
            'Establishing connection to the analysis engine 🔗',
            'Extracting text from transcript 📝',
            'Generating insights from the transcript 📈',
            'Compiling transcript analysis report 📄',
        ],
        success: 'Transcript Analyzed Successfully ✅',
    },
};

const REFERENCE_FILES: ReferenceFile[] = [
    {
        id: '1',
        source: {
            domainUrl:
                'https://storage.googleapis.com/kaya-bernie-public-bucket/kaya-aia-knowledge-base-docs/WEALTH_MANAGEMENT_TRANSCRIPT_ANALYSIS/Financial%20Education%20-%20College%20529%20Plans%20-%20Citi%20Global%20Wealth.pdf',
        },
        fileName: 'Financial Education - College 529 Plans - Citi Global Wealth.pdf',
    },
    {
        id: '2',
        source: {
            domainUrl:
                'https://storage.googleapis.com/kaya-bernie-public-bucket/kaya-aia-knowledge-base-docs/WEALTH_MANAGEMENT_TRANSCRIPT_ANALYSIS/Investments%20In%20Real%20Estate_%20Exploring%20The%20Alternatives.pdf',
        },
        fileName: 'Investments In Real Estate_ Exploring The Alternatives.pdf',
    },
    {
        id: '3',
        source: {
            domainUrl:
                'https://storage.googleapis.com/kaya-bernie-public-bucket/kaya-aia-knowledge-base-docs/WEALTH_MANAGEMENT_TRANSCRIPT_ANALYSIS/Learn%20_%20T.%20Rowe%20Price%20College%20Savings%20Plan.pdf',
        },
        fileName: 'Learn _ T. Rowe Price College Savings Plan.pdf',
    },
];

const WealthManagementTranscriptAnalysis = () => {
    const { containerRef } = useMessageContent();
    const { userFirstName } = useChatContext();
    const [fileUploaded, setFileUploaded] = useState<File | null>(null);
    const [disbleButton, setDisableButton] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [analyzeTranscript, setAnalyzeTranscript] = useState({ analyzeTranscript: false });
    const { currentMessage, showLoadingText, progress } = useLoadingMessages(analyzeTranscript, ANALYZE_TRANSCRIPT);

    const handleAnalyzeTranscript = () => {
        setAnalyzeTranscript({ analyzeTranscript: true });
    };

    const handleReferencePDFView = (domainUrl: string) => {
        const url = `${domainUrl}`;
        window.open(url, '_blank');
    };

    const getReference = (fileName: string) => {
        return `${fileName}`;
    };

    const downloadEmail = () => {
        const url = 'https://storage.googleapis.com/kaya-dev-public-bucket/next_steps.eml';
        window.open(url, '_blank');
    };
    useEffect(() => {
        if (progress === 100) {
            setAnalyzeTranscript({ analyzeTranscript: false });
            setDisableButton(true);
            setShowEmail(true);
        }
    }, [progress]);

    return (
        <ScrollableWrapper ref={containerRef}>
            <ChatMessage
                disableCopy
                text={`Hi, ${userFirstName} 👋
                    \n Welcome to Wealth Management Investment Planner! Bernie is here to help you design and optimize your investment strategies with ease.
                    \n- **Upload Your Transcript:** Start by uploading your wealth management transcript or financial details.
                    \n- **Analyze:** Bernie will analyze your portfolio and financial goals, providing tailored investment strategies across various sectors.
                    \n- **Review & Adjust:** Review the detailed recommendations and make any adjustments to suit your preferences and risk tolerance.
                    \n- **Save & Implement:** Save the final plan and track progress or export it for further action.
                    \n _Bernie is here to make your investment planning smooth, smart, and personalized. Let's get started!_`}
            />

            <CommonMessage
                content={<TranscriptionUpload onFileUpload={setFileUploaded} />}
                text="Please upload the transcript"
                isUser={true}
            />

            <ButtonWithMessage
                displayCondition={Boolean(fileUploaded)}
                disabledCondition={disbleButton}
                buttonText="Analyzing Transcript"
                disabledText="Transcript analyzed successfully"
                isLoading={analyzeTranscript.analyzeTranscript && progress < 100} // Show loading if analyzing and progress not complete
                onClick={handleAnalyzeTranscript}
                icon="ri-sparkling-line"
                text="👇 Please click on the button below to analyze the transcript"
            />
            {showLoadingText && (
                <CommonMessage
                    disableCopy
                    text={currentMessage}
                    content={
                        <>
                            <FormattedChatMessage text={currentMessage} />
                            <div className="bg-N-400 rounded-full h-2.5 animate-fade my-1">
                                <div
                                    style={{ width: `${progress}%` }}
                                    className={'bg-B-600 h-2.5 rounded-full animate-pulse'}
                                />
                            </div>
                        </>
                    }
                    isUser={false}
                />
            )}
            {showEmail && (
                <CommonMessage
                    disableCopy
                    text={FOLLOW_UP_EMAIL} // Markdown content of the email
                    isUser={false}
                    content={
                        <>
                            <div>
                                <FormattedChatMessage text={FOLLOW_UP_EMAIL} />
                            </div>
                            <div>
                                <p className="mb-4 text-md font-600">
                                    📚 Please find the referenced research pieces below:
                                </p>
                                <div>
                                    {REFERENCE_FILES.map(file => (
                                        <div
                                            key={file.id}
                                            onClick={() => handleReferencePDFView(file.source.domainUrl)}
                                            className="flex items-center border-B-400 rounded-[4px] border cursor-pointer mb-2"
                                        >
                                            <div className="bg-B-200 px-2 rounded-[3px_0px_0px_3px] text-xs py-[1px] leading-4 pt-1 cursor-pointer text-B-600">
                                                <p>{file.id}</p>
                                            </div>
                                            <div className="px-2 text-xs">
                                                <p className="text-B-700">{getReference(file.fileName)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Button
                                isDisabled={false}
                                isLoading={false}
                                className="bg-B-500 hover:bg-B-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                onClick={() => downloadEmail()}
                                iconBefore="ri-mail-download-line"
                            >
                                Download Email
                            </Button>
                        </>
                    }
                />
            )}
        </ScrollableWrapper>
    );
};

export { WealthManagementTranscriptAnalysis };
