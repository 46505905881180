import 'regenerator-runtime/runtime';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useMessenger, useTimesheet } from '.';
import { ChatType, FeatureType } from '../__generated__/graphql';
import { useChatContext } from '../contexts';
import { STOP_GENERATION } from '../graphql';
import { logger } from '../utils';

export const useChatActions = () => {
    const { submitMessage } = useMessenger();
    const { submitTimesheet } = useTimesheet();
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const {
        conversationId,
        isValid,
        featureType,
        chatType,
        currentMessage,
        streaming,
        lastMessageId: messageId,
        setCurrentMessage,
        sanitizedMessage,
    } = useChatContext();

    const [stopGeneration] = useMutation(STOP_GENERATION);

    useEffect(() => {
        if (listening && transcript) {
            setCurrentMessage(transcript);
        }
    }, [listening, setCurrentMessage, transcript]);

    const isMessenger = chatType === ChatType.Assistant;
    const isTimesheet = featureType === FeatureType.Timesheet;

    const stopRecording = useCallback(() => {
        SpeechRecognition.stopListening();
        resetTranscript();
    }, [resetTranscript]);

    const triggerMic = useCallback(() => {
        if (listening) {
            stopRecording();
        } else {
            SpeechRecognition.startListening({ continuous: true });
        }
    }, [listening, stopRecording]);

    const handleSubmit = useCallback(async () => {
        if (streaming) {
            try {
                logger.log('Stopping generation : ', messageId);
                await stopGeneration({
                    variables: {
                        input: {
                            messageId,
                            conversationId,
                            sessionId: '-',
                        },
                    },
                });
            } catch (error) {
                logger.error(error);
            }

            return;
        }

        if (!isValid) {
            logger.warn('Validation error!');
            return;
        }

        const textToSend = currentMessage.trim();
        const sanitizedTextToSend = sanitizedMessage(textToSend);

        switch (chatType) {
            case ChatType.Assistant:
                submitMessage(sanitizedTextToSend);
                break;
            case ChatType.Custom:
                if (isTimesheet) submitTimesheet(sanitizedTextToSend);
                break;
            default:
                logger.warn('Invalid Chat Type');
                break;
        }

        stopRecording();
        setCurrentMessage('');
    }, [
        chatType,
        currentMessage,
        isValid,
        messageId,
        conversationId,
        streaming,
        isTimesheet,
        setCurrentMessage,
        stopGeneration,
        stopRecording,
        submitMessage,
        submitTimesheet,
        sanitizedMessage,
    ]);

    return {
        isTimesheet,
        isMessenger,
        listening,
        isValid,
        browserSupport: browserSupportsSpeechRecognition,
        triggerMic,
        handleSubmit,
    };
};
