import { CommonMessage } from '..';
import { CallReportOptions } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { FormattedChatMessage, GuidelineItem } from '../message';
import { DocumentsUpload } from './DocumentsUpload';

const AddCallReports = ({ submitMessage }: { submitMessage: Function }) => {
    const { streaming, loading, callReportQuestions, setCallReportQuestions } = useChatContext();

    const handleClick = async (question: string) => {
        try {
            await submitMessage(question);
        } catch (error) {
            console.error('Error in handleGuidelineClick', error);
        }
    };

    return (
        <>
            <CommonMessage
                isUser
                disableCopy
                text=""
                content={
                    <>
                        <div className="w-[500px]">
                            <FormattedChatMessage
                                text={`✨ Here at our knowledge platform for Call Reports, we make it effortless for you to compare data from major financial institutions like JPMC, BOA, and Citi.\n \nNeed to analyze a specific report alongside our existing data?  No problem! Just upload it here, and we'll get started right away. 👇`}
                            />
                        </div>
                        <DocumentsUpload
                            onSuccess={(questions: CallReportOptions[]) => setCallReportQuestions(questions)}
                        />
                    </>
                }
            />
            {callReportQuestions.length > 0 && (
                <CommonMessage
                    disableCopy
                    text=""
                    content={
                        <>
                            <FormattedChatMessage text={`### ✨ Analyze the call reports by exploring these options`} />
                            {callReportQuestions?.map(({ option, question }, index) => (
                                <GuidelineItem
                                    key={index}
                                    question={String(option)}
                                    onClick={() => handleClick(String(question))}
                                    disabled={streaming || loading}
                                />
                            ))}
                        </>
                    }
                />
            )}
        </>
    );
};

export { AddCallReports };
