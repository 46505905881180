import React, { useEffect } from 'react';
import { SideBarButtonSelectionTypes, useSideBarContext } from '../../../contexts';
import { CN } from '../../../utils';
import { KPLList } from '../kpl-list';
import { Settings } from '../settings';
import { ChatHistory } from '../chat-history';
import { AdminPanelMenu } from '../admin-panel-menu';
import { useMobile } from '../../../hooks';
import { CustomKplMenu } from '../custom-kpl-menu/CustomKplMenu';

export const ExtendedSidebar = () => {
    const { sideBarButtonSelection, isCollapsed, isMenuCollapsed } = useSideBarContext();
    const isMobile = useMobile() ?? true;

    return (
        <div
            className={CN(
                'extended_sidebar shadow-xl h-full bg-white border-r border-r-N-200 min-w-[350px] max-w-[350px] w-[350px] xxl:min-w-[280px]  xxl:w-[280px] sm:max-w-full transition-all ease-in duration-[200ms]',
                {
                    'absolute ': !isMobile,
                    'left-0 z-[1] relative': !isCollapsed && !isMobile,
                    'left-[-120px] z-[1]': isCollapsed && !isMobile,
                    'left-[-350px]': !isMenuCollapsed && isCollapsed && !isMobile,
                    '!relative !left-0 !max-w-full !w-full': isMobile,
                }
            )}
        >
            {sideBarButtonSelection === SideBarButtonSelectionTypes.ChatHistorySelection && <ChatHistory />}
            {sideBarButtonSelection === SideBarButtonSelectionTypes.KplListSelection && <KPLList />}
            {sideBarButtonSelection === SideBarButtonSelectionTypes.SettingSelection && <Settings />}
            {sideBarButtonSelection === SideBarButtonSelectionTypes.AdminPanelSelection && <AdminPanelMenu />}
            {sideBarButtonSelection === SideBarButtonSelectionTypes.CustomKplSection && <CustomKplMenu />}
        </div>
    );
};
