import { useMutation } from '@apollo/client';
import { Button } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { useChatContext } from '../../../contexts';
import { ANALYZE_TEST_CASES } from '../../../graphql';
import { CN, getTestCaseSuccessMessage, uniqueId } from '../../../utils';
import { SYSTEM_TEMP_ID } from '../../../constants';
import { MessageAuthor } from '../../../__generated__/graphql';

const JIRA_OPTIONS = {
    projects: ['KAYA AI', 'KAYA MVP'],
    sprints: ['Sprint 1', 'Sprint 2', 'Sprint 3'],
    repositories: ['kaya-core-service', 'kaya-backend', 'kaya-frontend'],
};

const TestCaseUpload = () => {
    const [analyzeTestCases] = useMutation(ANALYZE_TEST_CASES);
    const [testCaseFile, setTestCaseFile] = React.useState<File | null>(null);
    const [testCaseUploaded, setTestCaseUploaded] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const { setMessages } = useChatContext();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;
        setTestCaseFile(selectedFile);
        e.target.value = '';
    };

    const handleAddToChat = async () => {
        try {
            setLoading(true);

            await analyzeTestCases({
                variables: {
                    input: {
                        testCases: testCaseFile,
                    },
                },
            });

            setMessages(prevMessages => [
                ...prevMessages,
                {
                    id: `${SYSTEM_TEMP_ID}_${uniqueId()}`,
                    content: getTestCaseSuccessMessage(),
                    author: MessageAuthor.System,
                },
            ]);

            setTestCaseUploaded(true);
            setLoading(false);
        } catch (error) {
            console.error('Error in handleClick', error);
            setLoading(false);
        }
    };

    const handleRemoveFile = () => {
        setTestCaseFile(null);
    };

    return (
        <div className="max-w-[500px]">
            <div className="my-2 w-full">
                <label className="flex flex-col items-center text-white cursor-pointer p-2 border w-full hover:border-dashed rounded-md">
                    <span>
                        <svg
                            className="w-8 h-8"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                    </span>
                    <span className="text-md">{testCaseFile ? 'Re-Upload Test Cases' : 'Upload Test Cases'}</span>
                    <input type="file" className="hidden" onChange={handleFileChange} accept=".xlsx,.csv" />
                </label>
            </div>
            {testCaseFile && (
                <>
                    <div className="py-1 text-sm add-timesheet">
                        <div className="my-2">
                            <i
                                className="ri-delete-bin-6-line mx-1 cursor-pointer hover:font-bold"
                                onClick={() => handleRemoveFile()}
                            />{' '}
                            {testCaseFile.name}
                        </div>
                    </div>
                    <hr />
                    <div className="my-2 text-md">👇 Please select your JIRA and Bitbucket settings to proceed </div>
                    <div>
                        <label htmlFor="jira-project">JIRA Project:</label>
                        <select
                            name="project"
                            id="jira-project"
                            className="rounded-md bg-N-600 my-2 p-2 text-white w-full"
                        >
                            <option value="">Select Project</option>
                            {JIRA_OPTIONS.projects.map((project, i) => (
                                <option key={i} value={String(project)}>
                                    {String(project)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="jira-sprint">JIRA Sprint:</label>
                        <select
                            name="sprint"
                            id="jira-sprint"
                            className="rounded-md bg-N-600 my-2 p-2 text-white w-full"
                        >
                            <option value="">Select Sprint</option>
                            {JIRA_OPTIONS.sprints.map((sprint, i) => (
                                <option key={i} value={String(sprint)}>
                                    {String(sprint)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="repository">Bitbucket Repository:</label>
                        <select
                            name="repository"
                            id="repository"
                            className="rounded-md bg-N-600 my-2 p-2 text-white w-full"
                        >
                            <option value="">Select Repository</option>
                            {JIRA_OPTIONS.repositories.map((repo, i) => (
                                <option key={i} value={String(repo)}>
                                    {String(repo)}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            )}
            {testCaseFile && (
                <Button
                    isDisabled={!testCaseFile || testCaseUploaded}
                    isLoading={loading}
                    className={CN(
                        'bg-N-700 hover:bg-N-800 text-white text-md outline-none border-none font-bold py-2 px-4 my-1 rounded-md w-full flex items-center disabled:bg-N-600 disabled:cursor-not-allowed',
                        {
                            'disabled:bg-N-400': loading,
                        }
                    )}
                    onClick={handleAddToChat}
                    iconBefore={loading ? '' : 'ri-chat-check-line'}
                >
                    {testCaseUploaded ? 'Test Cases Added' : 'Add Test Cases'}
                </Button>
            )}
        </div>
    );
};

export { TestCaseUpload };
