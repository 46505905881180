import { CommonMessage, FormattedChatMessage, MemoizedMarkdown, MessageAvatar } from '.';
import { decodeFromBase64, logger } from '../../../utils';
import { IChatMessage } from '../../pages/messenger/types';

const getParsedJson = (text: string) => {
    try {
        const { query, originalResponse, improvedResponse } = JSON.parse(text);
        const decodedQuery = decodeFromBase64(query);
        const decodedOriginalResponse = decodeFromBase64(originalResponse);
        const decodedImprovedResponse = decodeFromBase64(improvedResponse);
        return {
            query: decodedQuery,
            originalResponse: decodedOriginalResponse,
            improvedResponse: decodedImprovedResponse,
        };
    } catch (error) {
        logger.error('JSON parsing error:', error);
        return { query: '', originalResponse: '', improvedResponse: '' };
    }
};

const SMEChatMessage = ({ id = '', text = '', feedback, isUser = false, disableCopy = false }: IChatMessage) => {
    const { query, originalResponse, improvedResponse } = getParsedJson(text);

    return (
        <CommonMessage
            id={id}
            text={text}
            isUser={isUser}
            feedback={feedback}
            disableCopy={disableCopy}
            content={
                <>
                    <MemoizedMarkdown
                        markdownText={`Acknowledging dissatisfaction with a previous response, we value the chance to improve and
                        provide a more detailed, thoughtful answer.`}
                    />
                    <div className="flex mt-[12px] pt-[12px]">
                        <MessageAvatar isUser />
                        <div className="flex flex-col space-y-2 mx-2 order-1 max-w-[80%] ">
                            <div className="relative w-full px-4 py-2 border rounded-md inline-block text-white bg-gradient-to-l from-[#1488CC] to-[#5158e2] rounded-bl-sm">
                                {<FormattedChatMessage text={query} />}
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-[12px] pt-[12px]">
                        <MessageAvatar />
                        <div className="flex flex-col space-y-2 mx-2 order-1 max-w-[80%]">
                            <div className="relative w-full px-4 py-2 border rounded-md inline-block text-white bg-gradient-to-l from-[#536976] to-[#1e3b4e] rounded-bl-sm">
                                {<FormattedChatMessage text={originalResponse} />}
                            </div>
                        </div>
                    </div>
                    <div className="mt-[12px] pt-[12px]">
                        <MemoizedMarkdown markdownText={`### ✨ Improved Response`} />
                        <div className="mt-[4px] mx-2 w-full px-4 py-2">
                            {<FormattedChatMessage text={improvedResponse} />}
                        </div>
                    </div>
                </>
            }
        />
    );
};

export { SMEChatMessage };
