import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { CSS_INPUT, CSS_SCROLL } from '../../../constants';
import { CN } from '../../../utils';

const SprintAnalysisModal = ({
    open,
    response,
    onClose,
}: {
    open: boolean;
    response: string;
    onClose: (value: string) => void;
}) => {
    const ref = useClickOutside(() => onClose(response));
    const [value, setValue] = useState(response);

    if (!open) return null;

    return (
        <div className="absolute inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div
                className="fixed bottom-[4.5em] p-4 pt-2 bg-white border border-N-200 drop-shadow-sm rounded-md w-full max-w-md m-auto"
                ref={ref}
            >
                <div className="flex justify-between items-center mb-2 pb-2">
                    <p className="text-2xl font-bold">Sprint Analysis Report</p>
                </div>
                <div className="flex gap-2">
                    <div className="flex flex-col gap-1 w-full">
                        <textarea
                            className={CN(`${CSS_INPUT} ${CSS_SCROLL} w-full !h-[15em] bg-card-default text-N-600`)}
                            value={value || response}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setValue(e.target.value);
                            }}
                        />
                        <div className="flex gap-2">
                            <button
                                className="bg-B-500 hover:bg-B-400 text-white py-2 px-4 rounded-md w-full"
                                onClick={() => onClose(value)}
                            >
                                Update
                            </button>
                            <button
                                className="bg-R-600 hover:bg-N-500 text-white py-2 px-4 rounded-md w-full"
                                onClick={() => onClose(value)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { SprintAnalysisModal };
