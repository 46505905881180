import { Tag } from '@kaya/kaya-ui-design-system-pb';
import { IPrompt, usePromptTable } from '../../../../../../../hooks';
import { deConstantCase, deUnderscore } from '../../../../../../../utils';

type IPromptView = {
    selectedPrompt: IPrompt | null;
};

export const PromptView = ({ selectedPrompt }: IPromptView) => {
    const { getPromptModelName } = usePromptTable({});

    return (
        <div className="py-[16px] flex flex-col gap-y-5 items-start w-full">
            <div className="flex items-center justify-between gap-y-[8px] gap-x-[8px] border-b border-N-200 w-full pb-4 px-[24px]">
                <div className="flex flex-col gap-y-2">
                    <p className="text-h5 font-600 text-N-900">{deConstantCase(selectedPrompt?.templateKey || '')} </p>
                    <p className="text-N-400 font-400 text-xs flex items-center gap-1">
                        {deConstantCase(selectedPrompt?.category || '')}
                    </p>
                </div>
                <div className="flex gap-x-2">
                    <Tag
                        label={deUnderscore(selectedPrompt?.metadata.kpl ?? 'No KPL')}
                        appearance={selectedPrompt?.metadata.kpl ? 'sky' : 'neutral'}
                    />
                    <Tag
                        label={getPromptModelName(selectedPrompt?.metadata.model ?? 'No Model')}
                        appearance={selectedPrompt?.metadata.model ? 'sky' : 'neutral'}
                    />
                </div>
            </div>
            <div className="w-full px-[24px] flex flex-col gap-y-7">
                <div className="flex flex-col gap-y-2">
                    <p className="text-N-900 font-600">Prompt template</p>
                    <p className="text-N-600 text-sm font-400 whitespace-pre-line">{selectedPrompt?.templateContent}</p>
                </div>
                <div className="flex flex-col gap-y-2">
                    <p className="text-N-900 font-600">Variables</p>
                    {selectedPrompt?.variables.map((variable, index) => (
                        <div key={index} className="text-N-600 text-sm font-400">
                            {variable}
                        </div>
                    ))}
                    {selectedPrompt?.variables.length === 0 && <p className="text-N-600 text-sm font-400">N/A</p>}
                </div>
            </div>
        </div>
    );
};
