import { Tabs } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { KPLCreationInnerForm } from '../kpl-creation-inner-form';
import { SupportiveFormInnerPrompt } from '../supportive-prompts-inner-form';
import { UserConfigurationInnerForm } from '../user-configuration-inner-form';
import { CN } from '../../../../../utils';
import { useMobile } from '../../../../../hooks';

export interface ICurrentStep {
    step: number;
    disabled: boolean;
}

interface IKPLFormProps {
    currentStep: ICurrentStep;
    setCurrentStep: React.Dispatch<React.SetStateAction<ICurrentStep>>;
}

export const KPLForm = ({ currentStep, setCurrentStep }: IKPLFormProps) => {
    const tabDetails = [
        {
            id: 1,
            title: <div className="px-5 py-3">1. KPL Creation</div>,
            content: <KPLCreationInnerForm />,
        },
        {
            id: 2,
            title: <div className="step__supportive-prompts-title px-5 py-3">2. Supportive Prompts</div>,
            content: <SupportiveFormInnerPrompt />,
        },
        {
            id: 3,
            title: <div className="px-5 py-3">3. User Configuration</div>,
            content: <UserConfigurationInnerForm />,
        },
    ];
    const isMobile = useMobile();

    return (
        <div
            className={CN('KPL_form_body flex flex-col gap-y-6 px-4 py-2', {
                '!h-[calc(100dvh-150px)] overflow-y-auto': isMobile,
            })}
        >
            <Tabs
                isItemsFullWidth={false}
                tabContentClassName="pt-4 px-2"
                activeTabId={currentStep.step}
                tabDetails={tabDetails.map(tab => ({
                    ...tab,
                    onClick: () =>
                        setCurrentStep({
                            step: tab.id,
                            disabled: false,
                        }),
                    disabled: currentStep.step !== tab.id,
                }))}
            />
        </div>
    );
};
