import { Button } from '@kaya/kaya-ui-design-system-pb';
import { useApproveLog } from '../../../hooks';
import { IActivityLogMessage } from '../../pages';
import { CommonMessage } from './CommonMessage';
import { MemoizedMarkdown } from './Markdown';

const ActivityLogMessage = ({ id = '', text = '', isUser = false, showApprove = false }: IActivityLogMessage) => {
    const { submitApproval, approved, loading } = useApproveLog();

    return (
        <CommonMessage
            id={id}
            text={text}
            isUser={isUser}
            disableCopy={true}
            content={
                <>
                    <MemoizedMarkdown markdownText={text} />
                    {showApprove && (
                        <div className="flex justify-end mt-2">
                            <Button
                                isDisabled={approved}
                                isLoading={loading}
                                className="bg-B-500 hover:bg-B-400 text-white outline-none border-none font-bold py-2 px-4 rounded-md flex items-center disabled:bg-N-600 disabled:pointer-events-none"
                                onClick={() => submitApproval(text)}
                                iconBefore={loading ? '' : 'ri-checkbox-circle-line'}
                            >
                                {approved ? 'Approved' : 'Approve'}
                            </Button>
                        </div>
                    )}
                </>
            }
        />
    );
};

export { ActivityLogMessage };
